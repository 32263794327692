import React from "react";
import {
    XBrowseProps,
    XLazyColumn,
    XLazyDataTable, XSearchBrowseProps
} from "@michalrakus/x-react-web-lib/XLazyDataTable";
import { MiestnostForm } from "./MiestnostForm";

export const MiestnostBrowse = (props: XBrowseProps & XSearchBrowseProps) => {

    const onAddRow = () => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        props.openForm!(<MiestnostForm/>);
    }

    const onEdit = (selectedRow: any) => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        props.openForm!(<MiestnostForm id={selectedRow.id}/>);
    }

    return (
        <XLazyDataTable entity="Miestnost" label="Miestnosti" sortField="nazov" rows={30}
                        onAddRow={onAddRow} onEdit={onEdit} removeRow={true} multilineSwitch={true}
                        displayed={props.displayed} searchBrowseParams={props.searchBrowseParams}>
            <XLazyColumn field="nazov" header="Názov" width="12rem"/>
            <XLazyColumn field="pocetPosteli" header="Počet postelí" width="10rem"/>
            <XLazyColumn field="mimoPrevadzky" header="Mimo prevádzky"/>
            <XLazyColumn field="poznamka" header="Poznámka" width="50rem" contentType="multiline"/>
        </XLazyDataTable>
    );
}