import React from "react";
import {Form} from "../../XLibItems";
import {XInputDecimal} from "@michalrakus/x-react-web-lib/XInputDecimal";
import {XInputText} from "@michalrakus/x-react-web-lib/XInputText";
import {XFormFooter} from "@michalrakus/x-react-web-lib/XFormFooter";
import {XInputDate} from "@michalrakus/x-react-web-lib/XInputDate";
import {XFormBaseModif} from "@michalrakus/x-react-web-lib/XFormBaseModif";
import {XObject} from "@michalrakus/x-react-web-lib/XObject";
import {XFormHeader} from "@michalrakus/x-react-web-lib/XFormHeader";
import {XCheckbox} from "@michalrakus/x-react-web-lib/XCheckbox";
import {XAutoComplete} from "@michalrakus/x-react-web-lib/XAutoComplete";

@Form("Obec")
export class ObecForm extends XFormBaseModif {

    createNewObject(): XObject {
        return {oficialnyZaznam: false, version: 0};
    }

    render() {
        const labelStyle: React.CSSProperties = {width:'13rem'};

        return (
            <div>
                <XFormHeader form={this} label="Obec/mesto"/>
                <div className="x-form-row">
                    <div className="x-form-col">
                        <XInputDecimal form={this} field="id" label="ID" labelStyle={labelStyle} readOnly={true}/>
                        <XInputDate form={this} field="modifDate" label="Modified at" labelStyle={labelStyle} readOnly={true}/>
                        <XInputText form={this} field="modifXUser.name" label="Modified by" labelStyle={labelStyle} inputStyle={{width:'12.5rem'}}/>
                        <XInputText form={this} field="nazov" label="Názov" labelStyle={labelStyle} inputStyle={{width:'20rem'}}/>
                        <XAutoComplete form={this} assocField="okres" displayField="nazov" label="Okres" labelStyle={labelStyle}/>
                        <XInputText form={this} field="okres.kraj.nazov" label="Kraj" inputStyle={{width:'20rem'}} labelStyle={labelStyle}/>
                        <XInputText form={this} field="kod" label="Kód" inputStyle={{width:'10rem'}} labelStyle={labelStyle} readOnly={true}/>
                        <XCheckbox form={this} field="oficialnyZaznam" label="Oficiálny záznam" labelStyle={labelStyle} readOnly={true}/>
                    </div>
                </div>
                <XFormFooter form={this}/>
            </div>
        );
    }
}
