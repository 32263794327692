import React, {useState} from "react";
import {
    XBrowseProps,
    XLazyColumn,
    XLazyDataTable, XOptionalCustomFilter, XSearchBrowseProps
} from "@michalrakus/x-react-web-lib/XLazyDataTable";
import {Utils} from "../../Utils";
import {KlientNoclaharenForm} from "./KlientNoclaharenForm";
import {EnumEnum, NoclaharenPlatbaEnum} from "../../common/enums";
import {Klient} from "../../model/klient/klient.entity";
import {XUtilsCommon} from "@michalrakus/x-react-web-lib/XUtilsCommon";
import {
    HladatKlientaMimoSluzbuDialog,
    HladatKlientaMimoSluzbuDialogState
} from "../klient/HladatKlientaMimoSluzbuDialog";
import {OperationType} from "@michalrakus/x-react-web-lib/XUtils";
import {ColumnBodyOptions} from "primereact/column";
import {AsUIType, convertValueBase, XDateScale} from "@michalrakus/x-react-web-lib/XUtilsConversions";

const whereAktivniZaPoslednychXDni: string =
    `[id] IN (SELECT k.id FROM ${Utils.getSchema()}.klient k WHERE k.modif_date >= :datumMinusXDni`
    + ` UNION`
    + ` SELECT u.klient_id FROM ${Utils.getSchema()}.ubytovanie u WHERE u.modif_date >= :datumMinusXDni)`;

const optionalCustomFilters: XOptionalCustomFilter[] = [
    {
        label: "Práve ubytovaní",
        filter: {where: `[id] IN (SELECT klient_id FROM ${Utils.getSchema()}.ubytovanie WHERE datum = :datum)`, params: {datum: Utils.todayNoclaharen()}}
    },
    {
        label: "Nezaplatení",
        filter: {where: `[id] IN (SELECT klient_id FROM ${Utils.getSchema()}.ubytovanie WHERE datum = :datum AND platba_id = ${NoclaharenPlatbaEnum.Nezaplatil.id})`, params: {datum: Utils.todayNoclaharen()}}
    },
    {
        label: "Aktívni za posledný týždeň",
        filter: {where: whereAktivniZaPoslednychXDni, params: {datumMinusXDni: XUtilsCommon.dateAddDays(Utils.todayNoclaharen(), -7)}}
    },
    {
        label: "Aktívni za posledné 2 týždne",
        filter: {where: whereAktivniZaPoslednychXDni, params: {datumMinusXDni: XUtilsCommon.dateAddDays(Utils.todayNoclaharen(), -14)}}
    }
];

export const KlientNoclaharenBrowse = (props: XBrowseProps & XSearchBrowseProps) => {

    const [hladatKlientaDialogState, setHladatKlientaDialogState] = useState<HladatKlientaMimoSluzbuDialogState>({opened: false});

    const todayLogicky: Date = Utils.todayNoclaharen();

    const onAddRow = () => {
        // analogicky ako v KlientSluzbaBrowse

        setHladatKlientaDialogState({
            opened: true,
            onHideDialog: onHideDialog
        });
    }

    const onHideDialog = (ok: boolean, klient?: Klient | null, objectChange?: OperationType) => {
        // TODO - objectChange nie je spraveny, zatial nepouzivat
        setHladatKlientaDialogState({opened: false});
        if (ok) {
            if (klient !== null) {
                // otvorime formular KlientNoclaharenForm v rezime na update
                // priznak noclaharen nastavime v KlientNoclaharenForm.preInitForm, po tom ako sa zaznam Klient reread-ne z DB
                props.openForm!(<KlientNoclaharenForm id={klient!.id}/>);
            }
            else {
                // otvorime formular KlientNoclaharenForm v rezime na insert, pricom aj klient bude nanovo vytvoreny
                // inicializacia prebehne cez KlientNoclaharenForm.createNewObject()
                props.openForm!(<KlientNoclaharenForm/>);
            }
        }
    }

    const onEdit = (selectedRow: any) => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        props.openForm!(<KlientNoclaharenForm id={selectedRow.id}/>);
    }

    const rowClassName = (rowData: Klient) => {
        return {
            'klient-zakaz-ubytovat': Utils.klientMaZakazUbytovatSa(rowData, todayLogicky)
        };
    };

    const datumNarodeniaBody = (klient: Klient, options: ColumnBodyOptions): React.ReactNode => {
        return convertValueBase('date', klient.datumNarodeniaIbaRok ? XDateScale.Year : XDateScale.Date, klient.datumNarodenia, true, AsUIType.Form);
    }

    return (
        <div>
            <XLazyDataTable entity="Klient" label="Klienti nocľahárne" sortField="id desc" rows={30}
                            customFilter={Utils.klientFilterNoclaharen()}
                            onAddRow={onAddRow} onEdit={onEdit} removeRow={true}
                            fields={["klientZakazUbytovatList.id"]} rowClassName={rowClassName}
                            optionalCustomFilters={optionalCustomFilters}
                            displayed={props.displayed} searchBrowseParams={props.searchBrowseParams}>
                <XLazyColumn field="id" header="ID" width="5rem"/>
                <XLazyColumn field="menoPriezviskoPrezyvka" header="Klient" width="20rem"/>
                <XLazyColumn field="datumNarodenia" header="Dátum narod." body={datumNarodeniaBody}/>
                <XLazyColumn field="rodneCislo" header="Rodné číslo" width="7rem"/>
                <XLazyColumn field="pohlavie.name" header="Pohlavie" width="6rem" dropdownInFilter={true} dropdownFilter={Utils.enumFilter(EnumEnum.pohlavie)}/>
                <XLazyColumn field="cisloVreca" header="Číslo vreca" width="7rem"/>
                <XLazyColumn field="predplateneNoci" header="Predpl. noci" width="7rem"/>
            </XLazyDataTable>
            <HladatKlientaMimoSluzbuDialog dialogState={hladatKlientaDialogState}/>
        </div>
    );
}
