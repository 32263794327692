import React, {useEffect, useState} from "react";
import {
    HromEvidGetMaxDatumRequest,
    HromEvidGetMaxDatumResponse,
    HromEvidRequest,
    HromEvidResponse, XIdAndModifRequest
} from "../../common/hrom-evid-api";
import {Utils} from "../../Utils";
import {OperationType, XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {CinnostTypZaznamu, EnumEnum} from "../../common/enums";
import {XCalendar} from "@michalrakus/x-react-web-lib/XCalendar";
import {Panel} from "primereact/panel";
import {dateAsUI, dateFromModel} from "@michalrakus/x-react-web-lib/XUtilsConversions";
import {
    DataTable,
    DataTableSelectionSingleChangeEvent
} from "primereact/datatable";
import {Column} from "primereact/column";
import {Checkbox} from "primereact/checkbox";
import {XButtonIconMedium} from "@michalrakus/x-react-web-lib/XButtonIconMedium";
import {UtilsCommon} from "../../common/UtilsCommon";
import {HromEvidKlientAutoComplete} from "./HromEvidKlientAutoComplete";
import {XUtilsCommon} from "@michalrakus/x-react-web-lib/XUtilsCommon";
import {XEnum} from "../../model/user/x-enum.entity";
import {Cinnost} from "../../model/klient/cinnost.entity";
import {CinnostDen} from "../../model/klient/cinnost-den.entity";
import {KlientSluzba} from "../../model/klient/klient-sluzba.entity";

export const HromEvidForm = (props: {typCinnosti: XEnum;}) => {

    const today = XUtilsCommon.today();

    const [value, setValue] = useState<HromEvidResponse>({cinnostDenList: []});
    const [datumOd, setDatumOd] = useState<Date | null>(today);
    const [datumDo, setDatumDo] = useState<Date | null>(today);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedRow, setSelectedRow] = useState<any>(null); // treba?

    useEffect(() => {
        loadData(datumOd, datumDo);
    }, []);

    const loadData = async (datumOd: Date | null, datumDo: Date | null) => {
        const currentSluzbaId: number | undefined = Utils.getCurrentSluzbaId();
        if (currentSluzbaId) {
            setLoading(true);
            try {
                const hromEvidResponse: HromEvidResponse = await XUtils.fetchOne('hrom-evid-load-data', createHromEvidRequest(datumOd, datumDo));
                setValue(hromEvidResponse);
            }
            catch (e) {
                XUtils.showErrorMessage('Nepodarilo sa načítať dáta z DB.', e);
            }
            setLoading(false);
        }
    }

    const createHromEvidRequest = (datumOd: Date | null, datumDo: Date | null) => {
        const hromEvidRequest: HromEvidRequest = {sluzba: Utils.getCurrentSluzba()!, typCinnosti: props.typCinnosti, generovatPreDatum: today, modifXUser: Utils.getXUserDePaul()};
        if (datumOd instanceof Date) {
            hromEvidRequest.datumOd = datumOd;
        }
        if (datumDo instanceof Date) {
            hromEvidRequest.datumDo = datumDo;
        }
        return hromEvidRequest;
    }

    const onChangeKlientSluzba = async (rowData: Cinnost, klientSluzba: KlientSluzba) => {
        // zmenime v modeli (aby sa zmena prejavila vo formulari)
        rowData.klientSluzba = klientSluzba;

        // treba klonovat, inac react nezobrazi zmenenu hodnotu
        const valueCloned: HromEvidResponse = {...value};
        setValue(valueCloned);

        // zmenime v DB (excel styl ukladania zmien)
        if (klientSluzba !== null) {
            // ak user zadal klienta, zapiseme zmenu do DB
            // (vykona sa insert/update, podla toho ci zaznam uz existuje v DB, rowData.id obsahuje id vytvorene cez sequence)
            saveRowCinnost(rowData);
        }
        else {
            // klientSluzba === null
            // user vymazal klienta, velky zmysel to nema, ale vymazeme cely zaznam v DB, nech mame poriadok (nech UI sedi s DB)
            removeRowCinnost(rowData.id);
        }
    };

    const onChangeVykonana = (rowData: Cinnost, vykonana: boolean) => {
        // kontrola
        if (rowData.typZaznamu.code === CinnostTypZaznamu.manualneVytvoreny && rowData.klientSluzba === null) {
            alert("Najprv zadajte klienta.");
            return;
        }

        // zmenime v modeli (aby sa zmena prejavila vo formulari)
        rowData.vykonana = vykonana;

        // treba klonovat, inac react nezobrazi zmenenu hodnotu
        const valueCloned: HromEvidResponse = {...value};
        setValue(valueCloned);

        // zmenime v DB (excel styl ukladania zmien)
        saveRowCinnost(rowData);
    };

    const saveRowCinnost = async (cinnost: Cinnost) => {
        try {
            cinnost.modifDate = new Date();
            cinnost.modifXUser = XUtils.getXToken()?.xUser;
            const cinnostReloaded: Cinnost = await XUtils.fetch('saveRow', {entity: "Cinnost", object: cinnost, reload: true});
            cinnost.id = cinnostReloaded.id; // pri inserte zaznamu s uz existujucim id-ckom (vytvorenym cez sequence) vytvori nove id, preto si musime toto nove id skopirovat
        }
        catch (e) {
            XUtils.showErrorMessage('Nepodarilo sa zapísať zmenu do databázy.', e);
        }
    }

    const addRow = async (cinnostDen: CinnostDen) => {
        // aby boli vsetky zaznamy v tabulke unique, tak si vypytame id-cko hned teraz
        const id: number = await Utils.getSequenceValue("cinnost_id_seq");
        const cinnost: Cinnost = {
            id: id,
            typZaznamu: {id: 66, code: "manualneVytvoreny", name: "manuálne vytvorený"} as XEnum, // TODO - poriesit xEnum
            cinnostDen: {id: cinnostDen.id} as CinnostDen, // mozme sem zapisat aj cisto id (napr. cinnostDen.id), tiez zafunguje,
                // -> zapisanie cinnostDen nefunguje, lebo sa vytvori cyklicka vezba medzi cinnost a cinnostDen (vyplnena aj manyToOne aj oneToMany asociacia)
            klientSluzba: null!,
            vykonana: false,
            poznamka: null,
            modifDate: null,
            modifXUser: null
        };
        cinnostDen.cinnostList.push(cinnost);

        // treba klonovat, inac react nezobrazi zmenenu hodnotu
        const valueCloned: HromEvidResponse = {...value};
        setValue(valueCloned);

        // do DB zapiseme, az ked user zada klienta
        // dovodom je hlavne not null asociacia na klienta (museli by sme zrusit not null na stlpci klient_sluzba_id v DB),
        // s tym suvisiaci unique constraint na (cinnost_den_id, klient_sluzba_id)
    }

    const removeRow = (cinnostDen: CinnostDen, row: Cinnost) => {
        if (window.confirm("Chcete naozaj vymazať záznam?")) {
            UtilsCommon.arrayRemoveItem(cinnostDen.cinnostList, row);

            // treba klonovat, inac react nezobrazi zmenenu hodnotu
            const valueCloned: HromEvidResponse = {...value};
            setValue(valueCloned);

            // zmenime v DB (excel styl ukladania zmien)
            removeRowCinnost(row.id);
        }
    }

    const removeRowCinnost = async (cinnostId: number) => {
        try {
            await XUtils.post('removeRow', {entity: "Cinnost", id: cinnostId});
        }
        catch (e) {
            XUtils.showErrorMessage('Nepodarilo sa vymazať záznam v databáze.', e);
        }
    }

    const onErrorChange = (error: string | undefined) => {
        //this.errorInBase = error; // odlozime si error
    }

    const onChangeDatum = async (cinnostDen: CinnostDen, datum: Date | null) => {
        // zmenu do modelu zapiseme len ak mame kompletny datum (vypisovanie datumu po znakoch sa zobrazuje aj ked nezapisujeme null do modelu)
        // na opustenie inputu (onBlur) sa v inpute zobrazi hodnota z modelu (to sme nekodili ale tak to funguje)
        // -> cize sa vrati naspet povodna hodnota a to je to co chceme
        if (datum !== null) {
            // skontrolujeme, ci uz nahodou novy datum neexistuje na inom zazname
            let cinnostDenList: any[] = await XUtils.fetchRows('CinnostDen',
                {where: "[sluzba] = :sluzbaId AND [typCinnosti] = :typCinnostiId AND [datum] = :datum",
                            params: {sluzbaId: Utils.getCurrentSluzbaId(), typCinnostiId: props.typCinnosti.id, datum: datum}});
            if (cinnostDenList.length > 0) {
                alert(`Záznam s dátumom ${dateAsUI(datum)} už v databáze existuje.`);

                // hack, aby sme vratili povodnu hodnotu do date inputu - React zjavne refreshuje len ak zaregistruje zmenu v modeli
                cinnostDen.datum = new Date(cinnostDen.datum);
                const valueCloned: HromEvidResponse = {...value};
                setValue(valueCloned);
                return;
            }

            cinnostDen.datum = datum;

            // treba klonovat, inac react nezobrazi zmenenu hodnotu
            const valueCloned: HromEvidResponse = {...value};
            setValue(valueCloned);

            // zmenime v DB (excel styl ukladania zmien)
            try {
                cinnostDen.modifDate = new Date();
                cinnostDen.modifXUser = XUtils.getXToken()?.xUser;
                const cinnostDenReloaded: CinnostDen = await XUtils.fetch('saveRow', {entity: "CinnostDen", object: cinnostDen, reload: true});
                cinnostDen.version = cinnostDenReloaded.version; // aby nam nepadalo na optimistickom lockovani
            }
            catch (e) {
                XUtils.showErrorMessage('Nepodarilo sa zapísať zmenu do databázy.', e);
            }
        }
    };

    const addCinnostDen = async () => {
        if (!Utils.getCurrentSluzba()) {
            alert("Najprv zadajte službu.");
            return;
        }
        // id-cko pouzivame pri renderingu, tak si hu vypytame hned teraz
        //const id: number = await Utils.getSequenceValue("cinnost_den_id_seq");
        // predplnime datum = max(datum) + 1
        const getMaxDatumRequest: HromEvidGetMaxDatumRequest = {sluzba: Utils.getCurrentSluzba()!, typCinnosti: props.typCinnosti};
        const getMaxDatumResponse: HromEvidGetMaxDatumResponse = await XUtils.fetchOne('hrom-evid-get-max-datum', getMaxDatumRequest);
        let datum: Date | null = dateFromModel(getMaxDatumResponse.datum);
        if (datum) {
            datum = XUtilsCommon.dateAddDays(datum, 1);
        }
        else {
            datum = new Date();
        }

        const cinnostDen: CinnostDen = {
            id: undefined!, // vykona priamo insert
            sluzba: Utils.getCurrentSluzba()!,
            typCinnosti: props.typCinnosti,
            datum: datum!,
            cinnostList: [],
            vygenerovaneCinnosti: false,
            modifDate: new Date(),
            modifXUser: XUtils.getXToken()?.xUser,
            version: 0
        };

        let cinnostDenReloaded: CinnostDen;
        try {
            cinnostDenReloaded = await XUtils.fetch('saveRow', {entity: "CinnostDen", object: cinnostDen, reload: true});
        }
        catch (e) {
            XUtils.showErrorMessage('Nepodarilo sa zapísať záznam CinnostDen do databázy.', e);
            return;
        }

        // cinnostDenReloaded uz obsahuje aj ID
        value.cinnostDenList.push(cinnostDenReloaded);

        // treba klonovat, inac react nezobrazi zmenenu hodnotu
        const valueCloned: HromEvidResponse = {...value};
        setValue(valueCloned);
    }

    const removeCinnostDen = async (cinnostDen: CinnostDen) => {
        if (window.confirm(`Chcete naozaj vymazať celý blok činností pre deň ${dateAsUI(cinnostDen.datum)}?`)) {

            UtilsCommon.arrayRemoveItem(value.cinnostDenList, cinnostDen);

            // treba klonovat, inac react nezobrazi zmenenu hodnotu
            const valueCloned: HromEvidResponse = {...value};
            setValue(valueCloned);

            // zmenime v DB (excel styl ukladania zmien)
            try {
                await XUtils.post('removeRow', {entity: "CinnostDen", id: cinnostDen.id, assocsToRemove: ["cinnostList"]});
            }
            catch (e) {
                XUtils.showErrorMessage('Nepodarilo sa vymazať záznam v databáze.', e);
            }
        }
    }

    const generujCinnosti = async (cinnostDen: CinnostDen) => {
        // TODO - potvrdzovat spustenie generovania? zatial nedame
        // jediny dovod preco negenerovat je pripad, ak uz bolo spustene generovanie, user vymazal nejake cinnosti
        // a spustenim generovania by sa tieto cinnosti znova dogenerovali
        // preto mame aj priznak cinnostDen.vygenerovaneCinnosti (okrem toho tento priznak zlepsuje performance - nechceme pri kazdom otvoreni cinnosti pustat generovanie)

        cinnostDen.loading = true;
        // zobrazime spinner nad tabulkou
        let valueCloned: HromEvidResponse = {...value};
        setValue(valueCloned);

        let cinnostDenNovy: CinnostDen;
        try {
            const request: XIdAndModifRequest = {id: cinnostDen.id, modifXUser: XUtils.getXToken()?.xUser};
            cinnostDenNovy = await XUtils.fetchOne('hrom-evid-generuj-cinnosti', request);
        }
        catch (e) {
            XUtils.showErrorMessage('Nepodarilo sa vygenerovať činnosti.', e);
            cinnostDen.loading = undefined;
            // zrusime spinner
            valueCloned = {...value};
            setValue(valueCloned);
            return;
        }

        // vymenime cely zaznam v zozname
        UtilsCommon.arrayReplaceItem(value.cinnostDenList, cinnostDen, cinnostDenNovy);

        // treba klonovat, inac react nezobrazi zmenenu hodnotu
        valueCloned = {...value};
        setValue(valueCloned);

        // const pocetNovychZaznamov: number = cinnostDenNovy.cinnostList.length - cinnostDen.cinnostList.length;
        // alert(`Počet nových záznamov: ${pocetNovychZaznamov}`);
    }

    // ************ rendering - pomocne funkcie ****************

    const rowClassName = (rowData: Cinnost) => {
        return {
            'bg-teal-200': rowData.typZaznamu.code === CinnostTypZaznamu.manualneVytvoreny // tyrkysova
        };
    };

    const klientSluzbaTemplate = (cinnostDen: CinnostDen, rowData: Cinnost): React.ReactNode => {
        let klientNode: React.ReactNode | undefined = undefined;
        if (rowData.typZaznamu.code === CinnostTypZaznamu.generovany) {
            klientNode = rowData.klientSluzba?.klient?.menoPriezviskoPrezyvka;
        }
        if (rowData.typZaznamu.code === CinnostTypZaznamu.manualneVytvoreny) {
            klientNode = <HromEvidKlientAutoComplete cinnostDen={cinnostDen} klientSluzba={rowData.klientSluzba} onChange={(klientSluzba: KlientSluzba, objectChange: OperationType) => onChangeKlientSluzba(rowData, klientSluzba)}/>;
        }
        return klientNode;
    }

    const createPanelCinnostDen = (cinnostDen: CinnostDen): JSX.Element => {

        const readOnlyRemove = false; // TODO

        let fieldDatum: React.ReactNode;
        if (cinnostDen.cinnostList.length === 0 && !cinnostDen.vygenerovaneCinnosti) {
            fieldDatum = <XCalendar value={dateFromModel(cinnostDen.datum)} onChange={(value: Date | null) => onChangeDatum(cinnostDen, value)}/>;
        }
        else {
            // datum readOnly
            fieldDatum = <div style={{fontSize: '120%'}}>{dateAsUI(dateFromModel(cinnostDen.datum))}</div>;
        }

        const header: React.ReactNode =
            <div className="flex justify-content-between align-items-center">
                <XButtonIconMedium icon="pi pi-file-import" onClick={() => generujCinnosti(cinnostDen)} tooltip="Generovať činnosti"/>
                {fieldDatum}
                <XButtonIconMedium icon="pi pi-times" onClick={() => removeCinnostDen(cinnostDen)}/>
            </div>;

        return (
            <Panel key={cinnostDen.id} header={header} className="m-1" pt={{title: {style: {width: '100%'}}}}>
                <DataTable value={cinnostDen.cinnostList} dataKey="id" loading={loading || cinnostDen.loading}
                           selectionMode="single" selection={selectedRow} onSelectionChange={(event: DataTableSelectionSingleChangeEvent<Cinnost[]>) => setSelectedRow(event.value)}
                           className="p-datatable-sm x-form-datatable" rowClassName={rowClassName} resizableColumns columnResizeMode="expand">
                    <Column field="klientSluzba.klient.menoPriezviskoPrezyvka" header="Klient" headerStyle={{width: '15rem'}} body={(rowData: Cinnost) => klientSluzbaTemplate(cinnostDen, rowData)}/>
                    <Column field="vykonana" headerStyle={{width: '3rem'}} align="center" body={(rowData: Cinnost) => <Checkbox checked={rowData.vykonana ?? false} onChange={(e: any) => onChangeVykonana(rowData, e.checked)} disabled={false}/>}/>
                    <Column key="removeButton" headerStyle={{width: '2rem'}} align="center" body={(rowData: Cinnost) => <XButtonIconMedium icon="pi pi-times" onClick={() => removeRow(cinnostDen, rowData)} disabled={readOnlyRemove}/>}/>
                </DataTable>
                <div className="flex justify-content-center">
                    <XButtonIconMedium icon="pi pi-plus" onClick={() => addRow(cinnostDen)} disabled={readOnlyRemove}/>
                </div>
            </Panel>
        );
    }

    // ****************** rendering ******************

    let elemList: JSX.Element[] = [];
    for (const cinnostDen of value.cinnostDenList) {
        elemList.push(createPanelCinnostDen(cinnostDen));
    }

    return (
        <div>
            <div className="flex justify-content-center m-1">
                {props.typCinnosti.name}
            </div>
            <div className="flex justify-content-center m-1">
                <label className="m-2">Od</label>
                <XCalendar value={datumOd} onChange={(value: Date | null) => {setDatumOd(value); loadData(value, datumDo);}}/>
                <label className="m-2">do</label>
                <XCalendar value={datumDo} onChange={(value: Date | null) => {setDatumDo(value); loadData(datumOd, value);}}/>
                {/*<Button label="Filtrovať" onClick={loadData} className="ml-2"/>*/}
                {/*<Button icon="pi pi-search" onClick={loadData} className="p-ml-2"/>*/}
            </div>
            <div className="flex flex-column align-items-center">
                {elemList}
            </div>
            <div className="flex justify-content-center">
                <XButtonIconMedium icon="pi pi-plus" onClick={() => addCinnostDen()}/>
            </div>
        </div>
    );
}
