import React from "react";
import {Form} from "../../XLibItems";
import {XInputDecimal} from "@michalrakus/x-react-web-lib/XInputDecimal";
import {XInputText} from "@michalrakus/x-react-web-lib/XInputText";
import {XFormFooter} from "@michalrakus/x-react-web-lib/XFormFooter";
import {XFormBaseModif} from "@michalrakus/x-react-web-lib/XFormBaseModif";
import {XCheckbox} from "@michalrakus/x-react-web-lib/XCheckbox";
import {XObject} from "@michalrakus/x-react-web-lib/XObject";
import {Miestnost} from "../../model/noclaharen/miestnost.entity";
import {XFormCol} from "@michalrakus/x-react-web-lib/XFormCol";
import {XFormRow} from "@michalrakus/x-react-web-lib/XFormRow";
import {XInputTextarea} from "@michalrakus/x-react-web-lib/XInputTextarea";
import {XFormHeader} from "@michalrakus/x-react-web-lib/XFormHeader";

@Form("Miestnost")
export class MiestnostForm extends XFormBaseModif {

    createNewObject(): XObject {
        return {mimoPrevadzky:false, version: 0};
    }

    render() {
        return (
            <XFormCol labelStyle={{width:'10rem'}}>
                <XFormHeader form={this} label="Miestnosť"/>
                <XFormRow>
                        <XInputText form={this} field="nazov" label="Názov" inputStyle={{width:'13rem', marginRight:'4rem'}}/>
                        <XInputDecimal form={this} field="pocetPosteli" label="Počet postelí" inputStyle={{width:'7rem', marginRight:'4rem'}}/>
                        <XCheckbox form={this} field="mimoPrevadzky" label="Mimo prevádzky" labelStyle={{width:'13rem'}}/>       
                </XFormRow>
                <XFormRow>
                    <XInputTextarea form={this} field="poznamka" label="Poznámka" cols="full" autoResize={true} inputStyle={{width:'60rem'}}/>
                </XFormRow>
                <XFormFooter form={this}/>
            </XFormCol>
        );
    }
}
