import React, {useEffect, useState} from "react";
import {XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {Zapis} from "../../model/zapisy/zapis.entity";
import {dateAsUI} from "@michalrakus/x-react-web-lib/XUtilsConversions";
import {Utils} from "../../Utils";

// komponent ktory zobrazi datumy, kedy mal klient zakliknutu klikacku hodnotenie
export const KlientSluzbaHodnoteniaDatumy = (props: {
    klientSluzbaId: number;
}) => {

    const [zapisList, setZapisList] = useState<Zapis[]>([]);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        // tu by stacilo nacitavat len datum
        // nacitame zapisy ktore maju zaskrtnutu klikacku hodnotenie
        const zapisList: any[] = await XUtils.fetchRows('Zapis', {where: "[klientSluzba] = :klientSluzbaId AND CAST([atributy]->'hodnotenie' AS BOOLEAN)", params: {"klientSluzbaId": props.klientSluzbaId}}, "datum");
        setZapisList(zapisList);
    }

    return (
        <div>
            {Utils.createChips(zapisList.map((zapis: Zapis) => dateAsUI(zapis.datum)))}
        </div>
    );
}
