import React, {useState} from "react";
import {Dialog} from "primereact/dialog";
import {XCalendar} from "@michalrakus/x-react-web-lib/XCalendar";
import {XButton} from "@michalrakus/x-react-web-lib/XButton";
import {Miestnost} from "../../model/noclaharen/miestnost.entity";
import {Utils} from "../../Utils";
import {ReportKlientiPodlaMiestnostiRequest} from "../../common/noclaharen-api";
import {XDropdownForEntity} from "@michalrakus/x-react-web-lib/XDropdownForEntity";
import {XUtils} from "@michalrakus/x-react-web-lib/XUtils";

export const ReportKlientiPodlaMiestnostiDialog = (props: {
    dialogOpened: boolean;
    onHideDialog: () => void;
}) => {

    const [datumOd, setDatumOd] = useState<Date | null>(null);
    const [datumDo, setDatumDo] = useState<Date | null>(null);
    const [miestnost, setMiestnost] = useState<Miestnost | null>(null);

    // bez tejto metody by pri opetovnom otvoreni dialogu ponechal povodne hodnoty
    const onShow = () => {

        const today: Date = Utils.todayNoclaharen();
        setDatumOd(today);
        setDatumDo(today);
        setMiestnost(null);
    }

    const onOk = async () => {

        const reportKlientiPodlaMiestnostiRequest: ReportKlientiPodlaMiestnostiRequest = {datumOd: datumOd, datumDo: datumDo, miestnost: miestnost};
        XUtils.openExcelReport('klienti-podla-miestnosti', reportKlientiPodlaMiestnostiRequest);

        props.onHideDialog();
    }

    // poznamka: renderovanie vnutornych komponentov Dialogu sa zavola az po otvoreni dialogu
    return (
        <Dialog visible={props.dialogOpened} onShow={onShow} onHide={props.onHideDialog}>
            <div className="field grid">
                <label className="col-fixed" style={{width:'7rem'}}>Dátum od</label>
                <XCalendar value={datumOd} onChange={(value: Date | null) => setDatumOd(value)}/>
            </div>
            <div className="field grid">
                <label className="col-fixed" style={{width:'7rem'}}>Dátum do</label>
                <XCalendar value={datumDo} onChange={(value: Date | null) => setDatumDo(value)}/>
            </div>
            <div className="field grid">
                <label className="col-fixed" style={{width:'7rem'}}>Miestnosť</label>
                <XDropdownForEntity entity="Miestnost" displayField="nazov" value={miestnost} onChange={(value: any | null) => setMiestnost(value)}/>
            </div>
            <div className="flex justify-content-center">
                <XButton label="Ok" onClick={onOk}/>
            </div>
        </Dialog>
    );
}
