import React from "react";
import {Form} from "../../XLibItems";
import {XInputDecimal} from "@michalrakus/x-react-web-lib/XInputDecimal";
import {XInputText} from "@michalrakus/x-react-web-lib/XInputText";
import {XFormFooter} from "@michalrakus/x-react-web-lib/XFormFooter";
import {XInputDate} from "@michalrakus/x-react-web-lib/XInputDate";
import {XFormBaseModif} from "@michalrakus/x-react-web-lib/XFormBaseModif";
import {XFieldSet} from "./XFieldSet";
import {XEditor} from "@michalrakus/x-react-web-lib/XEditor";
import {KlientSluzbaAutoComplete} from "../klient/KlientSluzbaAutoComplete";
import {OperationType, XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import type {XFormProps} from "@michalrakus/x-react-web-lib/XFormBase";
import {XErrors} from "@michalrakus/x-react-web-lib/XErrors";
import {ZapisOsetrovna} from "../../model/zapisy/zapis-osetrovna.entity";
import {Utils} from "../../Utils";
import {KlientSluzba} from "../../model/klient/klient-sluzba.entity";
import {XFormHeader} from "@michalrakus/x-react-web-lib/XFormHeader";
import {XFormScrollable} from "../XFormScrollable";

@Form("ZapisOsetrovna")
export class ZapisOsetrovnaForm extends XFormBaseModif {

    private klientSluzbaError: string | undefined;

    constructor(props: XFormProps) {
        super(props);

        // najoinujeme zaznam KlientSluzba, Klient a aj pohlavie, obec a statCiselnik - atributy na tychto zaznamoch su zobrazene v autocomplete na vyber klienta
        this.addField("klientSluzba.klient.pohlavie.name");
        this.addField("klientSluzba.klient.obec.nazov");
        this.addField("klientSluzba.klient.statCiselnik.nazov");
        // najoinujeme aj klientSluzba.klientSluzbaZakazList - pouziva sa na vycervenenie klienta v autocomplete
        this.addField("klientSluzba.klientSluzbaZakazList.id");
    }

    async validate(zapisOsetrovna: ZapisOsetrovna): Promise<XErrors> {
        const errors: XErrors = {};

        if (this.klientSluzbaError) {
            errors.klientSluzba = this.klientSluzbaError;
        }
        else if (!zapisOsetrovna.klientSluzba) {
            errors.klientSluzba = "Klient musí byť vyplnený.";
        }

        return errors;
    }

    render() {
        return (
            <div>
                <XFormScrollable form={this} headerFooterHeight="8rem" widthFitContent={true}>
                    <div className="x-form-row">
                        <div className="x-form-col">
                            <div className="x-form-row justify-content-between">
                                <XFormHeader form={this} label="Zápis - ošetrovňa" style={{marginTop: '0rem', marginBottom: '1rem'}}/>
                                <div className="x-form-inline-row">
                                    <XInputDate form={this} field="modifDate" label="Dátum modif." labelStyle={{width:'9rem'}} readOnly={true}/>
                                    <XInputText form={this} field="modifXUser.name" label="Modifikoval" labelStyle={{width:'9rem'}} inputStyle={{width:'12.35rem'}} readOnly={true}/>
                                    <XInputDecimal form={this} field="id" label="ID" labelStyle={{width:'3rem'}} readOnly={true}/>
                                </div>
                            </div>
                            <div className="x-form-row justify-content-between">
                                <XInputDate form={this} field="datum" label="Dátum" labelStyle={{width:'6rem'}}/>
                                <div className="field grid" style={{width: 'calc(100% - 16rem)'}}>
                                    <label htmlFor="klient" className="col-fixed" style={{width: '6rem'}}>Klient *</label>
                                    <KlientSluzbaAutoComplete value={this.state.object ? this.state.object.klientSluzba : null}
                                                              onChange={(object: KlientSluzba | null, objectChange: OperationType) => {this.state.object.klientSluzba = object; this.setStateXForm();}}
                                                              error={Utils.getError(this.state.errorMap, "klientSluzba")}
                                                              onErrorChange={(error: (string | undefined)) => this.klientSluzbaError = error}
                                                              maxWidth="calc(100% - 6rem)"/>
                                </div>
                            </div>
                            <XEditor form={this} field="vseobecnyZapis" labelOnTop={true}/>{/*bolo: label="Všeobecný zápis" labelOnTop sposobuje sirku na cely form */}
                            <XFieldSet form={this} jsonField="atributy" fieldSetId="zapisOsetrovna" canEdit={Utils.isUserAdmin()}/>
                        </div>
                    </div>
                </XFormScrollable>
                <XFormFooter form={this}/>
            </div>
        );
    }
}
