import React from "react";
import {XFormBase} from "@michalrakus/x-react-web-lib/XFormBase";
import {XDropdown} from "@michalrakus/x-react-web-lib/XDropdown";
import {Utils} from "../../Utils";
import {XFieldOnChange, XReadOnlyProp} from "@michalrakus/x-react-web-lib/lib/components/XFormComponent";

// helper component
export const XDropdownEnum = (props: {form: XFormBase; assocField: string; label?: string; labelStyle?: React.CSSProperties; enumEnumCode: string; readOnly?: XReadOnlyProp; onChange?: XFieldOnChange;}) => {

    return (
        <XDropdown form={props.form} assocField={props.assocField} displayField="name" label={props.label} labelStyle={props.labelStyle} filter={Utils.enumFilter(props.enumEnumCode)} sortField="enumOrder" readOnly={props.readOnly} onChange={props.onChange}/>
    );
}
