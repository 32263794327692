import React, {useState} from "react";
import {Dialog} from "primereact/dialog";
import {XCalendar} from "@michalrakus/x-react-web-lib/XCalendar";
import {XButton} from "@michalrakus/x-react-web-lib/XButton";
import {xLocaleOption} from "@michalrakus/x-react-web-lib/XLocale";

// pomerne vseobecny dialog, mozme presunut aj do lib-ky

export interface XDateFromToDialogState {
    opened: boolean;
    dateFrom?: Date | null;
    dateTo?: Date | null;
    onHideDialog?: (ok: boolean, dateFrom: Date | null, dateTo: Date | null) => void;
}

export const XDateFromToDialog = (props: {
    dialogState: XDateFromToDialogState;
}) => {

    const [dateFrom, setDateFrom] = useState<Date | null>(null);
    const [dateTo, setDateTo] = useState<Date | null>(null);

    // bez tejto metody by pri opetovnom otvoreni dialogu ponechal povodne hodnoty
    const onShow = () => {

        setDateFrom(props.dialogState.dateFrom ?? null);
        setDateTo(props.dialogState.dateTo ?? null);
    }

    const onHide = () => {
        if (props.dialogState.onHideDialog) {
            props.dialogState.onHideDialog(false, null, null);
        }
    }

    // poznamka: renderovanie vnutornych komponentov Dialogu sa zavola az po otvoreni dialogu
    return (
        <Dialog visible={props.dialogState.opened} onShow={onShow} onHide={onHide}>
            <div className="field grid">
                <label className="col-fixed" style={{width:'7rem'}}>{xLocaleOption('dateFrom')}</label>
                <XCalendar value={dateFrom} onChange={(value: Date | null) => setDateFrom(value)}/>
            </div>
            <div className="field grid">
                <label className="col-fixed" style={{width:'7rem'}}>{xLocaleOption('dateTo')}</label>
                <XCalendar value={dateTo} onChange={(value: Date | null) => setDateTo(value)}/>
            </div>
            <div className="flex justify-content-center">
                <XButton label={xLocaleOption('ok')} onClick={() => props.dialogState.onHideDialog!(true, dateFrom, dateTo)}/>
            </div>
        </Dialog>
    );
}
