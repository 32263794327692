import React from "react";
import {
    XBrowseProps,
    XLazyColumn,
    XLazyDataTable,
    XSearchBrowseProps
} from "@michalrakus/x-react-web-lib/XLazyDataTable";
import {XsGroupByFieldForm} from "./XsGroupByFieldForm";

export const XsGroupByFieldBrowse = (props: XBrowseProps & XSearchBrowseProps) => {

    const onAddRow = () => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        props.openForm!(<XsGroupByFieldForm/>);
    }

    const onEdit = (selectedRow: any) => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        props.openForm!(<XsGroupByFieldForm id={selectedRow.id}/>);
    }

    return (
        <XLazyDataTable entity="XsGroupByField" label="Group by fields" sortField="id desc" rows={30}
                        onAddRow={onAddRow} onEdit={onEdit} removeRow={true}
                        displayed={props.displayed} searchBrowseParams={props.searchBrowseParams}>
            <XLazyColumn field="id" header="ID" width="5rem"/>
            <XLazyColumn field="label" header="Label" width="20rem"/>
            <XLazyColumn field="fieldType.name" header="Field type" width="15rem"/>
            <XLazyColumn field="entity" header="Entity" width="15rem"/>
            <XLazyColumn field="displayField" header="Display field" width="15rem"/>
            <XLazyColumn field="xEnumEnum.name" header="Enum" width="15rem"/>
            <XLazyColumn field="xsIntervalSet.label" header="Interval set" width="15rem"/>
        </XLazyDataTable>
    );
}
