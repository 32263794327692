import React, {Component} from "react";
import {OperationType, XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {Dropdown, DropdownChangeEvent} from "primereact/dropdown";
import {XObject} from "@michalrakus/x-react-web-lib/lib/components/XObject";
import {Utils} from "../../Utils";
import {XUserDePaul} from "../../model/user/x-user-de-paul.entity";
import {XUserSluzbaRola} from "../../model/user/x-user-sluzba-rola.entity";
import {Sluzba} from "../../model/user/sluzba.entity";
import {XCustomFilter} from "@michalrakus/x-react-web-lib/FindParam";
import {XUtilsCommon} from "@michalrakus/x-react-web-lib/XUtilsCommon";

export interface SluzbaDropdownProps {
    onChangeSluzba: (sluzba: Sluzba) => void;
}

export class SluzbaDropdown extends Component<SluzbaDropdownProps> {

    state: {
        options: Sluzba[];
        currentSluzba: Sluzba | undefined;
    };

    constructor(props: SluzbaDropdownProps) {
        super(props);

        this.state = {
            options: [],
            currentSluzba: undefined
        };

        this.onChangeSluzba = this.onChangeSluzba.bind(this);
        this.zmenaEntitySluzba = this.zmenaEntitySluzba.bind(this);
    }

    onChangeSluzba(sluzba: Sluzba) {
        this.setState({currentSluzba: sluzba});
        Utils.setCurrentSluzba(sluzba);
        // refreshneme aktualny obsah
        this.props.onChangeSluzba(sluzba);
    }

    componentDidMount() {
        this.findOptions();
        // zapiseme listener na zmenu do statickej premennej v Utils - listener sa zavola ak user spravi nejaku DB operaciu nad nejakym zaznamom Sluzba
        Utils.setZmenaEntitySluzba(this.zmenaEntitySluzba);
    }

    private async findOptions() {
        const xUserDePaul: XUserDePaul = Utils.getXUserDePaul();
        let customFilter: XCustomFilter | undefined = undefined;
        if (!xUserDePaul.admin) {
            customFilter = XUtilsCommon.filterIdIn("id", xUserDePaul.xUserSluzbaRolaList.map((xUserSluzbaRola: XUserSluzbaRola) => xUserSluzbaRola.sluzbaRola.sluzba.id));
        }
        let sluzbaList: Sluzba[] = await XUtils.fetchRows('Sluzba', customFilter, 'nazov');
        this.setState({options: sluzbaList});
        if (sluzbaList.length === 1) {
            this.onChangeSluzba(sluzbaList[0]);
        }
    }

    async zmenaEntitySluzba(object: XObject | null, objectChange: OperationType) {
        if (objectChange !== OperationType.None) {
            // zmenili sa data v DB -> refresneme
            await this.findOptions();
            // ak bola zmenena aktualna sluzba, zapiseme ju do state.currentSluzba
            if (objectChange === OperationType.Update && (object && this.state.currentSluzba && object.id === this.state.currentSluzba.id)) {
                this.setState({currentSluzba: object});
                Utils.setCurrentSluzba(object as Sluzba ?? undefined); // pre istotu (je to ina instancia ako ta v state.options ale je aktualna)
            }
        }
    }

    render() {
        // appendTo={document.body} appenduje overlay panel na element body - eliminuje "skakanie" formularu na mobile pri kliknuti na dropdown
        return (
            <Dropdown appendTo={document.body} id='sluzbaDropdown' optionLabel='nazov' dataKey='id' value={this.state.currentSluzba} options={this.state.options}
                      onChange={(e: DropdownChangeEvent) => this.onChangeSluzba(e.value)}/>
        );
    }
}