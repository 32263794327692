import {XGroupByType} from "./enums";
import {Klient} from "../model/klient/klient.entity";

export class UtilsCommon {

    // mame sice v class-e Klient metodu getObecNazov(), ale ked pride klient z frontend-u (alebo pride na frontend), tak mame object literal (data bez funkcii)
    // preto potrebujeme tuto metodu
    static klientObecNazov(klient: Klient): string | null {
        return klient.obec?.nazov ?? klient.mesto;
    }

    static computePercent(partSum: number | null, baseSum: number | null): number | null {
        let percent: number | null = null;
        if (partSum !== null && baseSum !== null) {
            if (baseSum !== 0) {
                const percentNotRounded: number = partSum / baseSum * 100;
                percent = Math.round(percentNotRounded * 100) / 100;
            }
            else {
                // baseSum === 0
                if (partSum === 0) {
                    percent = 0;
                }
            }
        }
        return percent;
    }

    // pri scitavani number cisel som zaznamenal rozdiel v 14. cifre za des. ciarkou, na porovnavanie preto pouzijeme deltu 0.0000000001 (-10 na -10)
    // 1600.15 + 576.95 vracia 2177.10000000000004
    private static delta: number = 10 ** -10;

    static numberCompare(value1: number | null, value2: number | null): (-1 | 0 | 1) | null {
        let result: (-1 | 0 | 1) | null = null;
        if (value1 !== null && value2 !== null) {
            const difference: number = value1 - value2;
            if (Math.abs(difference) < UtilsCommon.delta) {
                result = 0;
            }
            else if (difference < 0) {
                result = -1;
            }
            else {
                // difference > 0
                result = 1;
            }
        }
        return result;
    }

    static numberRound(value: number | null, scale?: number): number | null {
        if (value !== null) {
            scale = scale ?? 2;
            const multiplier: number = 10 ** scale; // 100 for scale = 2
            value = Math.round(value * multiplier) / multiplier;
        }
        return value;
    }

    // TODO - prehodit do XUtilsCommon
    static minDateIfNull(date: Date | null): Date {
        return date ?? new Date('0001-01-01'); // ak to neda DB, treba skusit pouzit 1970
    }

    // TODO - prehodit do XUtilsCommon
    static maxDateIfNull(date: Date | null): Date {
        return date ?? new Date('9999-12-31');
    }

    // TODO - prehodit do XUtilsCommon
    static arrayRemoveItem(array: any[], item: any) {
        const itemIndex: number = UtilsCommon.arrayIndexOf(array, item);
        array.splice(itemIndex, 1);
    }

    // TODO - prehodit do XUtilsCommon
    static arrayReplaceItem(array: any[], item: any, newItem: any) {
        const itemIndex: number = UtilsCommon.arrayIndexOf(array, item);
        array[itemIndex] = newItem;
    }

    // TODO - prehodit do XUtilsCommon
    static arrayIndexOf(array: any[], item: any): number {
        const itemIndex = array.indexOf(item);
        if (itemIndex === -1) {
            throw 'Unexpected error - item not found in list';
        }
        return itemIndex;
    }

    // TODO - prehodit do XUtilsCommon
    static dateDiff(dateOld: Date | null, dateNew: Date | null): number | null {
        let diff: number | null = null;
        if (dateOld !== null && dateNew !== null) {
            diff = Math.round((dateNew.getTime() - dateOld.getTime()) / (1000 * 60 * 60 * 24));
        }
        return diff;
    }

    // TODO - prehodit do XUtilsCommon
    static xGroupByTypeIsDate(xGroupByType: XGroupByType): boolean {
        return UtilsCommon.xGroupByTypeDateTypeList().includes(xGroupByType);
    }

    // TODO - prehodit do XUtilsCommon
    static xGroupByTypeDateTypeList(): XGroupByType[] {
        return [XGroupByType.day, XGroupByType.month, XGroupByType.year];
    }
}
