import React from "react";
import {
    XBrowseProps,
    XLazyColumn,
    XLazyDataTable, XSearchBrowseProps
} from "@michalrakus/x-react-web-lib/XLazyDataTable";
import {ObecForm} from "./ObecForm";

export const ObecBrowse = (props: XBrowseProps & XSearchBrowseProps) => {

    const onAddRow = () => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        props.openForm!(<ObecForm/>);
    }

    const onEdit = (selectedRow: any) => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        props.openForm!(<ObecForm id={selectedRow.id}/>);
    }

    return (
        <div>
            <XLazyDataTable entity="Obec" label="Obce/mestá" rows={30}
                            sortField="id desc"
                            onAddRow={onAddRow} onEdit={onEdit} removeRow={true}
                            displayed={props.displayed} searchBrowseParams={props.searchBrowseParams}>
                <XLazyColumn field="id" header="ID" width="5rem"/>
                <XLazyColumn field="nazov" header="Názov" width="15rem"/>
                <XLazyColumn field="okres.nazov" header="Okres" width="15rem"/>
                <XLazyColumn field="okres.kraj.nazov" header="Kraj" width="15rem"/>
                <XLazyColumn field="oficialnyZaznam" header="Oficiálny záznam"/>
            </XLazyDataTable>
        </div>
    );
}
