import React from "react";
import {XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {XBrowseProps, XLazyColumn, XLazyDataTable} from "@michalrakus/x-react-web-lib/XLazyDataTable";
import {XUserDePaulForm} from "./XUserDePaulForm";

// upravena kopia XUserBrowse
export const XUserDePaulBrowse = (props: XBrowseProps) => {

    const onAddRow = () => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        props.openForm!(<XUserDePaulForm/>);
    }

    const onEdit = (selectedRow: any) => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        props.openForm!(<XUserDePaulForm id={selectedRow.id}/>);
    }

    const onRemoveRow = async (selectedRow: any): Promise<boolean> => {
        if (selectedRow.username === XUtils.getUsername()) {
            alert("Can not remove current user.");
            return false;
        }

        if (window.confirm('Are you sure to remove the selected row?')) {
            await XUtils.removeRow("XUserDePaul", selectedRow);
            return true;
        }
        return false;
    }

    return (
        <XLazyDataTable entity="XUserDePaul" label="Používatelia" sortField="id" rows={30} onAddRow={onAddRow} onEdit={onEdit} removeRow={onRemoveRow} displayed={props.displayed}>
            <XLazyColumn field="id" header="ID"/>
            <XLazyColumn field="username" header="Username" width="17rem"/>
            <XLazyColumn field="name" header="Name" width="17rem"/>
            <XLazyColumn field="enabled" header="Enabled"/>
            <XLazyColumn field="admin" header="Admin"/>
            <XLazyColumn field="xUserSluzbaRolaList.sluzbaRola.sluzbaRola" header="Služba / Rola" width="30rem"/>
        </XLazyDataTable>
    );
}
