import React from "react";
import {
    XBrowseProps,
    XLazyColumn,
    XLazyDataTable, XSearchBrowseProps
} from "@michalrakus/x-react-web-lib/XLazyDataTable";
import {KlientForm} from "./KlientForm";
import {ColumnBodyOptions} from "primereact/column";
import {AsUIType, convertValueBase, XDateScale} from "@michalrakus/x-react-web-lib/XUtilsConversions";
import {Klient} from "../../model/klient/klient.entity";

// pouziva sa v HladatKlientaMimoSluzbuDialog a tiez v menu Adminstracia na kontrolu/odstranovanie duplicit
// velmi podobne ako KlientSluzbaBrowse
export const KlientBrowse = (props: XBrowseProps & XSearchBrowseProps & {label?: string;}) => {

    const onAddRow = () => {

        alert("Klientov je možné vytvárať len v rámci jednotlivých služieb (cez menu Aplikácia/Nocľaháreň).");
        return;
    }

    const onEdit = (selectedRow: any) => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        props.openForm!(<KlientForm id={selectedRow.id}/>);
    }

    const klientDatumNarodeniaBody = (klient: Klient, options: ColumnBodyOptions): React.ReactNode => {
        return convertValueBase('date', klient.datumNarodeniaIbaRok ? XDateScale.Year : XDateScale.Date, klient.datumNarodenia, true, AsUIType.Form);
    }

    return (
        <XLazyDataTable entity="Klient" label={props.label ?? "Klienti"} sortField="id desc" rows={30}
                        onAddRow={onAddRow} onEdit={onEdit} removeRow={true}
                        displayed={props.displayed} searchBrowseParams={props.searchBrowseParams}>
            <XLazyColumn field="id" header="ID" width="5rem"/>
            <XLazyColumn field="menoPriezviskoPrezyvka" header="Meno priezvisko prezývka" width="20rem"/>
            <XLazyColumn field="datumNarodenia" header="Dátum narodenia" body={klientDatumNarodeniaBody}/>
            <XLazyColumn field="ulica" header="Ulica" width="13rem"/>
            <XLazyColumn field="cisloDomu" header="Číslo domu" width="7rem"/>
            <XLazyColumn field="psc" header="PSČ" width="5rem"/>
            <XLazyColumn field="mesto" header="Mesto/obec (text)" width="11rem"/>
            <XLazyColumn field="obec.nazov" header="Mesto/obec" width="10rem"/>
            <XLazyColumn field="obec.okres.nazov" header="Okres" width="10rem"/>
            <XLazyColumn field="statCiselnik.nazov" header="Štát" width="8rem"/>
        </XLazyDataTable>
    );
}
