import React from "react";
import {XUtils, XViewStatus} from "@michalrakus/x-react-web-lib/XUtils";
import {XOnSaveOrCancelProp} from "@michalrakus/x-react-web-lib/XFormBase";
import {XCustomFilter} from "@michalrakus/x-react-web-lib/FindParam";
import {Rola, SluzbaEnum, TypPrav} from "./common/enums";
import {XUserDePaul} from "./model/user/x-user-de-paul.entity";
import {SluzbaRolaCheckboxMap, SluzbaRolaPrava} from "./model/user/sluzba-rola-prava.entity";
import {XUserSluzbaRola} from "./model/user/x-user-sluzba-rola.entity";
import {Sluzba} from "./model/user/sluzba.entity";
import {
    dateAsUI,
    dateAsYYYY_MM_DD,
    dateFromModel,
    dateFromUI,
    intFromUI,
    XDateScale
} from "@michalrakus/x-react-web-lib/XUtilsConversions";
import {Klient} from "./model/klient/klient.entity";
import {KlientZakazUbytovat} from "./model/klient/klient-zakaz-ubytovat.entity";
import {XError, XErrorMap} from "@michalrakus/x-react-web-lib/XErrors";
import {KlientSluzba} from "./model/klient/klient-sluzba.entity";
import {KlientSluzbaZakaz} from "./model/klient/klient-sluzba-zakaz.entity";
import {Chip} from "primereact/chip";

export class XUserNotFoundOrDisabledError extends Error {
}

export class Utils {

    private static currentSluzba?: Sluzba;
    private static xUserCurrentSluzbaRolaList?: XUserSluzbaRola[]; // sem si nacachujeme vyfiltrovane xUserSluzbaRolaList podla aktualne vykliknutej sluzby

    private static zmenaEntitySluzba: XOnSaveOrCancelProp;

    static getXUserDePaul(): XUserDePaul {
        const xUserDePaul: XUserDePaul = XUtils.getXToken()?.xUser;
        if (!xUserDePaul) {
            throw `Unexpected error: XUtils.getXToken()?.xUser returned undefined/null`;
        }
        return xUserDePaul;
    }

    static isUserAdmin(): boolean {
        return Utils.getXUserDePaul()?.admin;
    }

    static setCurrentSluzba(sluzba: Sluzba) {
        Utils.currentSluzba = sluzba;
        const user: XUserDePaul = Utils.getXUserDePaul();
        // vyfiltrujeme dvojicky sluzba/rola zodpovedajuce aktualnej sluzbe
        Utils.xUserCurrentSluzbaRolaList = user.xUserSluzbaRolaList.filter(
            (xUserSluzbaRola: XUserSluzbaRola) => xUserSluzbaRola.sluzbaRola.sluzba.kod === sluzba.kod);
    }

    static getCurrentSluzba(): Sluzba | undefined {
        return Utils.currentSluzba;
    }

    static getCurrentSluzbaId(): number | undefined {
        return Utils.getCurrentSluzba()?.id;
    }

    static isSluzbaNoclaharen(): boolean {
        return Utils.getCurrentSluzba()?.kod === SluzbaEnum.noclaharen;
    }

    static isSluzbaStreetwork(): boolean {
        return Utils.getCurrentSluzba()?.kod === SluzbaEnum.streetwork;
    }

    static isSluzbaOsetrovnaNDC(): boolean {
        return Utils.getCurrentSluzba()?.kod === SluzbaEnum.osetrovnaNDC;
    }

    static isSluzbaUtulok(): boolean {
        const sluzbaKod: string | undefined = Utils.getCurrentSluzba()?.kod;
        return sluzbaKod === SluzbaEnum.utulokLujza || sluzbaKod === SluzbaEnum.utulokZOS || sluzbaKod === SluzbaEnum.utulokVincent;
    }

    static getXUserCurrentSluzbaRolaList(): XUserSluzbaRola[] | undefined {
        return Utils.xUserCurrentSluzbaRolaList;
    }

    static setZmenaEntitySluzba(zmenaEntitySluzba: XOnSaveOrCancelProp) {
        Utils.zmenaEntitySluzba = zmenaEntitySluzba;
    }

    static getZmenaEntitySluzba(): XOnSaveOrCancelProp {
        return Utils.zmenaEntitySluzba;
    }

    // TODO - prehodit do XUtils
    static enumFilter(enumEnumCode: string): XCustomFilter {
        return {where: `[xEnumEnum.code] = '${enumEnumCode}' AND [enabled] = :enabled`, params: {enabled: true}};
    }

    static async getSequenceValue(sequenceName: string): Promise<number> {
        const {value} = await XUtils.fetch('x-get-sequence-value', {name: sequenceName});
        return value;
    }

    static klientFilterCurrentSluzba(ostatniKlienti: boolean = false): XCustomFilter {
        if (Utils.isSluzbaNoclaharen()) {
            // pri noclaharni sa riadime priznakom noclaharen ((zatial) nepouzivame zaznam KlientSluzba)
            return {where: "[noclaharen] = :noclaharen", params: {"noclaharen": !ostatniKlienti}};
        }
        else {
            return {where: `${ostatniKlienti ? "NOT " : ""}EXISTS (SELECT 1 FROM ${Utils.getSchema()}.klient_sluzba ks WHERE ks.klient_id = [id] AND ks.sluzba_id = :sluzbaId)`, params: {"sluzbaId": Utils.getCurrentSluzbaId() ?? 0}};
        }
    }

    static klientFilterNoclaharen(): XCustomFilter {
        return {where: "[noclaharen] = :noclaharen", params: {"noclaharen": true}};
    }

    // static klientFilterSluzba(sluzbaEnum: SluzbaEnum): XCustomFilter {
    //     return {where: "[sluzba] IS NULL OR [sluzba.kod] = :sluzbaKod", params: {"sluzbaKod": sluzbaEnum}};
    // }

    // toto treba pouzivat na entitach ktore obsahuju ManyToOne asociaciu sluzba (KlientSluzba, Zmluva a pod.)
    static filterCurrentSluzba(): XCustomFilter {
        return {where: "[sluzba] = :sluzbaId", params: {"sluzbaId": Utils.getCurrentSluzbaId() ?? 0}};
    }

    static filterSluzba(sluzbaEnum: SluzbaEnum): XCustomFilter {
        return {where: "[sluzba.kod] = :sluzbaKod", params: {"sluzbaKod": sluzbaEnum}};
    }

    /**
     * vrati prava vo forme XViewStatus
     * @param sluzbaRolaPrava
     */
    static userPravoViewStatus(sluzbaRolaPrava: SluzbaRolaPrava): XViewStatus {

        const currentSluzba: Sluzba | undefined = Utils.getCurrentSluzba();
        if (!currentSluzba) {
            return XViewStatus.Hidden; // nema vybratu sluzbu v comboboxe v menu
        }

        let pravo: XViewStatus = XViewStatus.Hidden; // default
        const sluzbaRolaMap: SluzbaRolaCheckboxMap | undefined = sluzbaRolaPrava.prava[currentSluzba.kod];
        if (sluzbaRolaMap) {
            const xUserCurrentSluzbaRolaList: XUserSluzbaRola[] | undefined = Utils.getXUserCurrentSluzbaRolaList();
            if (!xUserCurrentSluzbaRolaList) {
                return XViewStatus.Hidden; // nemalo by vobec nastat, ak nemame vybratu sluzbu, uz to vyskoci vyssie
            }
            if (sluzbaRolaPrava.typPrav.code === TypPrav.lenCheckbox) {
                // ak user ma nejaku dvojicku sluzba/rola, ktora je zaskrtnuta tak mame readWrite prava
                if (xUserCurrentSluzbaRolaList.some((xUserSluzbaRola: XUserSluzbaRola) => sluzbaRolaMap[xUserSluzbaRola.sluzbaRola.id])) {
                    pravo = XViewStatus.ReadWrite;
                }
            }
            else if (sluzbaRolaPrava.typPrav.code === TypPrav.citanieZapis) {
                // najdeme co najvyssie pravo
                for (const xUserSluzbaRola of xUserCurrentSluzbaRolaList) {
                    const viewStatus: XViewStatus | undefined = sluzbaRolaMap[xUserSluzbaRola.sluzbaRola.id] as XViewStatus | undefined;
                    if (viewStatus !== undefined) {
                        if (viewStatus === XViewStatus.ReadWrite) {
                            // najvyssia uroven
                            pravo = XViewStatus.ReadWrite;
                        }
                        else if (viewStatus === XViewStatus.ReadOnly) {
                            // ak mame najnizsiu uroven, tak ju zvysime
                            if (pravo === XViewStatus.Hidden) {
                                pravo = XViewStatus.ReadOnly;
                            }
                        }
                    }
                }
            }
            else {
                throw 'Unexpected value of sluzbaRolaPrava.typPrav.code = ' + sluzbaRolaPrava.typPrav.code;
            }
        }
        return pravo;
    }

    /**
     * vrati prava vo forme boolean
     * malo by sa pouzivat pre typPrav = lenCheckbox
     * pre typPrav = citanieZapis robime transformaciu XViewStatus.ReadWrite -> true, XViewStatus.ReadOnly a XViewStatus.Hidden -> false
     * @param sluzbaRolaPrava
     */
    static userPravoBoolean(sluzbaRolaPrava: SluzbaRolaPrava): boolean {

        let viewStatus: XViewStatus = Utils.userPravoViewStatus(sluzbaRolaPrava);
        return viewStatus === XViewStatus.ReadWrite;
    }

    /**
     * vrati true ak ma aktualny user v dropdowne pre vyber sluzby vybratu danu sluzbu "sluzbaEnum"
     * a zaroven je bud admin (ma pravo na vsetko) alebo ak nie je admin tak ma priradenu nejaku dvojicku (sluzba, rola)
     * kde sluzba je "sluzbaEnum" a rola je jedna z roli zadanych v array "rolaCode" (napr. "veduci", "socialnyPracovnik", ...)
     *
     * @param sluzbaEnum
     * @param rolaCode
     */
    static userMaSluzbuRolu(sluzbaEnum: SluzbaEnum, ...rolaCode: Rola[]): boolean {

        const currentSluzba: Sluzba | undefined = Utils.getCurrentSluzba();
        if (!currentSluzba) {
            return false; // nema vybratu sluzbu v comboboxe v menu
        }

        if (currentSluzba.kod !== sluzbaEnum) {
            return false; // nie je vybrata spravna sluzba
        }

        let maSluzbuRolu: boolean = false; // default
        const user: XUserDePaul = Utils.getXUserDePaul();
        if (user.admin) {
            maSluzbuRolu = true;
        }
        else {
            const xUserCurrentSluzbaRolaList: XUserSluzbaRola[] | undefined = Utils.getXUserCurrentSluzbaRolaList();
            if (!xUserCurrentSluzbaRolaList) {
                return false; // nemalo by vobec nastat, ak nemame vybratu sluzbu, uz to vyskoci vyssie
            }
            maSluzbuRolu = xUserCurrentSluzbaRolaList.some(
                (xUserSluzbaRola: XUserSluzbaRola) => rolaCode.includes(xUserSluzbaRola.sluzbaRola.rola.code as Rola));
        }

        return maSluzbuRolu;
    }

    /**
     * vrati logicky datum - ak mame aktualny cas od 0:00 do 11:00 (11 je parameter n_denPredlzenyOxHodin) tak vrati datum predchadzajuceho dna
     */
    static todayNoclaharen(): Date {
        // TODO - tahat z parametrov, ked budu nacachovane
        //const denPredlzenyOxHodin: number = Utils.getXParamValueAsInt(Param.n_denPredlzenyOxHodin);
        const denPredlzenyOxHodin: number = 9; // zatial natvrdo
        const today = new Date();
        // ked pouzivam kombinaciu today.setUTCHours + today.getHours() tak to funguje tak ako ma, minimalne pre letny cas
        //today.setUTCHours(today.getHours() - denPredlzenyOxHodin); // posunieme cas dozadu o x hodin (aktualny den klesne o 1 ak sme v case 0:00 - 11:00)
        today.setHours(today.getHours() - denPredlzenyOxHodin); // posunieme cas dozadu o x hodin (aktualny den klesne o 1 ak sme v case 0:00 - 11:00)

        // vynulujeme casovu zlozku
        // poznamka: Date vzdy obsahuje aj casovu zlozku. Nase konverzne funkcie dateFromModel a dateFromUI pouzivaju konverziu new Date('YYYY-MM-DD')
        // a tato konverzia vytvara datum s GMT/UTC/Z casom 00:00:00 (stredoeuropsky 00:01:00 - akokeby sme zadavali new Date('YYYY-MM-DDT00:00:00Z'))
        //today.setHours(0, 0, 0, 0); // nastavi cas 00:00:00 v aktualnej timezone (stredoeuropsky 00:00:00, GMT 23:00:00)
                                    // - potom nam nefunguje porovnavanie s datumami vytvorenymi cez funkcie dateFromModel a dateFromUI
        //today.setUTCHours(0, 0, 0, 0);
        return new Date(dateAsYYYY_MM_DD(today));;
    }

    static klientMaZakazUbytovatSa(klient: Klient, today: Date): boolean {
        return klient.klientZakazUbytovatList.some((value: KlientZakazUbytovat) => dateFromModel(value.datumOd)! <= today && today <= dateFromModel(value.datumDo)!);
    }

    static klientSluzbaMaZakaz(klientSluzba: KlientSluzba, today: Date): boolean {
        return klientSluzba.klientSluzbaZakazList.some((value: KlientSluzbaZakaz) => dateFromModel(value.datumOd)! <= today && today <= dateFromModel(value.datumDo)!);
    }

    // pomocna metodka
    static klientCreateLabel(label: string, klient: Klient | null | undefined): string {
        if (klient) {
            if (klient.meno) {
                label += " " + klient.meno;
            }
            if (klient.priezvisko) {
                label += " " + klient.priezvisko;
            }
            if (klient.prezyvka) {
                label += " " + klient.prezyvka;
            }
        }
        return label;
    }

    // pomocna metodka
    static klientCreateIDInfo(klient: Klient): string {
        return `${klient.meno} ${klient.priezvisko} ${dateAsUI(dateFromModel(klient.datumNarodenia), klient.datumNarodeniaIbaRok ? XDateScale.Year : XDateScale.Date)}`;
    }

    static createChips(labelList: string[]): JSX.Element[] {
        return labelList.map((value: string) => <Chip label={value} className="m-1"/>);
    }

    // zatial takto natvrdo
    private static schema: string = "depaul";

    static getSchema(): string {
        return Utils.schema;
    }

    // TODO - presunut do XUtils
    static async getXParamValueAsInt(paramCode: string): Promise<number> {
        const paramValue: string = await Utils.getXParamValue(paramCode);
        const paramValueInt: number | null | undefined = intFromUI(paramValue);
        if (paramValueInt === null || paramValueInt === undefined) {
            throw `Param ${paramCode}: could not convert param value ${paramValue} to int.`;
        }
        return paramValueInt;
    }

    // TODO - presunut do XUtils
    static async getXParamValueAsDate(paramCode: string): Promise<Date> {
        const paramValue: string = await Utils.getXParamValue(paramCode);
        const paramValueDate: Date | null | undefined = dateFromUI(paramValue);
        if (paramValueDate === null || paramValueDate === undefined) {
            throw `Param ${paramCode}: could not convert param value ${paramValue} to date.`;
        }
        return paramValueDate;
    }

    // TODO - presunut do XUtils
    static async getXParamValue(paramCode: string): Promise<string> {
        const paramValue: string | null = await XUtils.fetchString('x-get-param-value', {code: paramCode});
        if (paramValue === null || paramValue === "") {
            throw `Param ${paramCode}: param value is empty.`;
        }
        return paramValue;
    }

    // TODO - presunut do XUtils
    static getError(errorMap: XErrorMap, field: string): string | undefined {
        const error: XError = errorMap[field];
        return error ? XUtils.getErrorMessage(error) : undefined;
    }
}
