import React from "react";
import {Password} from "primereact/password";
import {Form} from "../../XLibItems";
import {OperationType, XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {XEnvVar, XReactAppAuth} from "@michalrakus/x-react-web-lib/XEnvVars";
import {XInputDecimal} from "@michalrakus/x-react-web-lib/XInputDecimal";
import {XInputText} from "@michalrakus/x-react-web-lib/XInputText";
import {XCheckbox} from "@michalrakus/x-react-web-lib/XCheckbox";
import {XFormFooter} from "@michalrakus/x-react-web-lib/XFormFooter";
import {XFormBaseModif} from "@michalrakus/x-react-web-lib/XFormBaseModif";
import {XInputDate} from "@michalrakus/x-react-web-lib/XInputDate";
import {XFormAutoCompleteColumn, XFormColumn, XFormDataTable2} from "@michalrakus/x-react-web-lib/XFormDataTable2";
import {XFieldChangeEvent} from "@michalrakus/x-react-web-lib/XFieldChangeEvent";
import {XObject} from "@michalrakus/x-react-web-lib/lib/components/XObject";
import {XFormHeader} from "@michalrakus/x-react-web-lib/XFormHeader";

// upravena kopia XUserForm
@Form("XUserDePaul")
export class XUserDePaulForm extends XFormBaseModif {

    constructor(props: any) {
        super(props);

        this.state.usernameEnabledReadOnly = false;
        this.state.passwordNew = '';
        this.state.passwordNewConfirm = '';

        this.onClickSave = this.onClickSave.bind(this);
    }

    createNewObject(): XObject {
        return {enabled: true, admin: false, xUserSluzbaRolaList: [], version: 0};
    }

    preInitForm(object: XObject, operationType: OperationType.Insert | OperationType.Update) {
        // aktualny user si nemoze zmenit username, enabled a admin status
        if (operationType === OperationType.Update && object.username === XUtils.getUsername()) {
            this.setState({usernameEnabledReadOnly: true});
        }
    }

    onChangeAdmin(e: XFieldChangeEvent) {
        // ma zmysel, aby admin bol napr. pracovnik streetworku, lebo len pracovnikov streetworku je mozne zapisat do vykazu
        // ak bol zaskrtnuty admin, treba vyprazdnit xUserSluzbaRolaList, ak nie je prazdny
        //if (e.object.admin && e.object.xUserSluzbaRolaList.length > 0) {
        //    e.object.xUserSluzbaRolaList = [];
        //}
    }

    async onClickSave(): Promise<void> {

        if (!this.validateSave()) {
            return;
        }

        // password is used only by local authorization
        if (XUtils.getEnvVarValue(XEnvVar.REACT_APP_AUTH) === XReactAppAuth.LOCAL) {
            if (this.isAddRow() && this.state.passwordNew === '') {
                alert("Password is required.");
                return;
            }

            if (this.state.passwordNew !== '' || this.state.passwordNewConfirm !== '') {

                // nedovolime tuto zmenit heslo aktualne prihlasenemu uzivatelovi, lebo by sme museli upravit aj token
                if (this.state.object.username === XUtils.getUsername()) {
                    alert("Please, change your password via option Administration -> Change password.");
                    return;
                }

                if (this.state.passwordNew !== this.state.passwordNewConfirm) {
                    alert("New password and confirmed new password are not equal.");
                    return;
                }

                // zapiseme nove heslo do objektu
                this.state.object.password = this.state.passwordNew;
            }
            else {
                // nemenime heslo (atribut s hodnotou undefined sa nezapise do DB)
                this.state.object.password = undefined;
            }
        }

        this.preSave(this.state.object);

        // zapise this.state.object do DB - samostatny servis koli hashovaniu password-u
        try {
            await XUtils.post('userSaveRow', {entity: this.getEntity(), object: this.state.object});
        }
        catch (e) {
            XUtils.showErrorMessage("Save row failed.", e);
            return; // zostavame vo formulari
        }
        (this.props as any).openForm(null); // save zbehol, ideme naspet do browsu
    }

    render() {
        // autoComplete="new-password" - bez tohto chrome predplna user/password, ak si user da ulozit user/password (pre danu url)
        let passwordElems: any[] = [];
        if (XUtils.getEnvVarValue(XEnvVar.REACT_APP_AUTH) === XReactAppAuth.LOCAL) {
            passwordElems = [
                <div className="field grid">
                    <label className="col-fixed" style={{width:'14rem'}}>New password</label>
                    <Password value={this.state.passwordNew} onChange={(e: any) => this.setState({passwordNew: e.target.value})} feedback={false} maxLength={64} size={20} autoComplete="new-password"/>
                </div>,
                <div className="field grid">
                    <label className="col-fixed" style={{width:'14rem', whiteSpace:'nowrap'}}>Confirm new password</label>
                    <Password value={this.state.passwordNewConfirm} onChange={(e: any) => this.setState({passwordNewConfirm: e.target.value})} feedback={false} maxLength={64} size={20} autoComplete="new-password"/>
                </div>
            ];
        }

        return (
            <div>
                <XFormHeader form={this} label="Používateľ"/>
                <div className="x-form-row">
                    <div className="x-form-col">
                        <XInputDecimal form={this} field="id" label="ID" readOnly={true} labelStyle={{width:'14rem'}}/>
                        <XInputDate form={this} field="modifDate" label="Dátum modif." readOnly={true} labelStyle={{width:'14rem'}}/>
                        <XInputText form={this} field="modifXUser.name" label="Modifikoval" size={20} labelStyle={{width:'14rem'}}/>
                        <XInputText form={this} field="username" label="Username" size={30} labelStyle={{width:'14rem'}} readOnly={this.state.usernameEnabledReadOnly}/>
                        <XInputText form={this} field="name" label="Name" size={30} labelStyle={{width:'14rem'}} onChange={this.onChangeAdmin}/>
                        <XCheckbox form={this} field="enabled" label="Enabled" labelStyle={{width:'14rem'}} readOnly={this.state.usernameEnabledReadOnly}/>
                        <XCheckbox form={this} field="admin" label="Admin" labelStyle={{width:'14rem'}} readOnly={this.state.usernameEnabledReadOnly} onChange={this.onChangeAdmin}/>
                        {passwordElems}
                        <XFormDataTable2 form={this} assocField="xUserSluzbaRolaList" label="Služba / Rola">
                            <XFormColumn field="id" header="ID" readOnly={true} width="4rem"/>
                            <XFormAutoCompleteColumn assocField="sluzbaRola" displayField="sluzbaRola" header="Služba / Rola" width="25rem"/>
                        </XFormDataTable2>
                    </div>
                </div>
                <XFormFooter form={this}/>
            </div>
        );
    }
}
