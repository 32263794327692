import React, {CSSProperties} from "react";
import {Component} from "react";
import {FileUpload, FileUploadHandlerEvent} from "primereact/fileupload";
import {xLocaleOption} from "@michalrakus/x-react-web-lib/XLocale";
import {XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {numberAsUI} from "@michalrakus/x-react-web-lib/XUtilsConversions";
import {XButton} from "@michalrakus/x-react-web-lib/XButton";
import {XButtonIconNarrow} from "@michalrakus/x-react-web-lib/XButtonIconNarrow";
import axios, {AxiosProgressEvent} from "axios";
import {XUtilsCommon} from "@michalrakus/x-react-web-lib/XUtilsCommon";
import {ProgressBar} from "primereact/progressbar";
import {XFileJsonField} from "@michalrakus/x-react-web-lib/lib/serverApi/XFileJsonField";
import {XFile} from "../../model/user/x-file.entity";

export interface XInputFileBaseProps {
    id?: string;
    value: XFile | null;
    onChange: (value: XFile | null) => void;
    readOnly?: boolean;
    error?: string;
    style?: CSSProperties | undefined;
    saveDest: "fileSystem" | "database";
    subdir?: string; // subdirectory, where to save uploaded file (for the case saveDest === "fileSystem")
    maxFileSize?: number; // maximum file size allowed in bytes
    downloadOnly?: boolean; // if true, no upload and remove buttons rendered (readOnly === true renders those buttons disabled)
    downloadAndRemoveOnly?: boolean; // if true, only download and remove buttons rendered (no upload button rendered)
}

export class XInputFileBase extends Component<XInputFileBaseProps> {

    public static defaultProps = {
        saveDest: "fileSystem"
    };

    fileUploadRef: any;

    state: {
        uploadInProgress: boolean;
        progressState: number | undefined;
    };

    //props: XInputFileListProps2;

    constructor(props: XInputFileBaseProps) {
        super(props);

        this.fileUploadRef = React.createRef();

        this.state = {
            uploadInProgress: false,
            progressState: undefined
        };

        //this.props = props;

        this.onDownloadFile = this.onDownloadFile.bind(this);
        this.onRemoveFile = this.onRemoveFile.bind(this);
        this.uploadHandler = this.uploadHandler.bind(this);

        //this.onProgress = this.onProgress.bind(this);
    }

    async uploadHandler(event: FileUploadHandlerEvent) {
        //const file = event.files[0];

        const endpoint: string = this.props.saveDest === 'fileSystem' ? 'x-upload-file-into-file-system' : 'x-upload-file-into-db';

        for (const file of event.files) {
            // skontrolujeme velkost - robime to tuto, lebo ked nastavime maxFileSize na komponente FileUpload, tak prilis velky subor sem do handlera ani neposle
            if (this.props.maxFileSize !== undefined && file.size > this.props.maxFileSize) {
                alert(xLocaleOption('fileUploadSizeToBig', {fileName: file.name, fileSize: XInputFileBase.sizeInMB(file.size), maxFileSize: XInputFileBase.sizeInMB(this.props.maxFileSize)}))
                continue; // ideme na dalsi subor
            }
            // uploadneme subor na server, insertne sa tam zaznam XFile a tento insertnuty zaznam pouzijeme
            const jsonFieldValue: XFileJsonField = {
                filename: file.name,
                subdir: this.props.subdir,
                modifDate: new Date(),
                modifXUser: XUtils.getXToken()?.xUser.id
            }

            let xFile: XFile;
            // POVODNY KOD:
            // try {
            //     xFile = await XUtils.fetchFile(endpoint, jsonFieldValue, file);
            // }
            // catch (e) {
            //     XUtils.showErrorMessage(xLocaleOption('fileUploadFailed', {fileName: file.name}), e);
            //     this.fileUploadRef.current.clear(); // vyprazdnime hidden input, nech moze user znova zadat subory
            //     return; // prerusime upload tohto a dalsich suborov
            // }

            this.setState({uploadInProgress: true, progressState: 0});

            const formData = new FormData();
            formData.append(
                "jsonField",
                XUtilsCommon.objectAsJSON(jsonFieldValue)
            );
            formData.append(
                "fileField",
                file,
                file.name
            );
            let accessToken: string = await XUtils.getAccessToken();
            const headers = {'Authorization': `Bearer ${accessToken}`};
            let response: {data: any; status: any;};
            try {
                response = await axios.postForm(XUtils.getXBackendUrl() + endpoint, formData, {headers: headers, onUploadProgress: (progressEvent: AxiosProgressEvent) => {
                        //console.log(progressEvent);
                        if (progressEvent.progress) {
                            this.setState({progressState: progressEvent.progress * 100});
                        }
                    }});
            }
            catch (error) {
                XUtils.showErrorMessage(xLocaleOption('fileUploadFailed', {fileName: file.name}), error);
                this.fileUploadRef.current.clear(); // vyprazdnime hidden input, nech moze user znova zadat subory
                this.setState({uploadInProgress: false, progressState: undefined});
                return; // prerusime upload tohto a dalsich suborov
            }

            this.setState({uploadInProgress: false, progressState: undefined});

            //console.log(`data = ${JSON.stringify(response.data)}`);
            //console.log(`status = ${response.status}`);

            xFile = response.data as XFile;

            this.props.onChange(xFile);
        }

        // vymaze zaznamy v event.files (hidden input type="file"), sposobi ze tlacitko "+Pridat" otvori dialog na vyber suborov
        this.fileUploadRef.current.clear();
    }

    // TODO - do XUtils
    static sizeInMB(size: number): string {
        const sizeInMB = size / (10 ** 6);
        return numberAsUI(sizeInMB, 2) + ' MB'; // zobrazime 2 desatinky
    }

    async onDownloadFile() {
        let response;
        try {
            response = await XUtils.fetchBasicJson('x-download-file', {xFileId: this.props.value!.id});
        }
        catch (e) {
            XUtils.showErrorMessage(xLocaleOption('fileDownloadFailed'), e);
            return;
        }
        const fileName = this.props.value!.name;
        // let respJson = await response.json(); - konvertuje do json objektu
        let respBlob = await response.blob();

        // download blob-u (download by mal fungovat asynchronne a "stream-ovo" v spolupraci so servrom)
        let url = window.URL.createObjectURL(respBlob);
        let a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
    }

    onRemoveFile() {
        // poznamka: nemozme zmazat zaznam na backend-e, lebo ak user ukonci editaciu formulara tlacitkom Cancel (alebo odide uplne prec),
        // tak musime mat v databaze zachovany povodny stav dat/suborov
        this.props.onChange(null);
    }

    // onProgress() {
    //     let progress: number = this.state.progressState ?? 0;
    //     progress += 10;
    //     this.setState({uploadInProgress: true, progressState: progress});
    // }

    render() {
        let elemList: JSX.Element[] = [];

        if (this.state.uploadInProgress) {
            elemList.push(
                <ProgressBar value={this.state.progressState} showValue={false}/>
            );
        }
        else {
            if (!this.props.downloadOnly && !this.props.downloadAndRemoveOnly) {
                elemList.push(
                    <FileUpload ref={this.fileUploadRef} mode="basic" auto customUpload uploadHandler={this.uploadHandler}
                                chooseOptions={{icon: "pi pi-upload"}} chooseLabel={xLocaleOption('upload')} className="m-1" disabled={this.props.readOnly}/>
                );
            }
            if (this.props.value) {
                elemList.push(
                    <XButton label={this.props.value.name} onClick={this.onDownloadFile}/>
                );
                if (!this.props.downloadOnly) {
                    elemList.push(
                        <XButtonIconNarrow icon="pi pi-times" onClick={this.onRemoveFile} disabled={this.props.readOnly}/>
                    );
                }
            }
        }

        // buttony v elemList do riadku a nowrap
        return (
            <div className="flex flex-row flex-nowrap">
                {elemList}
            </div>
        );
    }
}