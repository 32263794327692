import React, {useEffect, useState} from "react";
import {OperationType, XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {Utils} from "../../Utils";
import {XAutoCompleteBase} from "@michalrakus/x-react-web-lib/XAutoCompleteBase";
import {CinnostDen} from "../../model/klient/cinnost-den.entity";
import {KlientSluzba} from "../../model/klient/klient-sluzba.entity";
import {KlientSluzbaForm} from "./KlientSluzbaForm";
import {XCustomFilter} from "@michalrakus/x-react-web-lib/FindParam";
import {dateFromModel} from "@michalrakus/x-react-web-lib/XUtilsConversions";
import {XUtilsCommon} from "@michalrakus/x-react-web-lib/XUtilsCommon";

export const HromEvidKlientAutoComplete = (props: {cinnostDen: CinnostDen; klientSluzba: KlientSluzba; onChange: (klientSluzba: KlientSluzba, objectChange: OperationType) => void;}) => {

    const [klientSluzbaList, setKlientSluzbaList] = useState<KlientSluzba[]>([]);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        // klient musi mat aktivnu zmluvu ku dnu pre ktory vytvarame cinnost
        const filter: XCustomFilter | undefined = XUtilsCommon.filterAnd(
                Utils.filterCurrentSluzba(),
                {
                    where: `EXISTS (SELECT 1 FROM ${Utils.getSchema()}.zmluva z `
                        + `WHERE z.klient_sluzba_id = [id] `
                        + `AND z.datum_od <= :datum AND (z.datum_do >= :datum OR z.datum_do IS NULL))`,
                    params: {datum: dateFromModel(props.cinnostDen.datum)}
                }
            );

        let klientSluzbaList: any[] = await XUtils.fetchRows('KlientSluzba', filter, "klient.menoPriezviskoPrezyvka", ["klient.menoPriezviskoPrezyvka"]);
        setKlientSluzbaList(klientSluzbaList);
    }

    const onErrorChange = (error: string | undefined) => {
        //this.errorInBase = error; // odlozime si error
        // mohli by sme setnut do filtra null, ak error !== undefined, potom by pri chybnom zadani nevalidnej polozky zobrazil vsetky zaznamy
    }

    const vyfiltrujUzZadanychKlientov = (klientSluzbaList: KlientSluzba[], cinnostDen: CinnostDen, klientSluzba: KlientSluzba): KlientSluzba[] => {
        // aby to bolo jednoduchsie/efektivnejsie, tak si najprv vytvorime Set zadanych klientov
        const zadanyKlientSluzbaIdSet = new Set<number>();
        for (const cinnost of cinnostDen.cinnostList) {
            const zadanyKlientSluzba: KlientSluzba = cinnost.klientSluzba;
            if (zadanyKlientSluzba !== null) {
                zadanyKlientSluzbaIdSet.add(zadanyKlientSluzba.id);
            }
        }
        // klienta setnuteho v tomto autocomplete ponechame
        if (klientSluzba !== null) {
            zadanyKlientSluzbaIdSet.delete(klientSluzba.id);
        }
        return klientSluzbaList.filter((klientSluzba: KlientSluzba) => !zadanyKlientSluzbaIdSet.has(klientSluzba.id));
    }

    return (
        <XAutoCompleteBase value={props.klientSluzba} suggestions={vyfiltrujUzZadanychKlientov(klientSluzbaList, props.cinnostDen, props.klientSluzba)} field="klient.menoPriezviskoPrezyvka"
                           onChange={props.onChange} onErrorChange={onErrorChange}
                            /*searchBrowse={<KlientSluzbaBrowse/>}*/ valueForm={<KlientSluzbaForm/>} addRowEnabled={false} idField="id"/>
    );
}
