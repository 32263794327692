import {XLazyColumn, XLazyDataTable} from "@michalrakus/x-react-web-lib/XLazyDataTable";
import React from "react";
import {ZapisOsetrovnaForm} from "./ZapisOsetrovnaForm";
import {XUtilsCommon} from "@michalrakus/x-react-web-lib/XUtilsCommon";

// poznamka: ZapisOsetrovna sa od zaznamu Zapis lisi hlavne tym ze neobsahuje atributy psych/soc poradenstvo (uzivatelia a casy)
//          je mozne ze pre osetrovnu sa nebudu vytvarat ziadne zaznamy Zapis, ale zatial su zaznamy Zapis povolene pre vsetky sluzby
export const ZapisOsetrovnaBrowse = (props: {}) => {

    const onAddRow = () => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        (props as any).openForm(<ZapisOsetrovnaForm initValues={{klientSluzba: null, datum: XUtilsCommon.today(), atributy: {}, version: 0}}/>);
    }

    const onEdit = (selectedRow: any) => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        (props as any).openForm(<ZapisOsetrovnaForm id={selectedRow.id}/>);
    }

    return (
        <XLazyDataTable entity="ZapisOsetrovna" label="Zápisy - ošetrovňa" sortField={[{field: "datum", order: -1}, {field: "id", order: -1}]} rows={30}
                        onAddRow={onAddRow} onEdit={onEdit} removeRow={true} displayed={(props as any).displayed} multilineSwitch={true}>
            <XLazyColumn field="id" header="ID" width="5rem"/>
            <XLazyColumn field="datum" header="Dátum" betweenFilter="column"/>
            <XLazyColumn field="klientSluzba.klient.menoPriezviskoPrezyvka" header="Klient" width="15rem"/>
            <XLazyColumn field="vseobecnyZapis" header="Všeobecný zápis" width="60rem" contentType="html"/>
        </XLazyDataTable>
    );
}
