import React from "react";
import {
    XBrowseProps,
} from "@michalrakus/x-react-web-lib/XLazyDataTable";
import {XParamBrowse} from "@michalrakus/x-react-web-lib/XParamBrowse";

// toto je trosku hack - XParamNoclaharenBrowse sme vytvorili len na to aby pri zmene sluzby zrusil pripadny otvoreny XParamNoclaharenBrowse
// (je to len kozmeticka vec)
export const XParamNoclaharenBrowse = (props: XBrowseProps) => {

    // filter [code] LIKE 'n_%' filtruje len parametre tykajuce sa noclaharne
    return (
        <XParamBrowse displayed={props.displayed} openForm={props.openForm} customFilter={{where: "[code] LIKE 'n_%'", params: {}}}/>
    );
}