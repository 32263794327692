import React from "react";
import {Form} from "../../XLibItems";
import {XInputDecimal} from "@michalrakus/x-react-web-lib/XInputDecimal";
import {XInputText} from "@michalrakus/x-react-web-lib/XInputText";
import {XFormFooter} from "@michalrakus/x-react-web-lib/XFormFooter";
import {XInputDate} from "@michalrakus/x-react-web-lib/XInputDate";
import {XAutoComplete} from "@michalrakus/x-react-web-lib/XAutoComplete";
import {Utils} from "../../Utils";
import {EnumEnum, SluzbaEnum} from "../../common/enums";
import {XFormAutoCompleteColumn, XFormColumn, XFormDataTable2} from "@michalrakus/x-react-web-lib/XFormDataTable2";
import {VykazStreetwork} from "../../model/zapisy/vykaz-streetwork.entity";
import {Zapis} from "../../model/zapisy/zapis.entity";
import type {XFormProps} from "@michalrakus/x-react-web-lib/XFormBase";
import {XFormBase} from "@michalrakus/x-react-web-lib/XFormBase";
import {XButtonIconMedium} from "@michalrakus/x-react-web-lib/XButtonIconMedium";
import {OperationType, XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {XUserDePaul} from "../../model/user/x-user-de-paul.entity";
import {VykazXUser} from "../../model/zapisy/vykaz-x-user.entity";
import {XErrors} from "@michalrakus/x-react-web-lib/XErrors";
import {FindUsersRequest} from "../../common/user-api";
import {MiestoMoznehoKontaktuBrowse} from "./MiestoMoznehoKontaktuBrowse";
import {MiestoMoznehoKontaktuForm} from "./MiestoMoznehoKontaktuForm";
import {ZapisPanel} from "./ZapisPanel";
import {ZapisBaseForm} from "./ZapisBaseForm";
import {XEditor} from "@michalrakus/x-react-web-lib/XEditor";
import {XFormHeader} from "@michalrakus/x-react-web-lib/XFormHeader";
import {XFormScrollable} from "../XFormScrollable";
import {XFieldChangeEvent} from "@michalrakus/x-react-web-lib/XFieldChangeEvent";

@Form("VykazStreetwork")
export class VykazStreetworkForm extends ZapisBaseForm {

    userStreetworkList: XUserDePaul[] = [];

    constructor(props: XFormProps) {
        super(props);

        // najoinujeme tieto asociacie (lebo cela asociacia zapisList je odprogramovana rucne)
        // najoinujeme zaznam KlientSluzba, Klient a aj pohlavie, obec a stat - atributy na tychto zaznamoch su zobrazene v autocomplete na vyber klienta
        this.addField("zapisList.klientSluzba.klient.pohlavie.name");
        //this.addField("zapisList.klientSluzba.klient.mestskaCast.name"); rusime
        this.addField("zapisList.klientSluzba.klient.obec.nazov");
        this.addField("zapisList.klientSluzba.klient.statCiselnik.nazov");
        // najoinujeme aj klientSluzba.klientSluzbaZakazList - pouziva sa na vycervenenie klienta v autocomplete
        this.addField("zapisList.klientSluzba.klientSluzbaZakazList.id");
        this.addField("zapisList.sluzba.kod");
        this.addField("zapisList.psychPoradXUser.name");
        this.addField("zapisList.specPoradXUser.name");

        // spracovanie custom validacie pre oneToMany asociaciu zapisList
        this.addAssocToValidate("zapisList");
        this.addAssocToSort("zapisList", "id");

        this.onChangeDatum = this.onChangeDatum.bind(this);
        this.removeVykazXUser = this.removeVykazXUser.bind(this);
    }

    async componentDidMount() {
        await super.componentDidMount();

        // nacitame si zoznam userov pre stretwork (tych budeme ponukat v autocomplete v casti Pracovnici)
        this.userStreetworkList = await XUtils.fetchMany('find-users', {sluzbaKod: SluzbaEnum.streetwork} satisfies FindUsersRequest);

        // metoda vytvorAtributyHistoria() potrebuje mat k dispozicii vytvorene vsetky komponenty typu XFieldSetBase
        // a tie su vytvorene az ked sa zavola callback v setState (mozno sa da zavolat aj niekedy skor ale takto to funguje, napr. v preInitForm to nefunguje)
        // (tiez prava musia byt nacitane)
        this.setStateXForm(() => this.vytvorAtributyHistoria()); // aby sa prejavili zmeny ktore zavisia od nacitanych zoznamov
    }

    async vytvorAtributyHistoria() {
        const vykazStreetwork: VykazStreetwork | null = this.getVykazStreetwork();
        if (!vykazStreetwork) {
            throw "Unexpected error - vykazStreetwork is null";
        }
        for (const zapis of vykazStreetwork.zapisList) {
            await ZapisPanel.vytvorAtributyHistoria(zapis);
        }

        this.setStateXForm(); // aby sa prejavili zmeny ktore zavisia od nacitanych zoznamov
    }

    // pomocna metodka
    private getVykazStreetwork(): VykazStreetwork | null {
        return this.state.object;
    }

    // pomocna metodka
    // private getZapisList(): Zapis[] {
    //     const vykazStreetwork: VykazStreetwork = this.getXObject() as VykazStreetwork;
    //     return vykazStreetwork.zapisList;
    // }

    async onChangeDatum(e: XFieldChangeEvent<VykazStreetwork>) {
        // zapiseme datum do zapisov - datum v zapisoch pouziva ZapisPanel.vytvorAtributyHistoria (pripadne ina funkcionalita ho moze pouzivat)
        for (const zapis of e.object.zapisList) {
            zapis.datum = e.object.datum;
        }

        // zmenil sa datum, prepocitame historiu atributov
        for (const zapis of e.object.zapisList) {
            await ZapisPanel.vytvorAtributyHistoria(zapis);
        }
        // kedze sme pouzili await, tak musime zavolat setState (framework necaka a vola setState hned)
        this.setStateXForm();
    }

    removeVykazXUser(vykazXUser: VykazXUser) {
        // skontrolujeme, ci mozme vymazat
        if (vykazXUser.xUser) {
            const vykazStreetwork: VykazStreetwork | null = this.getVykazStreetwork();
            if (vykazStreetwork) {
                for (const zapis of vykazStreetwork.zapisList) {
                    if (zapis.specPoradXUser && zapis.specPoradXUser.id === vykazXUser.xUser.id) {
                        alert(`Nie je dovolené vymazať pracovníka ${vykazXUser.xUser.name}, pracovník je zapísaný v špecializovanom poradenstve na zázname pre klienta ${zapis.klientSluzba?.klient?.menoPriezviskoPrezyvka}`);
                        return;
                    }
                    if (zapis.psychPoradXUser && zapis.psychPoradXUser.id === vykazXUser.xUser.id) {
                        alert(`Nie je dovolené vymazať pracovníka ${vykazXUser.xUser.name}, pracovník je zapísaný v psychologickom poradenstve na zázname pre klienta ${zapis.klientSluzba?.klient?.menoPriezviskoPrezyvka}`);
                        return;
                    }
                }
            }
        }
        // vymazeme
        this.onTableRemoveRow("vykazXUserList", vykazXUser);
    }

    addZapis() {
        const zapis: Zapis = {
            id: undefined!, // vygeneruje this.onTableAddRow
            sluzba: Utils.getCurrentSluzba()!,
            vykazStreetwork: undefined!, // v tomto formulari sa tato asociacia nepouziva
            klientSluzba: null,
            datum: null!, // pri save doplnime z VykazStreetwork
            vseobecnyZapis: null,
            atributy: {},
            psychPoradCas: null,
            psychPoradXUser: null,
            specPoradCas: null,
            specPoradXUser: null,
            specPoradZapis: null,
            modifDate: null,
            modifXUser: null,
            version: 0 // nepouziva sa
        }
        this.onTableAddRow("zapisList", zapis, "id");
    }

    removeZapis(zapis: Zapis) {
        if (window.confirm(`Chcete naozaj vymazať celý zápis${zapis.klientSluzba ? ' pre klienta ' + zapis.klientSluzba.klient.menoPriezviskoPrezyvka : ''}?`)) {
            this.onTableRemoveRow("zapisList", zapis);
        }
    }

    // overrides method in XFormBase
    async validate(vykazStreetwork: VykazStreetwork): Promise<XErrors> {
        const errors: XErrors = {};

        for (const zapis of vykazStreetwork.zapisList) {
            const errorsZapis: XErrors = ZapisPanel.validateZapis(vykazStreetwork, zapis);
            //zapiseme chyby do zaznamu zapis do specialneho technickeho atributu
            XFormBase.saveErrorsIntoXRowTechData(zapis, errorsZapis);
        }

        return errors;
    }

    preSave(object: VykazStreetwork) {
        super.preSave(object);

        // upravy zapisov
        for (const zapis of object.zapisList) {
            zapis.datum = object.datum; // datum kopirujeme z vykazu
            zapis.modifDate = object.modifDate; // ak by raz niekto pozeral/upravoval po jednotlivych zaznamoch, nech vidi nejaku hodnotu
            zapis.modifXUser = object.modifXUser; // ak by raz niekto pozeral/upravoval po jednotlivych zaznamoch, nech vidi nejaku hodnotu
            zapis.atributyHistoria = undefined; // info o historii atributov nechceme posielat na backend
            zapis.xFieldSetBaseRef = undefined; // referenciu xFieldSetBaseRef nechceme posielat na backend
        }
    }

    createPanelZapis(vykazStreetwork: VykazStreetwork, zapis: Zapis): React.ReactNode {

        return <ZapisPanel key={zapis.id} vykazStreetwork={vykazStreetwork}
                   zapis={zapis} onChangeZapis={this.onChangeForm} removeZapis={() => this.removeZapis(zapis)}
                   userSpecPoradList={this.userSpecPoradList}
                   userPsychPoradList={this.userPsychPoradList}
                   idSluzbaRolaPravaMap={this.state.idSluzbaRolaPravaMap}
                   kodSluzbaRolaPravaMap={this.state.kodSluzbaRolaPravaMap}
                   onEditFieldChange={this.onEditFieldChange}/>;
    }

    createZapisy(): React.ReactNode {
        const vykazStreetwork: VykazStreetwork | null = this.getVykazStreetwork();
        if (vykazStreetwork === null) { // este nenacital objekt v componentDidMount()
            return undefined;
        }

        const zapisList: Zapis[] = vykazStreetwork.zapisList;
        const elemZapisList: React.ReactNode[] = [];
        for (const zapis of zapisList) {
            elemZapisList.push(this.createPanelZapis(vykazStreetwork, zapis));
        }
        return (
            <div>
                <div className="flex justify-content-center">
                    <div style={{fontSize: '120%'}}>Záznamy</div>
                </div>
                {elemZapisList}
                <div className="flex justify-content-center">
                    <XButtonIconMedium icon="pi pi-plus" onClick={() => this.addZapis()}/>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div>
                <XFormHeader form={this} label="Výkaz - streetwork"/>
                <XFormScrollable form={this} headerFooterHeight="11.5rem" widthFitContent={true}>
                    <div className="x-form-row">
                        <div className="x-form-col">
                            <div className="x-form-row">
                                <div className="x-form-col">
                                    <XInputDecimal form={this} field="id" label="ID" readOnly={true}/>
                                    <XInputDate form={this} field="modifDate" label="Dátum modif." readOnly={true}/>
                                    <XInputText form={this} field="modifXUser.name" label="Modifikoval" size={20}/>
                                    <XInputDate form={this} field="datum" label="Dátum" onChange={this.onChangeDatum}/>
                                    <XAutoComplete form={this} assocField="palivoVIgnise" displayField="name" label="Palivo v Ignise"
                                                   filter={Utils.enumFilter(EnumEnum.palivoVIgnise)} sortField="enumOrder" width="8.35rem"/>
                                    <XFormDataTable2 form={this} assocField="vykazXUserList" label="Pracovníci"
                                                     onClickRemoveRow={this.removeVykazXUser}>
                                        <XFormColumn field="id" header="ID" readOnly={true} width="3rem"/>
                                        <XFormAutoCompleteColumn assocField="xUser" displayField="name" header="Pracovník" width="15rem"
                                                                 suggestions={this.userStreetworkList}/>
                                    </XFormDataTable2>
                                </div>
                                <div className="x-form-col">
                                    <XFormDataTable2 form={this} assocField="vykazMestskaCastList" label="Mestské časti - kilometre, hodiny">
                                        <XFormColumn field="id" header="ID" readOnly={true} width="3rem"/>
                                        <XFormAutoCompleteColumn assocField="mestskaCast" displayField="name" header="Mestská časť" width="12rem" filter={Utils.enumFilter(EnumEnum.mestskaCast)}/>
                                        <XFormColumn field="kilometre" header="Kilometre" width="6rem"/>
                                        <XFormColumn field="hodinyCelkovo" header="Hodiny celkovo" width="8rem"/>
                                        <XFormColumn field="hodinyVTerene" header="Hodiny v teréne" width="8rem"/>
                                    </XFormDataTable2>
                                    <XFormDataTable2 form={this} assocField="vykazMiestoMoznehoKontaktuList" label="Miesta možného kontaktu">
                                        <XFormColumn field="id" header="ID" readOnly={true} width="3rem"/>
                                        <XFormColumn field="miestoMoznehoKontaktu.mestskaCast.name" header="Mestská časť" readOnly={true} width="10rem"/>
                                        <XFormAutoCompleteColumn assocField="miestoMoznehoKontaktu" displayField="miesto" header="Miesto možného kontaktu" width="24rem"
                                                                 filter={Utils.filterCurrentSluzba()}
                                                                 searchBrowse={<MiestoMoznehoKontaktuBrowse/>} assocForm={<MiestoMoznehoKontaktuForm/>}/>
                                    </XFormDataTable2>
                                </div>
                            </div>{/* x-form-row pre stlpce */}
                            <XEditor form={this} field="priebehSluzby" label="Priebeh služby" labelOnTop={true}/>
                            {this.createZapisy()}
                        </div>
                    </div>
                </XFormScrollable>
                <XFormFooter form={this}/>
            </div>
        );
    }
}
