import {
    XBrowseProps,
    XLazyColumn,
    XLazyDataTable,
    XSearchBrowseProps
} from "@michalrakus/x-react-web-lib/XLazyDataTable";
import React from "react";
import {SluzbaRolaPravaForm} from "./SluzbaRolaPravaForm";
import {XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {xLocaleOption} from "@michalrakus/x-react-web-lib/XLocale";

export const SluzbaRolaPravaBrowse = (props: XBrowseProps & XSearchBrowseProps) => {

    const onAddRow = () => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        props.openForm!(<SluzbaRolaPravaForm/>);
    }

    const onEdit = (selectedRow: any) => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        props.openForm!(<SluzbaRolaPravaForm id={selectedRow.id}/>);
    }

    const onRemoveRow = async (selectedRow: any): Promise<boolean> =>  {

        if (window.confirm(xLocaleOption('removeRowConfirm'))) {
            await XUtils.post('remove-sluzba-rola-prava', {id: selectedRow.id});
            return true;
        }
        return false;
    }

    return (
        <XLazyDataTable entity="SluzbaRolaPrava" label="Práva" sortField="id" rows={30}
                        onAddRow={onAddRow} onEdit={onEdit} removeRow={onRemoveRow}
                        displayed={props.displayed} searchBrowseParams={props.searchBrowseParams}>
            <XLazyColumn field="id" header="ID" width="6rem"/>
            <XLazyColumn field="kod" header="Kód" width="10rem"/>
            <XLazyColumn field="nazov" header="Názov" width="30rem"/>
            <XLazyColumn field="typPrav.name" header="Typ práv" width="10rem"/>
        </XLazyDataTable>
    );
}
