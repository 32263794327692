import React from "react";
import {XInputDT, XInputDTProps} from "@michalrakus/x-react-web-lib/lib/components/XInputDT";
import {XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {Tooltip} from "primereact/tooltip";
import {XInputTextareaBase} from "@michalrakus/x-react-web-lib/XInputTextareaBase";
import {dateFromModel} from "@michalrakus/x-react-web-lib/XUtilsConversions";
import {XCalendar} from "@michalrakus/x-react-web-lib/XCalendar";

// XInputDateRow vznikol kombinaciou XInputDateDT a XInputDate
// je to vlastne XInputDateDT aj s "label" funkcionalitou

export interface XInputDateRowProps extends XInputDTProps {
}

export class XInputDateRow extends XInputDT<XInputDateRowProps> {

    constructor(props: XInputDateRowProps) {
        super(props);

        this.onValueChange = this.onValueChange.bind(this);
    }

    getValue(): Date | null {
        return dateFromModel(this.getValueFromRowData());
    }

    onValueChange(value: Date | null) {
        this.onValueChangeBase(value, this.props.onChange);
    }

    render() {
        // podporujeme len desc ako label tooltip, lebo samotny input je velmi maly
        let labelTooltip: string | undefined = undefined;
        let labelElemId: string | undefined = undefined;
        if (this.props.desc !== null) {
            // nevidno placeholder, tak zobrazime desc ako label tooltip
            labelTooltip = this.props.desc;
            labelElemId = `${this.props.field}_label_id`; // TODO - tento labelElemId moze byt problem ak sa nahodou trafi do ineho id-cka vo formulari
        }

        return (
            <div className="field grid">
                <label id={labelElemId} htmlFor={this.props.field} className="col-fixed" style={this.getLabelStyle()}>{this.getLabel()}</label>
                {labelTooltip ? <Tooltip target={`#${labelElemId}`} content={labelTooltip}/> : null}
                <XCalendar id={this.props.field} value={this.getValue()} onChange={this.onValueChange} readOnly={this.isReadOnly()} error={this.getError()}
                           scale={this.xField.scale} datetime={this.xField.type === 'datetime'}/>
            </div>
        );
    }
}
