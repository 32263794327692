import {
    XBrowseProps,
    XLazyColumn,
    XLazyDataTable
} from "@michalrakus/x-react-web-lib/XLazyDataTable";
import React from "react";
import {Utils} from "../../Utils";
import {CenovaKategoriaForm} from "./CenovaKategoriaForm";

export const CenovaKategoriaBrowse = (props: XBrowseProps) => {

    const onAddRow = () => {

        if (!Utils.getCurrentSluzba()) {
            alert("Vyberte najprv službu.");
            return;
        }

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        props.openForm!(<CenovaKategoriaForm/>);
    }

    const onEdit = (selectedRow: any) => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        props.openForm!(<CenovaKategoriaForm id={selectedRow.id}/>);
    }

    return (
        <XLazyDataTable entity="CenovaKategoria" label="Cenové kategórie" sortField="nazov" rows={30} customFilter={{where: "[sluzba] = :sluzbaId", params: {"sluzbaId": Utils.getCurrentSluzbaId() ?? 0}}}
                        onAddRow={onAddRow} onEdit={onEdit} removeRow={true} displayed={props.displayed}>
            <XLazyColumn field="id" header="ID"/>
            <XLazyColumn field="nazov" header="Názov" width="20rem"/>
            <XLazyColumn field="cenovaKategoriaCenaList.datumOd" header="Dátum od"/>
            <XLazyColumn field="cenovaKategoriaCenaList.datumDo" header="Dátum do"/>
            <XLazyColumn field="cenovaKategoriaCenaList.cena" header="Cena" width="6rem"/>
        </XLazyDataTable>
    );
}
