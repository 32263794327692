import React from "react";
import {Form} from "../../XLibItems";
import {XInputDecimal} from "@michalrakus/x-react-web-lib/XInputDecimal";
import {XInputText} from "@michalrakus/x-react-web-lib/XInputText";
import {XFormFooter} from "@michalrakus/x-react-web-lib/XFormFooter";
import {XInputDate} from "@michalrakus/x-react-web-lib/XInputDate";
import {XFormBaseModif} from "@michalrakus/x-react-web-lib/XFormBaseModif";
import {Utils} from "../../Utils";
import {XObject} from "@michalrakus/x-react-web-lib/XObject";
import {XFormColumn, XFormDataTable2} from "@michalrakus/x-react-web-lib/XFormDataTable2";
import {XInputTextarea} from "@michalrakus/x-react-web-lib/XInputTextarea";
import {XFormHeader} from "@michalrakus/x-react-web-lib/XFormHeader";

@Form("CenovaKategoria")
export class CenovaKategoriaForm extends XFormBaseModif {

    createNewObject(): XObject {
        return {sluzba: Utils.getCurrentSluzba(), cenovaKategoriaCenaList: [], version: 0};
    }

    render() {
        return (
            <div>
                <XFormHeader form={this} label="Cenová kategória"/>
                <div className="x-form-row">
                    <div className="x-form-col">
                        <XInputDecimal form={this} field="id" label="ID" readOnly={true}/>
                        <XInputDate form={this} field="modifDate" label="Dátum modif." readOnly={true}/>
                        <XInputText form={this} field="modifXUser.name" label="Modifikoval" size={20}/>
                        <XInputText form={this} field="sluzba.nazov" label="Služba" size={20}/>
                        <XInputText form={this} field="nazov" label="Názov" size={20}/>
                        <XInputTextarea form={this} field="poznamka" label="Poznámka" cols="full" autoResize={true}/>
                        <XFormDataTable2 form={this} assocField="cenovaKategoriaCenaList" label="Ceny">
                            <XFormColumn field="id" header="ID" readOnly={true} width="4rem"/>
                            <XFormColumn field="datumOd" header="Dátum od"/>
                            <XFormColumn field="datumDo" header="Dátum do"/>
                            <XFormColumn field="cena" header="Cena" width="6rem"/>
                        </XFormDataTable2>
                    </div>
                </div>
                <XFormFooter form={this}/>
            </div>
        );
    }
}
