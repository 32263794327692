import React from "react";
import {
    XFieldMeta,
    XFieldPropEditProps, XFieldSetBase
} from "./XFieldSetBase";
import {Checkbox} from "primereact/checkbox";

// najjednoduchsi edit panel, s jednym checkboxom
// default hodnota pre parameter fieldPropEdit
// props.value je true | undefined (zaskrtnute | nezaskrtnute)
export const XSimpleFieldPropEdit = (props: XFieldPropEditProps & {label?: string;}) => {

    return (
        <div className="field grid">
            {props.label ? <label className="col-fixed" style={{width:'25rem'}}>{props.label}</label> : undefined}
            <Checkbox checked={props.value ?? false} onChange={(e: any) => props.onChange!(e.checked ? true : undefined)}/>
        </div>
    );
}

// namiesto statickej funkcie
XSimpleFieldPropEdit.getFieldPropValue = (xFieldMeta: XFieldMeta, fieldPropId: string): boolean => {
    const fieldPropValues: true | undefined = XFieldSetBase.getFieldPropValues(xFieldMeta, fieldPropId);
    return fieldPropValues ?? false;
}
