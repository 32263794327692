import {
    XBrowseProps,
    XLazyColumn,
    XLazyDataTable
} from "@michalrakus/x-react-web-lib/XLazyDataTable";
import React from "react";
import {Utils} from "../../Utils";
import {ZmluvaForm} from "./ZmluvaForm";
import {Zmluva} from "../../model/klient/zmluva.entity";
import {XUtilsCommon} from "@michalrakus/x-react-web-lib/XUtilsCommon";

export const ZmluvaBrowse = (props: XBrowseProps) => {

    const onAddRow = () => {

        if (!Utils.getCurrentSluzba()) {
            alert("Vyberte najprv službu.");
            return;
        }

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        props.openForm!(<ZmluvaForm/>);
    }

    const onEdit = (selectedRow: any) => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        props.openForm!(<ZmluvaForm id={selectedRow.id}/>);
    }

    const onPredlzitZmluvu = async (selectedRow: Zmluva) => {
        if (selectedRow.predlzovanaZmluva !== null) {
            alert("Predlžovať je možné len zmluvu, nie predĺženie.");
            return;
        }
        // specialny insert, kde predplnime predlzovanu zmluvu
        // cez operator ...selectedRow skopirujeme vsetky atributy a nasledne niektore z nich prebijeme
        props.openForm!(<ZmluvaForm initValues={{...selectedRow, id: undefined, modifDate: null, cisloZmluvy: null, predlzovanaZmluva: selectedRow, datumOd: XUtilsCommon.dateAddDays(selectedRow.datumDo, 1), datumDo: null, datumPodpisu: null, zmluvaXFile: null, version: 0}}/>);
    }

    return (
        <XLazyDataTable entity="Zmluva" label="Zmluvy" sortField="id desc" rows={30} customFilter={{where: "[klientSluzba.sluzba] = :sluzbaId", params: {"sluzbaId": Utils.getCurrentSluzbaId() ?? 0}}}
                        onAddRow={onAddRow} onEdit={onEdit} removeRow={true}
                        appButtonsForRow={[{key: "button-predlzit-zmluvu", label: "Predĺžiť zmluvu", onClick: onPredlzitZmluvu}]}
                        displayed={props.displayed}>
            <XLazyColumn field="id" header="ID" width="5rem"/>
            <XLazyColumn field="cisloZmluvy" header="Číslo zmluvy" width="10rem"/>
            <XLazyColumn field="predlzovanaZmluva.cisloZmluvy" header="Predlžovaná zmluva" width="12rem"/>
            <XLazyColumn field="klientSluzba.klient.menoPriezviskoPrezyvka" header="Klient" width="25rem"/>
            <XLazyColumn field="datumOd" header="Dátum od"/>
            <XLazyColumn field="datumDo" header="Dátum do"/>
            <XLazyColumn field="zmluvaXFile.name" header="Zmluva" width="13rem"/>
        </XLazyDataTable>
    );
}
