import React, {useState} from "react";
import {Dialog} from "primereact/dialog";
import {XButton} from "@michalrakus/x-react-web-lib/XButton";
import {XFieldSetBase, XFieldSetValues} from "../zapisy/XFieldSetBase";
import {xLocaleOption} from "@michalrakus/x-react-web-lib/XLocale";

// dialog na editaciu XFieldSet-u - pouzivany pri statistikach na vytvorenie zoznamu fieldov o ktorych chceme zbierat statistiky
// mozno by bolo fajn presunut ho k XFieldSetBase.tsx

export interface XFieldSetDialogState {
    opened: boolean;
    fieldSetId?: string; // tu by sme mohli posielat priamo XFieldSetMeta.xFieldMetaRoot, ak mame a chceme usetrit select do DB
    fieldSetValues?: XFieldSetValues; // json {<field1>: <value1>, ..., <fieldN>: <valueN>}, zvycajne z nejakeho JSONB atributu v DB
    // onHideDialog by mohol byt aj v XFieldSetDialog.props (ak neplanujeme volat ten isty dialog z dvoch handlerov),
    // dame ho sem koli prehladnosti
    onHideDialog?: (ok: boolean, fieldSetValues: XFieldSetValues | undefined) => void; // ak ok = true, tak fieldSetValues je upraveny json (vysledok editacie)
}

export const XFieldSetDialog = (props: {
    dialogState: XFieldSetDialogState;
}) => {

    const [fieldSetValues, setFieldSetValues] = useState<XFieldSetValues>({});

    // bez tejto metody by pri opetovnom otvoreni dialogu ponechal povodne hodnoty
    const onShow = () => {
        setFieldSetValues(props.dialogState.fieldSetValues!);
    }

    const onHide = () => {
        if (props.dialogState.onHideDialog) {
            props.dialogState.onHideDialog(false, undefined);
        }
    }

    const onFieldChange = (field: string, value: any) => {

        fieldSetValues[field] = value;

        // state was changed
        setFieldSetValues({...fieldSetValues});
    }

    // poznamka: renderovanie vnutornych komponentov Dialogu sa zavola az po otvoreni dialogu
    return (
        <Dialog visible={props.dialogState.opened} onShow={onShow} onHide={onHide}>
            <div className="flex justify-content-center">
                <XFieldSetBase values={fieldSetValues} onFieldChange={onFieldChange}
                               fieldSetId={props.dialogState.fieldSetId!} canEdit={false}/>
            </div>
            <div className="flex justify-content-center">
                <XButton label={xLocaleOption('ok')} onClick={() => props.dialogState.onHideDialog!(true, fieldSetValues)}/>
                <XButton label={xLocaleOption('cancel')} onClick={() => props.dialogState.onHideDialog!(false, undefined)}/>
            </div>
        </Dialog>
    );
}
