import React, {useEffect, useState} from "react";
import {OperationType, XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {XFieldMeta, XFieldPropEditProps, XFieldPropValues, XFieldSetBase,} from "./XFieldSetBase";
import {SluzbaRolaPrava} from "../../model/user/sluzba-rola-prava.entity";
import {XAutoCompleteBase} from "@michalrakus/x-react-web-lib/XAutoCompleteBase";
import {SluzbaRolaPravaBrowse} from "../user/SluzbaRolaPravaBrowse";
import {SluzbaRolaPravaForm} from "../user/SluzbaRolaPravaForm";

// props.value je typu number | undefined (id-cko zaznamu entity SluzbaRolaPrava)
export const PravaFieldPropEdit = (props: XFieldPropEditProps) => {

    // premenne platne pre cely component (obdoba member premennych v class-e)
    //let sluzbaRolaCheckboxMap: SluzbaRolaCheckboxMap = (props.value ?? {}) as SluzbaRolaCheckboxMap;

    const [sluzbaRolaPravaList, setSluzbaRolaPravaList] = useState<SluzbaRolaPrava[]>([]);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        // TODO - ked budu ciselniky cachovane, pouzivat cache
        let sluzbaRolaPravaList: SluzbaRolaPrava[] = await XUtils.fetchRows(
            'SluzbaRolaPrava',
            undefined,
            'id',
            ['typPrav.code']);
        setSluzbaRolaPravaList(sluzbaRolaPravaList);
    }

    const onChange = async (object: SluzbaRolaPrava, objectChange: OperationType) => {
        if (objectChange !== OperationType.None) {
            await loadData(); // ak user napr. vytvoril novy objekt do DB, refreshneme data
        }
        props.onChange!(object ? object.id : undefined); // iba id-cko zapiseme do struktury popisujucej formular XFieldSet
    }

    // do onChange posielame len id-cko (typ number)
    // v props.value preto tiez prichadza id-cko (typ number) a musime ho pretransformovat na objekt typu SluzbaRolaPrava (minimalne musi objekt mat property id a nazov)
    let sluzbaRolaPrava: SluzbaRolaPrava | null = null;
    if (props.value && sluzbaRolaPravaList.length > 0) {
        // zaznam s id=props.value by mal byt v zozname sluzbaRolaPravaList
        const rowListFiltered: SluzbaRolaPrava[] = sluzbaRolaPravaList.filter((prava: SluzbaRolaPrava) => prava.id === props.value);
        if (rowListFiltered.length === 1) {
            sluzbaRolaPrava = rowListFiltered[0];
        }
        else {
            throw `Neocakavana chyba - nenasiel sa zaznam SluzbaRolaPrava s id = ${props.value}`;
        }
    }

    return (
        <XAutoCompleteBase value={sluzbaRolaPrava} suggestions={sluzbaRolaPravaList} field="nazov"
                           onChange={onChange} onErrorChange={props.onErrorChange!}
                           searchBrowse={<SluzbaRolaPravaBrowse/>} valueForm={<SluzbaRolaPravaForm/>} idField="id"/>
    );
}

// namiesto statickej funkcie
PravaFieldPropEdit.getFieldPropValue = (xFieldMeta: XFieldMeta, fieldPropId: string, idSluzbaRolaPravaMap: Map<number, SluzbaRolaPrava>): SluzbaRolaPrava | undefined => {
    let value: SluzbaRolaPrava | undefined = undefined; // default
    const fieldPropValues: XFieldPropValues | undefined = XFieldSetBase.getFieldPropValues(xFieldMeta, fieldPropId);
    if (fieldPropValues) {
        if (typeof fieldPropValues === 'object') {
            // nasli sme nacachovanu hodnotu
            value = fieldPropValues;
        }
        else {
            // malo by tu byt id-cko
            if (!(typeof fieldPropValues === 'number')) {
                throw `Neocakavana chyba: xFieldMeta field = ${xFieldMeta.field}, fieldPropValues pre prava neobsahuje id-cko (typ number)`;
            }
            value = idSluzbaRolaPravaMap.get(fieldPropValues);
            if (!value) {
                throw `Neocakavana chyba: xFieldMeta field = ${xFieldMeta.field}, nenasiel sa zaznam SluzbaRolaPrava pre id = ${fieldPropValues}`;
            }
            // zapiseme value namiesto id-cka (nabuduce najdeme nachachovanu hodnotu)
            // ci cachovanie usetri vela casu, netusim
            XFieldSetBase.setFieldPropValues(xFieldMeta, fieldPropId, value);
        }
    }
    return value;
}
