import React, {useRef, useState} from "react";
import {OperationType, XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {Utils} from "../../Utils";
import {XAutoCompleteBase} from "@michalrakus/x-react-web-lib/XAutoCompleteBase";
import {Klient} from "../../model/klient/klient.entity";
import {HladatKlientaMimoSluzbuDialog, HladatKlientaMimoSluzbuDialogState} from "./HladatKlientaMimoSluzbuDialog";
import {Dialog} from "primereact/dialog";
import {KlientSluzbaForm} from "./KlientSluzbaForm";
import {KlientSluzba} from "../../model/klient/klient-sluzba.entity";
import {KlientSluzbaBrowse} from "./KlientSluzbaBrowse";
import {XUtilsCommon} from "@michalrakus/x-react-web-lib/XUtilsCommon";

// UPOZORNENIE:
// property "value" typu KlientSluzba musi v sebe obsahovat vsetky zaznamy ktorych atributy sa tu pouzivaju,
// t.j. aj zaznamy Klient a XEnum pre pohlavie a zaznamy obec a statCiselnik
// t.j. vo formulari kde sa KlientSluzbaAutoComplete pouziva treba najoinovat toto:
//     this.addField("klientSluzba.klient.pohlavie.name");
//     this.addField("klientSluzba.klient.obec.nazov");
//     this.addField("klientSluzba.klient.statCiselnik.nazov");

interface UpravitKlientaMimoSluzbuDialogState {
    opened: boolean;
    initValues?: object;
}

export const KlientSluzbaAutoComplete = (props: {
    value: KlientSluzba | null;
    onChange: (klient: KlientSluzba | null, objectChange: OperationType) => void;
    error: string | undefined;
    onErrorChange: (error: string | undefined) => void;
    readOnly?: boolean;
    maxWidth?: string;
}) => {

    const [hladatKlientaDialogState, setHladatKlientaDialogState] = useState<HladatKlientaMimoSluzbuDialogState>({opened: false});
    const [upravitKlientaDialogState, setUpravitKlientaDialogState] = useState<UpravitKlientaMimoSluzbuDialogState>({opened: false});

    const xAutoCompleteBaseRef = useRef<XAutoCompleteBase>(null);

    const today: Date = XUtilsCommon.today();

    const onAddRow = (inputValue: string) => {
        setHladatKlientaDialogState({
            opened: true,
            onHideDialog: onHideDialog
        });
    }

    const onHideDialog = async (ok: boolean, klient?: Klient | null, objectChange?: OperationType) => {
        // TODO - objectChange nie je spraveny, zatial nepouzivat
        setHladatKlientaDialogState({opened: false});
        if (ok) {
            if (klient !== null) {
                // TODO - zatial vezmeme priamo cely zaznam Klient a pouzijeme ho vo formulari KlientSluzbaForm pre update
                // -> korektnejsie by bolo teraz rereadnut zaznam z DB (pouzit klient!.id)
                // potrebujeme najoinovat vsetko co sa pouziva vo formulari KlientSluzbaForm v casti klient (cast KlientSluzba bude insertovana) (v autocomplete zobrazujeme aj obec aj okres)
                const klientFromDB: Klient = await XUtils.fetchById("Klient", ["pohlavie.name", "obec.okres.nazov", "statCiselnik.nazov", "klientSluzbaList.sluzba.nazov"], klient!.id);

                // otvorime formular KlientSluzbaForm v rezime na insert (id zaznamu KlientSluzba je undefined) a predplnime niektore atributy
                setUpravitKlientaDialogState({opened: true, initValues: {klient: klientFromDB, sluzba: Utils.getCurrentSluzba(), version: 0}});
            }
            else {
                // otvorime formular KlientSluzbaForm v rezime na insert, pricom aj klient bude nanovo vytvoreny
                // inicializacia prebehne cez KlientSluzbaForm.createNewObject()
                setUpravitKlientaDialogState({opened: true, initValues: undefined});
            }
        }
    }

    const onSaveOrCancel = (object: any | null, objectChange: OperationType) => {
        setUpravitKlientaDialogState({opened: false});
        // object je typu KlientSluzba
        props.onChange(object, objectChange);
        props.onErrorChange(undefined);
        // vycistime aj autocomplete (ak bol save), user tam moze mat nieco zapisane
        xAutoCompleteBaseRef.current!.formDialogOnSaveOrCancel(object, objectChange);
    }

    const itemTemplate = (suggestion: any, index: number, createStringValue: boolean, defaultValue: (suggestion: any) => string): React.ReactNode => {
        if (createStringValue) {
            // hodnota do inputu - iba string
            return defaultValue(suggestion);
        }
        else {
            // hodnota do dropdown panelu - mozme dat element
            return <div className={Utils.klientSluzbaMaZakaz(suggestion, today) ? "klient-sluzba-zakaz" : undefined}>{defaultValue(suggestion)}</div>;
        }
    }

    const inputClassName = (): string | undefined => {
        const klientSluzba: KlientSluzba | null = props.value;
        if (klientSluzba && Utils.klientSluzbaMaZakaz(klientSluzba, today)) {
            return "klient-sluzba-zakaz";
        }
        else {
            return undefined;
        }
    }

    return (
        <div className="w-full" style={{maxWidth: props.maxWidth}}>
            {/* poznamka: pouzivame aj mesto (text) aj ciselnik obec; max jedno z nich by malo byt vyplnene */}
            <XAutoCompleteBase ref={xAutoCompleteBaseRef} value={props.value} onChange={props.onChange} error={props.error} onErrorChange={props.onErrorChange} idField="id"
                               field={["klient.meno", "klient.priezvisko", "klient.prezyvka", "RČ:klient.rodneCislo", "klient.pohlavie.name", "klient.datumNarodenia", "klient.ulica", "klient.cisloDomu", "klient.psc", "klient.mesto", "klient.obec.nazov", "klient.statCiselnik.nazov", "ID:klient.id"]}
                               itemTemplate={itemTemplate} inputClassName={inputClassName()}
                               suggestionsQuery={{entity: "KlientSluzba", filter: Utils.filterCurrentSluzba(), fields: ["klientSluzbaZakazList.id"]}}
                               searchBrowse={<KlientSluzbaBrowse/>} valueForm={<KlientSluzbaForm/>} onAddRow={onAddRow}
                               width="100%" scrollHeight="50rem" readOnly={props.readOnly}
                               suggestionsLoad="lazy" lazyLoadMaxRows={15} minLength={3}/>
            <HladatKlientaMimoSluzbuDialog dialogState={hladatKlientaDialogState}/>
            <Dialog className="x-dialog-without-header" visible={upravitKlientaDialogState.opened} onHide={() => setUpravitKlientaDialogState({opened: false})}>
                <KlientSluzbaForm initValues={upravitKlientaDialogState.initValues} onSaveOrCancel={onSaveOrCancel}/>
            </Dialog>
        </div>
    );
}
