import React from "react";
import {
    XFieldMeta,
    XFieldPropEditProps, XFieldSetBase
} from "./XFieldSetBase";
import {InputNumber, InputNumberChangeEvent} from "primereact/inputnumber";

// do tejto prop sa da zapisat pocet dni, pre ktore sa ma skontrolovat historia daneho fieldu
// ak sa sem zapise napr. 14, tak aplikacia skontroluje, ci v pribehu 14 dni do minulosti bol dany field pre daneho klienta vykliknuty
// ak bol field vykliknuty, tak sa dany field farebne zvyrazni
// props.value je number | undefined (pocet dni | nepouzity prop)
export const HistoriaFieldPropEdit = (props: XFieldPropEditProps) => {

    return (
        <div className="field grid">
            <label className="col-fixed" style={{width:'25rem'}}>Počet dní</label>
            <InputNumber id="pocet-dni" value={props.value} onChange={(e: InputNumberChangeEvent) => props.onChange!(e.value ?? undefined)}
                         mode="decimal" locale="de-DE" useGrouping={false} minFractionDigits={0} maxFractionDigits={0} min={1} max={3560}/>
        </div>
    );
}

// namiesto statickej funkcie
HistoriaFieldPropEdit.getFieldPropValue = (xFieldMeta: XFieldMeta, fieldPropId: string): number | undefined => {
    const fieldPropValues: number | undefined = XFieldSetBase.getFieldPropValues(xFieldMeta, fieldPropId);
    return fieldPropValues;
}
