import React, {useEffect, useState} from "react";
import {XUtils, XViewStatus} from "@michalrakus/x-react-web-lib/XUtils";
import {Sluzba} from "../../model/user/sluzba.entity";
import {Checkbox} from "primereact/checkbox";
import {SluzbaRola} from "../../model/user/sluzba-rola.entity";
import {SluzbaRolaCheckboxMap, SluzbaSluzbaRolaCheckboxMap} from "../../model/user/sluzba-rola-prava.entity";
import {TypPrav} from "../../common/enums";
import {Dropdown} from "primereact/dropdown";

export const SluzbaRolaPravaCheckboxPanel = (props: {
                                                 typPrav: TypPrav
                                                 value: SluzbaSluzbaRolaCheckboxMap;
                                                 onChange: (value: SluzbaSluzbaRolaCheckboxMap) => void;
                                             }
) => {

    // premenne platne pre cely component (obdoba member premennych v class-e)
    let sluzbaSluzbaRolaCheckboxMap: SluzbaSluzbaRolaCheckboxMap = props.value;

    const [sluzbaList, setSluzbaList] = useState<Sluzba[]>([]);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        // TODO - ked budu ciselniky cachovane, pouzivat cache
        let sluzbaList: Sluzba[] = await XUtils.fetchRows(
            'Sluzba',
            undefined,
            [{field: 'id', order: 1}, {field: 'sluzbaRolaList.rola.enumOrder', order: 1}],
            ['sluzbaRolaList.id']); // chceme nacitat aj entitu SluzbaRola
        setSluzbaList(sluzbaList);
    }

    const onChangeSluzba = (sluzba: Sluzba, checked: boolean) => {
        if (checked) {
            const sluzbaRolaCheckboxMap: SluzbaRolaCheckboxMap = {};
            for (const sluzbaRola of sluzba.sluzbaRolaList) {
                sluzbaRolaCheckboxMap[sluzbaRola.id] = props.typPrav === TypPrav.lenCheckbox ? true : XViewStatus.ReadWrite;
            }
            sluzbaSluzbaRolaCheckboxMap[sluzba.kod] = sluzbaRolaCheckboxMap;
        }
        else {
            delete sluzbaSluzbaRolaCheckboxMap[sluzba.kod];
        }
        // zapiseme vyklikane hodnoty do formularovej struktury
        // - to asi neni potrebne, kedze sme dostali referenciu ale dolezite je zavolat "setState" v parent komponente
        props.onChange(sluzbaSluzbaRolaCheckboxMap);
    }

    const onChangeRolaCheckbox = (sluzba: Sluzba, sluzbaRolaCheckboxMap: SluzbaRolaCheckboxMap, sluzbaRola: SluzbaRola, checked: boolean) => {
        if (checked) {
            sluzbaRolaCheckboxMap[sluzbaRola.id] = true;
        }
        else {
            delete sluzbaRolaCheckboxMap[sluzbaRola.id];
        }
        // ak sme vsetky checkboxy odskrtli, odskrtneme aj checkbox pre sluzbu
        if (Object.keys(sluzbaRolaCheckboxMap).length === 0) {
            delete sluzbaSluzbaRolaCheckboxMap[sluzba.kod];
        }
        // zapiseme vyklikane hodnoty do formularovej struktury - to asi neni potrebne ale dolezite je zavolat "setState" v parent komponente
        // - to asi neni potrebne, kedze sme dostali referenciu ale dolezite je zavolat "setState" v parent komponente
        props.onChange(sluzbaSluzbaRolaCheckboxMap);
    }

    const onChangeRolaDropdown = (sluzba: Sluzba, sluzbaRolaCheckboxMap: SluzbaRolaCheckboxMap, sluzbaRola: SluzbaRola, value: XViewStatus) => {
        if (value === XViewStatus.ReadOnly || value === XViewStatus.ReadWrite) {
            sluzbaRolaCheckboxMap[sluzbaRola.id] = value;
        }
        else {
            delete sluzbaRolaCheckboxMap[sluzbaRola.id]; // hidden nezapisujeme
        }
        // ak sme dali vsetky dropdowny na hidden, odskrtneme aj checkbox pre sluzbu
        if (Object.keys(sluzbaRolaCheckboxMap).length === 0) {
            delete sluzbaSluzbaRolaCheckboxMap[sluzba.kod];
        }
        // zapiseme vyklikane hodnoty do formularovej struktury - to asi neni potrebne ale dolezite je zavolat "setState" v parent komponente
        // - to asi neni potrebne, kedze sme dostali referenciu ale dolezite je zavolat "setState" v parent komponente
        props.onChange(sluzbaSluzbaRolaCheckboxMap);
    }

    const createSluzbaEdit = (sluzba: Sluzba, elemList: JSX.Element[]) => {
        elemList.push(
            <div key={sluzba.id} className="field grid">
                <label className="col-fixed" style={{width:'25rem'}}>{sluzba.nazov}</label>
                <Checkbox checked={sluzbaSluzbaRolaCheckboxMap[sluzba.kod] !== undefined}
                          onChange={(e: any) => onChangeSluzba(sluzba, e.checked)}/>
            </div>
        );
        const sluzbaRolaCheckboxMap: SluzbaRolaCheckboxMap | undefined = sluzbaSluzbaRolaCheckboxMap[sluzba.kod];
        if (sluzbaRolaCheckboxMap) {
            for (const sluzbaRola of sluzba.sluzbaRolaList) {
                elemList.push(
                    <div key={`${sluzba.id}_${sluzbaRola.id}`} className="field grid">
                        <label className="col-fixed" style={{width:'3rem'}}>&nbsp;</label>
                        <label className="col-fixed" style={{width:'22rem'}}>{sluzbaRola.rola.name}</label>
                        {props.typPrav === TypPrav.lenCheckbox ?
                            <Checkbox checked={sluzbaRolaCheckboxMap[sluzbaRola.id] !== undefined}
                                      onChange={(e: any) => onChangeRolaCheckbox(sluzba, sluzbaRolaCheckboxMap, sluzbaRola, e.checked)}/>
                            : <Dropdown
                                options={XUtils.options([XViewStatus.Hidden, XViewStatus.ReadOnly, XViewStatus.ReadWrite])}
                                value={sluzbaRolaCheckboxMap[sluzbaRola.id] ?? XViewStatus.Hidden}
                                onChange={(e: any) => onChangeRolaDropdown(sluzba, sluzbaRolaCheckboxMap, sluzbaRola, e.value)}/>
                        }
                    </div>
                );
            }
        }
    }

    const elemList: JSX.Element[] = [];
    for (const sluzba of sluzbaList) {
        createSluzbaEdit(sluzba, elemList);
    }

    return (
        <div>
            {elemList}
        </div>
    );
}
