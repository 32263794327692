import React, {useState} from "react";
import {Dialog} from "primereact/dialog";
import {XButton} from "@michalrakus/x-react-web-lib/XButton";
import {Dropdown, DropdownChangeEvent} from "primereact/dropdown";
import {xLocaleOption} from "@michalrakus/x-react-web-lib/XLocale";

// pomerne vseobecny dialog, mozme presunut aj do lib-ky

export interface XYearDialogState {
    opened: boolean;
    yearFrom?: number;
    yearTo?: number;
    yearInit?: number; // initialization value
    onHideDialog?: (ok: boolean, rok: number | undefined) => void;
}

export const XYearDialog = (props: {
    dialogState: XYearDialogState;
}) => {

    const [year, setYear] = useState<number | null>(null);
    const [options, setOptions] = useState<{value: number;}[]>([]);

    // bez tejto metody by pri opetovnom otvoreni dialogu ponechal povodne hodnoty
    const onShow = () => {

        const options: {value: number;}[] = [];
        for (let optionYear: number = props.dialogState.yearTo!; optionYear >= props.dialogState.yearFrom!; optionYear--) {
            options.push({value: optionYear});
        }
        setOptions(options);
        setYear(props.dialogState.yearInit ?? null);
    }

    const onHide = () => {
        if (props.dialogState.onHideDialog) {
            props.dialogState.onHideDialog(false, undefined);
        }
    }

    // poznamka: renderovanie vnutornych komponentov Dialogu sa zavola az po otvoreni dialogu
    return (
        <Dialog visible={props.dialogState.opened} onShow={onShow} onHide={onHide}>
            <div className="field grid">
                <label className="col-fixed" style={{width:'5rem'}}>{xLocaleOption('year')}</label>
                <Dropdown options={options} value={year} optionLabel="value" optionValue="value" onChange={(e: DropdownChangeEvent) => setYear(e.value)}/>
            </div>
            <div className="flex justify-content-center">
                <XButton label={xLocaleOption('ok')} onClick={() => props.dialogState.onHideDialog!(true, year!)}/>
            </div>
        </Dialog>
    );
}
