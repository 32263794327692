import React from "react";
import {XInputDT, XInputDTProps} from "@michalrakus/x-react-web-lib/lib/components/XInputDT";
import {XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {Tooltip} from "primereact/tooltip";
import {XInputTextareaBase} from "@michalrakus/x-react-web-lib/XInputTextareaBase";

// XInputTextareaRow vznikol kombinaciou XInputTextareaDT a XInputTextarea
// je to vlastne XInputTextareaDT aj s "label/desc" funkcionalitou
// nepodarilo sa vytvorit vseobecny XFormField, ktory by encapsuloval funkcionalitu "label/desc"
// (nevieme si vytvorit ref na XInputTextareaDT odovzdavany ako prop a nevieme zavolat jeho metody, tak zatial aspon docasne takto)
// XInputTextareaRow by mohol aj uplne nahradit XInputTextareaDT ale zatial by som to zbytocne nesilil

export interface XInputTextareaRowProps extends XInputDTProps {
    rows?: number;
    cols?: number | "full"; // full - maximalna sirka (width:100%)
    labelOnTop?: boolean;
    autoResize?: boolean;
    fieldStyle?: React.CSSProperties; // zatial sem, mozno v buducnosti posunieme do superclass
}

export class XInputTextareaRow extends XInputDT<XInputTextareaRowProps> {

    public static defaultProps = {
        cols: "full",
        labelOnTop: false,
        autoResize: true
    };

    constructor(props: XInputTextareaRowProps) {
        super(props);

        this.onValueChange = this.onValueChange.bind(this);
    }

    getValue(): string | null {
        return this.getValueFromRowData();
    }

    onValueChange(value: string | null) {
        this.onValueChangeBase(value, this.props.onChange);
    }

    render() {

        // toto je copy/paste z XInputTextarea (plati pre javascript kod aj return metodu - kod je identicky az na drobny rozdiel v return metode)
        let fieldStyle: React.CSSProperties | undefined = this.props.fieldStyle;
        const labelStyle: React.CSSProperties = this.getLabelStyle();
        let inputStyle: React.CSSProperties = this.props.inputStyle ?? {};
        let cols: number | undefined;
        if (this.props.cols === "full") {
            cols = undefined;
            // pridame width:100%
            //fieldStyle = {width: '100%'};
            let widthValue: string;
            if (this.props.labelOnTop) {
                widthValue = '100%';
            }
            else {
                // ak nemame labelOnTop=true, musime odratat sirku labelu, inac sa label dostane nad input (koli flex-wrap: wrap)
                const labelStyleWidth: string | number | undefined = labelStyle.width;
                if (labelStyleWidth) {
                    widthValue = `calc(100% - ${labelStyleWidth})`;
                }
                else {
                    widthValue = '100%';
                }
            }
            XUtils.addCssPropIfNotExists(inputStyle, {width: widthValue});
        }
        else {
            // TODO - nastavenie cols nefunguje ak labelOnTop=true - vtedy je input roztiahnuty na 100%
            // roztiahnutie na 100% sposobuje .x-inputtextarea-label-on-top {flex-direction: column;}
            // aj tak sa asi vzdy bude pouzivat "full"
            cols = this.props.cols;
        }

        const value: string | null = this.getValue();
        let labelTooltip: string | undefined = undefined;
        let labelElemId: string | undefined = undefined;
        if (value !== null) {
            // nevidno placeholder, tak zobrazime desc ako label tooltip
            labelTooltip = this.props.desc;
            labelElemId = `${this.props.field}_label_id`; // TODO - tento labelElemId moze byt problem ak sa nahodou trafi do ineho id-cka vo formulari
        }

        // poznamka: InputTextarea treba renderovat az ked mame nacitany object, lebo inac pri autoResize sa nam nenastavi spravna velkost (hodnota nie je k dispozicii pri prvom renderingu)
        // k renderovaniu XInputTextareaRow dochadza az ked mame k dispozicii dany row (a s nim aj jeho data) takze tu nepotrebujeme "o-ifovat" element XInputTextareaBase
        return (
            <div className={!this.props.labelOnTop ? 'field grid' : 'field grid x-inputtextarea-label-on-top'} style={fieldStyle}>
                <label id={labelElemId} htmlFor={this.props.field} className={!this.props.labelOnTop ? 'col-fixed' : undefined} style={labelStyle}>{this.getLabel()}</label>
                {labelTooltip ? <Tooltip target={`#${labelElemId}`} content={labelTooltip}/> : null}
                <XInputTextareaBase id={this.props.field} value={value} onChange={this.onValueChange} readOnly={this.isReadOnly()}
                                    maxLength={this.xField.length} style={inputStyle} rows={this.props.rows} cols={cols}
                                    autoResize={this.props.autoResize} error={this.getError()} tooltip={this.props.tooltip} placeholder={this.props.placeholder ?? this.props.desc}/>
            </div>
        );
    }
}
