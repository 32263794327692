import {XBrowseProps, XLazyColumn, XLazyDataTable} from "@michalrakus/x-react-web-lib/XLazyDataTable";
import React from "react";
import {Utils} from "../../Utils";
import {ZapisForm} from "./ZapisForm";
import {EnumEnum, SluzbaEnum} from "../../common/enums";

export const ZapisBrowse = (props: XBrowseProps) => {

    const onAddRow = () => {

        if (!Utils.getCurrentSluzba()) {
            alert("Vyberte najprv službu.");
            return;
        }

        if (Utils.getCurrentSluzba()?.kod === SluzbaEnum.streetwork) {
            alert("Zápis pre streetwork je dovolené vytvoriť len cez voľbu Zápisy - streetwork.");
            return;
        }

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        props.openForm!(<ZapisForm/>);
    }

    const onEdit = (selectedRow: any) => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        props.openForm!(<ZapisForm id={selectedRow.id}/>);
    }

    return (
        <div>
            <XLazyDataTable entity="Zapis" label="Zápisy" sortField={[{field: "datum", order: -1}, {field: "id", order: -1}]} rows={30}
                            customFilter={{where: "[sluzba] = :sluzbaId", params: {"sluzbaId": Utils.getCurrentSluzbaId() ?? 0}}}
                            onAddRow={onAddRow} onEdit={onEdit} removeRow={true} displayed={props.displayed} multilineSwitch={true}>
                <XLazyColumn field="id" header="ID" width="5rem"/>
                <XLazyColumn field="datum" header="Dátum" betweenFilter="column"/>
                <XLazyColumn field="klientSluzba.klient.menoPriezviskoPrezyvka" header="Klient" width="12rem"/>
                <XLazyColumn field="vseobecnyZapis" header="Všeobecný zápis" width="70rem" contentType="html"/>
                <XLazyColumn field="atributy" fieldSetId="zapis" header="Zaevidované položky" width="20rem"/>
            </XLazyDataTable>
        </div>
    );
}
