import {XLazyColumn, XLazyDataTable} from "@michalrakus/x-react-web-lib/XLazyDataTable";
import React from "react";
import {VykazStreetworkForm} from "./VykazStreetworkForm";
import {XUtilsCommon} from "@michalrakus/x-react-web-lib/XUtilsCommon";

export const VykazStreetworkBrowse = (props: {}) => {

    const onAddRow = () => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        (props as any).openForm(<VykazStreetworkForm initValues={{datum: XUtilsCommon.today(),
            vykazXUserList: [], vykazMestskaCastList: [], vykazMiestoMoznehoKontaktuList: [], zapisList: [],
            version: 0}}/>);
    }

    const onEdit = (selectedRow: any) => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        (props as any).openForm(<VykazStreetworkForm id={selectedRow.id}/>);
    }

    return (
        <XLazyDataTable entity="VykazStreetwork" label="Výkazy - streetwork" sortField={[{field: "datum", order: -1}, {field: "id", order: -1}]} rows={30} multilineSwitch={true}
                        onAddRow={onAddRow} onEdit={onEdit} removeRow={true} displayed={(props as any).displayed}>
            <XLazyColumn field="id" header="ID" width="5rem"/>
            <XLazyColumn field="datum" header="Dátum" betweenFilter="column"/>
            <XLazyColumn field="vykazXUserList.xUser.name" header="Pracovníci" width="15rem"/>
            <XLazyColumn field="priebehSluzby" header="Priebeh služby" width="60rem" contentType="html"/>
        </XLazyDataTable>
    );
}
