import React, {useEffect, useState} from "react";
import {XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {
    XFieldSetBase,
    XFieldSetMeta,
    XFieldXFieldMetaMap
} from "@michalrakus/x-react-web-lib/lib/components/XFieldSet/XFieldSetBase";
import {ZakliknuteFieldyRequest, ZakliknuteFieldyResponse} from "../../common/klient-api";
import {Utils} from "../../Utils";

// komponent zobrazi zakliknute klikacky pre danu field group
export const KlientSluzbaZakliknuteFieldy = (props: {
    klientSluzbaId: number;
    fieldGroup: string;
}) => {

    const [fieldLabelList, setFieldLabelList] = useState<string[]>([]);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        // najprv nacitame label-y fieldov
        const xFieldSetMetaList: XFieldSetMeta[] = await XUtils.fetchRows('XFieldSetMeta', {where: "[fieldSetId] = :fieldSetId", params: {fieldSetId: "zapis"}});
        if (xFieldSetMetaList.length !== 1) {
            throw "Neocakavana chyba - nenasiel sa zaznam x_field_set_meta s field_set_id = 'zapis'";
        }

        const xFieldMap: XFieldXFieldMetaMap = XFieldSetBase.createXFieldXFieldMetaMap(xFieldSetMetaList[0], props.fieldGroup);

        // ziskame zakliknute fieldy
        const zakliknuteFieldyRequest: ZakliknuteFieldyRequest = {klientSluzbaId: props.klientSluzbaId, filterFieldList: [...(xFieldMap.keys())]};
        const zakliknuteFieldyResponse: ZakliknuteFieldyResponse = await XUtils.fetchOne('klient-zakliknute-fieldy', zakliknuteFieldyRequest);
        const fieldLabelListLocal: string[] = [];
        for (const fieldAndCount of zakliknuteFieldyResponse.fieldAndCountList) {
            let fieldLabel: string = xFieldMap.get(fieldAndCount.field)!.label;
            if (fieldAndCount.count > 1) {
                fieldLabel += ` (${fieldAndCount.count})`;
            }
            fieldLabelListLocal.push(fieldLabel);
        }
        setFieldLabelList(fieldLabelListLocal);
    }

    return (
        <div>
            {Utils.createChips(fieldLabelList)}
        </div>
    );
}
