
import React, {useState} from 'react';
// import './App.css'; - tu bol povodne
import {XMenu} from "./XMenu";
import {XUtilsMetadata} from "@michalrakus/x-react-web-lib/XUtilsMetadata";
import {XUtils} from "@michalrakus/x-react-web-lib/XUtils";

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import './App.css'; // bol povodne ako prve css
import {XPostLoginRequest} from "./serverApi/XPostLoginIfc";
import {XUserNotFoundOrDisabledError} from "./Utils";
import {
    IMsalContext,
    useIsAuthenticated,
    useMsal
} from "@azure/msal-react";
import {AuthenticationResult, IPublicClientApplication, SilentRequest, AccountInfo} from "@azure/msal-browser";
import {loginRequest} from "./msalConfig";
import {Button} from "primereact/button";

// window.screen.addEventListener("orientationchange", function () {
//     console.log("The orientation of the screen is: " + window.screen.orientation);
// });

/*
const ratio = window.devicePixelRatio || 1;
const w = window.screen.width * ratio;
const h = window.screen.height * ratio;
console.log('***************** fachci');
console.log(window.devicePixelRatio);
console.log(w);
console.log(h);
*/

//console.log(window.getComputedStyle(document.documentElement).fontSize);


// TODO - v buducnosti presunut do XReactWebLib
function AppMSEntraID() {


    //const {user, isAuthenticated, isLoading, loginWithRedirect, logout, getAccessTokenSilently} = useAuth0();

    const isAuthenticated = useIsAuthenticated();
    const msalContext: IMsalContext = useMsal();
    const msalInstance: IPublicClientApplication = msalContext.instance;
    const accounts: AccountInfo[] = msalContext.accounts;


    const [initialized, setInitialized] = useState(false);

    // useEffect(() => {
    //     fetchAndSetXEntityMap();
    // },[]); // eslint-disable-line react-hooks/exhaustive-deps
/*
    const initializeMsalAndLogin = async () => {

        try {
            console.log("initializeMsalAndLogin: ideme volat msalInstance.initialize();");
            await msalInstance.initialize();

            await msalInstance.handleRedirectPromise();

            // TODO - treba tieto scopes?
            const loginRequest: RedirectRequest = {
                scopes: ['openid', 'profile', 'email'],
            };

            console.log("sme za msalInstance.handleRedirectPromise(); ************************* ");

            // otvori AAD prihlasovaciu stranku
            const accounts = msalInstance.getAllAccounts();
            console.log(accounts);
            if (accounts.length === 0) {
                console.log("sme pred loginRedirect ************************* ");
                await msalInstance.loginRedirect(loginRequest);
                //await msalInstance.loginPopup(loginRequest);
                console.log("sme za loginRedirect *****************************");
            }
        }
        catch (err) {
            console.error('Error in initializeMsalAndLogin: ', err);
            alert(err);
            //throw err;
        }
    }

    const initializeMsalAndLogin2 = async () => {

        try {
            console.log("initializeMsalAndLogin2: ideme volat msalInstance.initialize();");
            await msalInstance.initialize();

            // Account selection logic is app dependent. Adjust as needed for different use cases.
            // Set active acccount on page load
            const accounts = msalInstance.getAllAccounts();
            if (accounts.length > 0) {
                msalInstance.setActiveAccount(accounts[0]);
            }

            msalInstance.addEventCallback((event: any) => {
                // set active account after redirect
                if (event.eventType === "msal:loginSuccess" && event.payload?.account) {
                    const account = event.payload.account;
                    msalInstance.setActiveAccount(account);
                }
            });

            console.log('get active account', msalInstance.getActiveAccount());

            // handle auth redired/do all initial setup for msal
            msalInstance.handleRedirectPromise().then(authResult=>{
                // Check if user signed in
                const account = msalInstance.getActiveAccount();
                if(!account){
                    // redirect anonymous user to login page
                    console.log("initializeMsalAndLogin2: ideme volat msalInstance.loginRedirect();");
                    msalInstance.loginRedirect();
                }
            }).catch(err=>{
                // TODO: Handle errors
                console.log(err);
            });
        }
        catch (err) {
            console.error('Error in initializeMsalAndLogin2: ', err);
            alert(err);
            //throw err;
        }
    }

    const initializeMsalAndLogin3 = async () => {

        try {
            console.log("initializeMsalAndLogin: ideme volat msalInstance.initialize();");
            await msalInstance.initialize();

            await msalInstance.handleRedirectPromise();

            // TODO - treba tieto scopes?
            const loginRequest: RedirectRequest = {
                scopes: ['openid', 'profile', 'email'],
            };

            console.log("sme za msalInstance.handleRedirectPromise(); ************************* ");

            // otvori AAD prihlasovaciu stranku
            const accounts = msalInstance.getAllAccounts();
            console.log(accounts);
            if (accounts.length === 0) {
                console.log("sme pred loginRedirect ************************* ");
                await msalInstance.loginRedirect(loginRequest);
                //await msalInstance.loginPopup(loginRequest);
                console.log("sme za loginRedirect *****************************");
            }
        }
        catch (err) {
            console.error('Error in initializeMsalAndLogin: ', err);
            alert(err);
            //throw err;
        }
    }
*/
    const initializeApp = async () => {
        try {
            await setXTokenAndDoPostLogin();
            await fetchAndSetXMetadata();
            // vsetko zbehlo, app-ka je inicializovana
            setInitialized(true);
        }
        catch (err) {
            if (err instanceof XUserNotFoundOrDisabledError) {
                // prihlasil sa napr. gmail user, ktory nie je uvedeny v DB
                // zrusime nastaveny access token
                XUtils.setXToken(null);
                // odhlasime uzivatela
                //msalInstance.logoutRedirect();
                logoutWithRedirect();
            }
            else {
                // ak bola ina chyba, aplikacia spadne
                throw err;
            }
        }
    }

    const setXTokenAndDoPostLogin = async () => {

        // const accessToken: string = await getAccessTokenSilently(/*{
        //     audience: XUtils.getEnvVarValue(XEnvVar.REACT_APP_AUTH0_AUDIENCE)
        //     //scope: "openid profile admin:demo try:demo" - treba nam na nieco?
        // }*/);

        // TODO - treba tieto scopes?
        // const loginRequest: SilentRequest = {
        //     scopes: ['openid', 'profile', 'email'], account: msalInstance.getAllAccounts()[0]
        // };
/*
        const silentRequest: SilentRequest = {
            ...loginRequest,
            account: accounts[0],
        }

        console.log("sme pred msalInstance.acquireTokenSilent");
        const authenticationResult: AuthenticationResult = await msalInstance.acquireTokenSilent(silentRequest);

        console.log("mame access token: " + authenticationResult.accessToken);

        // post-login potrebuje accessToken, preto ho uz tu setneme
        XUtils.setXToken({accessToken: authenticationResult.accessToken});
*/
        // neviem ci tu je idealne miesto kde nastavit metodku getAccessToken, zatial dame sem
        XUtils.setXToken({accessToken: getAccessToken});

        //const accountInfo = msalInstance.getActiveAccount();
        //const accountInfo = msalInstance.getAllAccounts()[0];
        const accountInfo = accounts[0];

        // zavolame post-login
        // - overime ci je user zapisany v DB (toto sa da obist - TODO - poriesit)
        // - zosynchronizujeme zmeny (pre pripad ak sa zmenilo napr. Meno, Priezvisko) - TODO
        let xPostLoginResponse;
        try {
            const xPostLoginRequest: XPostLoginRequest = {username: accountInfo?.username};
            // standardny post-login nahradime specifickym post-login-depaul
            //xPostLoginResponse = await XUtils.fetch('post-login', xPostLoginRequest);
            xPostLoginResponse = await XUtils.fetch('post-login-depaul', xPostLoginRequest);
        }
        catch (e) {
            // console.log(typeof e);
            // console.log(e instanceof Error);
            const error: Error = e as Error;
            // console.log(JSON.stringify(error));
            console.log(error.stack);
            // console.log(error.message);
            // console.log(error.name);
            console.log(error.cause);

            XUtils.showErrorMessage('post-login failed', e);
            throw 'post-login failed';
        }

        if (xPostLoginResponse.xUser === undefined) {
            // nenasli sme usera v DB
            alert(`User account "${accountInfo?.username}" not found in DB. Login not permitted. Ask admin to create user account in DB.`);
            // pouzijeme custom exception ktoru neskor odchytime (krajsie riesenie ako vracat true/false)
            throw new XUserNotFoundOrDisabledError();
        }

        if (!xPostLoginResponse.xUser.enabled) {
            // user je disablovany
            alert(`User account "${accountInfo?.username}" is not enabled. Ask admin to enable user account.`);
            // pouzijeme custom exception ktoru neskor odchytime (krajsie riesenie ako vracat true/false)
            throw new XUserNotFoundOrDisabledError();
        }

        // ulozime si usera do access token-u - zatial take provizorne, user sa pouziva v preSave na setnutie vytvoril_id
        XUtils.setXToken({accessToken: XUtils.getXToken()?.accessToken, xUser: xPostLoginResponse.xUser});

        //console.log("App - bol uspesne zavolany getAndSetAccessToken");
    }

    const fetchAndSetXMetadata = async () => {
        await XUtilsMetadata.fetchAndSetXEntityMap();
        console.log("App - bol zavolany XUtilsMetadata.fetchAndSetXEntityMap()");
        await XUtilsMetadata.fetchAndSetXBrowseMetaMap();
        console.log("App - bol zavolany XUtilsMetadata.fetchAndSetXBrowseMetaMap()");
    }

    const loginWithRedirect = async () => {

        try {
            console.log("loginWithRedirect: ideme volat msalInstance.loginRedirect");
            await msalInstance.loginRedirect(loginRequest);
        }
        catch (err) {
            console.error('Error in loginWithRedirect: ', err);
            //alert(err);
            throw err;
        }
    }

    const logoutWithRedirect = async () => {
        const logoutRequest = {
            //account: msalInstance.getAccountByHomeId(homeAccountId),
            account: accounts[0],
            postLogoutRedirectUri: window.location.origin + window.location.pathname
        };
        await msalInstance.logoutRedirect(logoutRequest);
    }

    // tato funkcia sa vola pred kazdym requestom na backend - vola sa v metode XUtils.fetchBasic
    // ked sa vola len pri inicializacii, tak token po 1 hodine exspiruje a user si musi restartnut aplikaciu
    // acquireTokenSilent ziskava token zo sessionStorage (pozri msalConfig), ak po hodine vyexspiruje, tak ziska novy access token
    const getAccessToken = async (): Promise<string> => {

        const silentRequest: SilentRequest = {
            ...loginRequest,
            account: accounts[0],
        }

        //console.log("sme pred msalInstance.acquireTokenSilent");
        const authenticationResult: AuthenticationResult = await msalInstance.acquireTokenSilent(silentRequest);

        return authenticationResult.accessToken;
    }

    let elem;
    // if (msalContext.inProgress) {
    //     elem = <div className="App-form">User is being initialized using Azure AD...</div>;
    // }
    // else {
        if (!isAuthenticated) {
            //if (msalInstance.getAllAccounts().length === 0) {
            //loginWithRedirect();
            elem = <div className="App-form"><Button onClick={loginWithRedirect}>Log In</Button></div>;
        }
        else {
            if (!initialized) {
                elem = <div className="App-form">App is being initialized...</div>;
                console.log("ideme do initializeApp()");
                initializeApp();
            }
            else {
                elem = <XMenu defaultFormElement={null} logout={logoutWithRedirect}/>;
            }
        }
    // }

    return (
        <div className="App">
            {elem}
        </div>
    );
}

export default AppMSEntraID;
