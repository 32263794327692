import React, {useState} from "react";
import {
    XBrowseProps,
    XLazyColumn,
    XLazyDataTable
} from "@michalrakus/x-react-web-lib/XLazyDataTable";
import {XsTableForm} from "./XsTableForm";
import {XsTable} from "../../model/statistics/xs-table.entity";
import {XDateFromToDialog, XDateFromToDialogState} from "../XDateFromToDialog";
import {XUtilsCommon} from "@michalrakus/x-react-web-lib/XUtilsCommon";
import {XsRunStatisticRequest} from "../../common/utils-api";
import {XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {XsTableSubtable} from "../../model/statistics/xs-table-subtable.entity";
import {xLocaleOption} from "@michalrakus/x-react-web-lib/XLocale";

export const XsTableBrowse = (props: XBrowseProps) => {

    const [xDateFromToDialogState, setXDateFromToDialogState] = useState<XDateFromToDialogState>({opened: false});

    const onAddRow = () => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        props.openForm!(<XsTableForm/>);
    }

    const onEdit = (selectedRow: any) => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        props.openForm!(<XsTableForm id={selectedRow.id}/>);
    }

    const onRunStatistic = (selectedRow: XsTable) => {
        // warning for reports without date field
        let hasSubtableWithDateField: boolean = false;
        const fieldWithoutDateList: string[] = [];
        for (const xsTableSubtable of selectedRow.xsTableSubtableList) {
            if (xsTableSubtable.xsStatField.dateField === null) {
                fieldWithoutDateList.push(xsTableSubtable.xsStatField.label);
            }
            else {
                hasSubtableWithDateField = true;
            }
        }

        // dateFromTo dialog has sense only if there is subtable with date field
        if (hasSubtableWithDateField) {
            const dateTo: Date = XUtilsCommon.today();
            let dateFrom: Date = XUtilsCommon.dateAddMonths(dateTo, -1)!;
            dateFrom = XUtilsCommon.dateAddDays(dateFrom, 1)!;
            setXDateFromToDialogState({
                opened: true,
                dateFrom: dateFrom,
                dateTo: dateTo,
                onHideDialog: (ok: boolean, dateFrom: Date | null, dateTo: Date | null) => runStatisticOnHideDialog(ok, dateFrom, dateTo, selectedRow, fieldWithoutDateList)
            });
        }
        else {
            // run statistic without dialog date from/to, only with warning
            runStatistic(null, null, selectedRow, fieldWithoutDateList);
        }
    }

    const runStatisticOnHideDialog = (ok: boolean, dateFrom: Date | null, dateTo: Date | null, xsTable: XsTable, fieldWithoutDateList: string[]) => {
        if (ok) {
            runStatistic(dateFrom, dateTo, xsTable, fieldWithoutDateList);
        }
        setXDateFromToDialogState({opened: false});
    }

    const runStatistic = (dateFrom: Date | null, dateTo: Date | null, xsTable: XsTable, fieldWithoutDateList: string[]) => {
        if (fieldWithoutDateList.length > 0) {
            alert(xLocaleOption('runStatisticMissingDateField', {fieldsWithoutDate: fieldWithoutDateList.join(", ")}))
        }

        const xsRunStatisticRequest: XsRunStatisticRequest = {xsTableId: xsTable.id, dateFrom: dateFrom, dateTo: dateTo};
        // zbavime label diakritiky a medzier, nech ma nazov suboru pekny format
        const fileName: string = XUtils.normalizeString(xsTable.label).trim().replaceAll(' ', '-');
        XUtils.openExcelReport('xs-run-statistic', xsRunStatisticRequest, fileName);
    }

    return (
        <div>
            <XLazyDataTable entity="XsTable" label="Statistic tables" rows={30}
                            sortField={[{field: "id", order: -1}, {field: "xsTableSubtableList.subtableOrder", order: 1}]}
                            onAddRow={onAddRow} onEdit={onEdit} removeRow={true}
                            appButtonsForRow={[{key: "button-run-statistic", label: "Run statistic", onClick: onRunStatistic}]}
                            displayed={props.displayed}>
                <XLazyColumn field="id" header="ID" width="5rem"/>
                <XLazyColumn field="label" header="Label" width="20rem"/>
                <XLazyColumn field="xsTableSubtableList.xsStatField.label" header="Statistic fields" width="20rem"/>
                <XLazyColumn field="xsTableSubtableList.xsStatField.entity" header="Entity" width="15rem"/>
                <XLazyColumn field="xsTableSubtableList.xsStatField.dateField" header="Date field" width="15rem"/>
                <XLazyColumn field="xsTableSubtableList.xsStatField.fieldType.name" header="Field type" width="15rem"/>
            </XLazyDataTable>
            <XDateFromToDialog dialogState={xDateFromToDialogState}/>
        </div>
    );
}
