import React from "react";
import {Form} from "../../XLibItems";
import {XInputDecimal} from "@michalrakus/x-react-web-lib/XInputDecimal";
import {XInputText} from "@michalrakus/x-react-web-lib/XInputText";
import {XFormFooter} from "@michalrakus/x-react-web-lib/XFormFooter";
import {XInputDate} from "@michalrakus/x-react-web-lib/XInputDate";
import {XFormBaseModif} from "@michalrakus/x-react-web-lib/XFormBaseModif";
import {XCheckbox} from "@michalrakus/x-react-web-lib/XCheckbox";
import {XFormColumn, XFormDataTable2} from "@michalrakus/x-react-web-lib/XFormDataTable2";
import {XObject} from "@michalrakus/x-react-web-lib/XObject";
import type {XFormProps} from "@michalrakus/x-react-web-lib/XFormBase";
import {XFormHeader} from "@michalrakus/x-react-web-lib/XFormHeader";

@Form("XEnumEnum")
export class XEnumEnumForm extends XFormBaseModif {

    constructor(props: XFormProps) {
        super(props);

        this.onClickAddRow = this.onClickAddRow.bind(this);
    }

    createNewObject(): XObject {
        return {readOnly: false, xEnumList: [], version: 0};
    }

    onClickAddRow() {
        this.onTableAddRow("xEnumList", {enabled: true, readOnly: false});
    };

    render() {
        return (
            <div>
                <XFormHeader form={this} label="Enumeration"/>
                <div className="x-form-row">
                    <div className="x-form-col">
                        <XInputDecimal form={this} field="id" label="ID" readOnly={true}/>
                        <XInputDate form={this} field="modifDate" label="Modified at" readOnly={true}/>
                        <XInputText form={this} field="modifXUser.name" label="Modified by" size={20}/>
                        <XInputText form={this} field="code" label="Code" size={20}/>
                        <XInputText form={this} field="name" label="Name" size={30}/>
                        <XCheckbox form={this} field="readOnly" label="Read only"/>
                        <XFormDataTable2 form={this} assocField="xEnumList" label="Enums"
                                         onClickAddRow={this.onClickAddRow}>
                            <XFormColumn field="id" header="ID" readOnly={true} width="4rem"/>
                            <XFormColumn field="code" header="Code" width="16rem"/>
                            <XFormColumn field="name" header="Name" width="16rem"/>
                            <XFormColumn field="enabled" header="Enabled"/>
                            <XFormColumn field="readOnly" header="Read only"/>
                            <XFormColumn field="enumOrder" header="Enum order" width="6rem"/>
                        </XFormDataTable2>
                    </div>
                </div>
                <XFormFooter form={this}/>
            </div>
        );
    }
}
