import React from "react";
import {Form} from "../../XLibItems";
import {XInputDecimal} from "@michalrakus/x-react-web-lib/XInputDecimal";
import {XInputText} from "@michalrakus/x-react-web-lib/XInputText";
import {XFormFooter} from "@michalrakus/x-react-web-lib/XFormFooter";
import {XInputDate} from "@michalrakus/x-react-web-lib/XInputDate";
import {XFormBaseModif} from "@michalrakus/x-react-web-lib/XFormBaseModif";
import {XFormAutoCompleteColumn, XFormColumn, XFormDataTable2} from "@michalrakus/x-react-web-lib/XFormDataTable2";
import {Utils} from "../../Utils";
import {EnumEnum, SluzbaEnum} from "../../common/enums";
import {XAutoComplete} from "@michalrakus/x-react-web-lib/XAutoComplete";
import {XObject} from "@michalrakus/x-react-web-lib/lib/components/XObject";
import {XInputTextarea} from "@michalrakus/x-react-web-lib/XInputTextarea";
import {XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {KlientSluzbaBrowse} from "../klient/KlientSluzbaBrowse";
import {KlientSluzbaForm} from "../klient/KlientSluzbaForm";
import {XFormHeader} from "@michalrakus/x-react-web-lib/XFormHeader";
import {XFormCol} from "@michalrakus/x-react-web-lib/XFormCol";

@Form("MiestoMoznehoKontaktu")
export class MiestoMoznehoKontaktuForm extends XFormBaseModif {

    createNewObject(): XObject {
        return {sluzba: Utils.getCurrentSluzba(), miestoMoznehoKontaktuKlientSluzbaList: [], version: 0};
    }

    render() {
        return (
            <div>
                <XFormHeader form={this} label="Miesto možného kontaktu"/>
                <div className="x-form-row">
                    <div className="x-form-col">
                        <XInputDecimal form={this} field="id" label="ID" readOnly={true}/>
                        <XInputDate form={this} field="modifDate" label="Dátum modif." readOnly={true}/>
                        <XInputText form={this} field="modifXUser.name" label="Modifikoval" size={20}/>
                        <XInputDate form={this} field="datumPoslNavstivenia" label="Dátum posl. navštívenia" readOnly={true}/>
                        <XAutoComplete form={this} assocField="mestskaCast" displayField="name" label="Mestská časť" filter={Utils.enumFilter(EnumEnum.mestskaCast)}/>
                        <XInputText form={this} field="miesto" label="Miesto" inputStyle={{width: `calc(100% - ${XUtils.FIELD_LABEL_WIDTH})`}}/>
                        <XInputTextarea form={this} field="poznamka" label="Poznámka" autoResize={true} cols="full"/>
                        <XFormDataTable2 form={this} assocField="miestoMoznehoKontaktuKlientSluzbaList" label="Klienti">
                            <XFormColumn field="id" header="ID" readOnly={true} width="4rem"/>
                            <XFormAutoCompleteColumn assocField="klientSluzba" displayField="klient.menoPriezviskoPrezyvka" header="Klient" width="25rem"
                                                     filter={Utils.filterCurrentSluzba()}
                                                     searchBrowse={<KlientSluzbaBrowse/>} assocForm={<KlientSluzbaForm/>}/>
                        </XFormDataTable2>
                    </div>
                </div>
                <XFormFooter form={this}/>
            </div>
        );
    }
}
