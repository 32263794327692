import {
    XBrowseProps,
    XLazyColumn,
    XLazyDataTable
} from "@michalrakus/x-react-web-lib/XLazyDataTable";
import React from "react";
import {SluzbaForm} from "./SluzbaForm";
import {xLocaleOption} from "@michalrakus/x-react-web-lib/XLocale";
import {XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {Sluzba} from "../../model/user/sluzba.entity";

export const SluzbaBrowse = (props: XBrowseProps) => {

    const onAddRow = () => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        props.openForm!(<SluzbaForm/>);
    }

    const onEdit = (selectedRow: any) => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        props.openForm!(<SluzbaForm id={selectedRow.id}/>);
    }

    const onRemoveRow = async (selectedRow: any): Promise<boolean> =>  {

        // nacitame si zaznam aj so zoznamom sluzbaRolaList
        const sluzba: Sluzba | null = await XUtils.fetchById("Sluzba", ["sluzbaRolaList.id"], selectedRow.id);
        if (!sluzba) {
            throw 'Neocakavana chyba, zaznam nebol najdeny';
        }
        if (sluzba.sluzbaRolaList.length > 0) {
            alert(`Nie je dovolené vymazať záznam, lebo obsahuje záznamy Role. Vymažte najprv jednotlivé záznamy Rola.`);
            return false;
        }

        // default
        if (window.confirm(xLocaleOption('removeRowConfirm'))) {
            await XUtils.removeRow("Sluzba", selectedRow);
            return true;
        }
        return false;
    }

    return (
        <XLazyDataTable entity="Sluzba" label="Služby" sortField="id" rows={30}
                        onAddRow={onAddRow} onEdit={onEdit} removeRow={onRemoveRow} displayed={props.displayed}>
            <XLazyColumn field="id" header="ID"/>
            <XLazyColumn field="kod" header="Kód" width="15rem"/>
            <XLazyColumn field="utulok" header="Útulok"/>
            <XLazyColumn field="nazov" header="Názov" width="20rem"/>
        </XLazyDataTable>
    );
}
