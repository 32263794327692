import React, {useEffect, useState} from "react";
import {XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {Zapis} from "../../model/zapisy/zapis.entity";
import {dateAsUI} from "@michalrakus/x-react-web-lib/XUtilsConversions";
import {XHtmlRenderer} from "@michalrakus/x-react-web-lib/XHtmlRenderer";
import {Panel} from "primereact/panel";

// komponent ktory zobrazi katamnezy daneho klienta
export const KlientSluzbaKatamneza = (props: {
    klientSluzbaId: number;
}) => {

    const [zapisList, setZapisList] = useState<Zapis[]>([]);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        // tu by stacilo nacitavat len datum a vseobecnyZapis
        // nacitame zapisy ktore maju zaskrtnutu klikacku katamneza
        const zapisList: any[] = await XUtils.fetchRows('Zapis', {where: "[klientSluzba] = :klientSluzbaId AND CAST([atributy]->'katamneza' AS BOOLEAN)", params: {"klientSluzbaId": props.klientSluzbaId}}, "datum");
        setZapisList(zapisList);
    }

    return (
        <div>
            {zapisList.map((zapis: Zapis) =>
                <Panel key={zapis.id} header={"Katamnéza " + dateAsUI(zapis.datum)} className="m-1" pt={{title: {style: {width: '100%'}}}} toggleable>
                    <XHtmlRenderer htmlValue={zapis.vseobecnyZapis} renderType="allLines" fewLinesCount={10}/>
                </Panel>
            )}
        </div>
    );
}
