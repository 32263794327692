import React from "react";
import {XFormComponent, XFormComponentProps} from "@michalrakus/x-react-web-lib/lib/components/XFormComponent";
import {XInputFileBase} from "./XInputFileBase";
import {XFile} from "../../model/user/x-file.entity";
import {XAssoc} from "@michalrakus/x-react-web-lib/lib/serverApi/XEntityMetadata";
import {XUtilsMetadataCommon} from "@michalrakus/x-react-web-lib/XUtilsMetadataCommon";

export interface XInputFileProps extends XFormComponentProps<number> {
    fileField: string; // OneToOne or ManyToOne association to entity XFile
    inputStyle?: React.CSSProperties;
    saveDest: "fileSystem" | "database";
    subdir?: string; // subdirectory, where to save uploaded file (for the case saveDest === "fileSystem")
    maxFileSize?: number; // maximum file size allowed in bytes
    downloadOnly?: boolean; // if true, no upload and remove buttons rendered (readOnly === true renders those buttons disabled)
    downloadAndRemoveOnly?: boolean; // if true, only download and remove buttons rendered (no upload button rendered)
}

export class XInputFile extends XFormComponent<XFile, XInputFileProps> {

    public static defaultProps = {
        saveDest: "fileSystem",
        maxFileSize: 10000000 /* 10 MB */
    };

    protected xAssoc: XAssoc;

    constructor(props: XInputFileProps) {
        super(props);

        this.xAssoc = XUtilsMetadataCommon.getXAssocToOne(XUtilsMetadataCommon.getXEntity(props.form.getEntity()), props.fileField);

        props.form.addField(props.fileField + '.name'); // "name" is not important, it is just one of attributes of entity XFiel

        this.onValueChange = this.onValueChange.bind(this);
    }

    getField(): string {
        return this.props.fileField;
    }

    isNotNull(): boolean {
        return !this.xAssoc.isNullable;
    }

    getValue(): XFile | null {
        return this.getValueFromObject();
    }

    onValueChange(value: XFile | null) {
        this.onValueChangeBase(value, this.props.onChange);
    }

    render() {
        return (
            <div className="field grid">
                <label htmlFor={this.props.fileField} className="col-fixed" style={this.getLabelStyle()}>{this.getLabel()}</label>
                <XInputFileBase id={this.props.fileField} value={this.getValue()} onChange={this.onValueChange}
                                readOnly={this.isReadOnly()} error={this.getError()} style={this.props.inputStyle} {...this.getClassNameTooltip()}
                                saveDest={this.props.saveDest} subdir={this.props.subdir} maxFileSize={this.props.maxFileSize}
                                downloadOnly={this.props.downloadOnly} downloadAndRemoveOnly={this.props.downloadAndRemoveOnly}/>
            </div>
        );
    }
}
