import {
    XBrowseProps,
    XLazyColumn,
    XLazyDataTable,
    XSearchBrowseProps
} from "@michalrakus/x-react-web-lib/XLazyDataTable";
import React from "react";
import {XEnumEnumForm} from "./XEnumEnumForm";

export const XEnumEnumBrowse = (props: XBrowseProps & XSearchBrowseProps) => {

    const onAddRow = () => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        props.openForm!(<XEnumEnumForm/>);
    }

    const onEdit = (selectedRow: any) => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        props.openForm!(<XEnumEnumForm id={selectedRow.id}/>);
    }

    return (
        <XLazyDataTable entity="XEnumEnum" label="Enumerations" sortField="id" rows={30}
                        onAddRow={onAddRow} onEdit={onEdit} removeRow={true}
                        displayed={props.displayed} searchBrowseParams={props.searchBrowseParams}>
            <XLazyColumn field="id" header="ID"/>
            <XLazyColumn field="code" header="Code" width="16rem"/>
            <XLazyColumn field="name" header="Name" width="16rem"/>
            <XLazyColumn field="readOnly" header="Read only"/>
        </XLazyDataTable>
    );
}
