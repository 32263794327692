import React from "react";
import {Form} from "../../XLibItems";
import {XInputDecimal} from "@michalrakus/x-react-web-lib/XInputDecimal";
import {XInputText} from "@michalrakus/x-react-web-lib/XInputText";
import {XFormFooter} from "@michalrakus/x-react-web-lib/XFormFooter";
import {XInputDate} from "@michalrakus/x-react-web-lib/XInputDate";
import {XFormBaseModif} from "@michalrakus/x-react-web-lib/XFormBaseModif";
import {
    XFormAutoCompleteColumn,
    XFormColumn,
    XFormDataTable2
} from "@michalrakus/x-react-web-lib/XFormDataTable2";
import {XObject} from "@michalrakus/x-react-web-lib/XObject";
import {XFormHeader} from "@michalrakus/x-react-web-lib/XFormHeader";
import {XsGroupByFieldBrowse} from "./XsGroupByFieldBrowse";
import {XsGroupByFieldForm} from "./XsGroupByFieldForm";
import {XsStatFieldBrowse} from "./XsStatFieldBrowse";
import {XsStatFieldForm} from "./XsStatFieldForm";
import type {XFormProps} from "@michalrakus/x-react-web-lib/XFormBase";
import {XCustomFilter} from "@michalrakus/x-react-web-lib/FindParam";
import {XsTable} from "../../model/statistics/xs-table.entity";
import {Utils} from "../../Utils";
import {stringAsDB} from "@michalrakus/x-react-web-lib/XUtilsConversions";
import {UtilsCommon} from "../../common/UtilsCommon";

@Form("XsTable")
export class XsTableForm extends XFormBaseModif {

    constructor(props: XFormProps) {
        super(props);

        this.onClickAddRowSubtable = this.onClickAddRowSubtable.bind(this);
        this.createFilterForXsGroupByField = this.createFilterForXsGroupByField.bind(this);
    }

    createNewObject(): XObject {
        return {xsTableGroupByFieldForColumnsList: [], xsTableGroupByFieldForRowsList: [], xsTableSubtableList: [], version: 0};
    }

    onClickAddRowSubtable() {
        this.onTableAddRow("xsTableSubtableList", {createSumRow: false, createSumColumn: false});
    };

    createFilterForXsGroupByField(object: XsTable, rowData: any): XCustomFilter | undefined {
        let filter: XCustomFilter | undefined = undefined;
        if (object) {
            // create condition for every entity of subtable
            let whereExistsList: string[] = [];
            for (const xsTableSubtable of object.xsTableSubtableList) {
                whereExistsList.push(`EXISTS (SELECT 1 FROM ${Utils.getSchema()}.xs_group_by_field_by_entity gbe WHERE gbe.xs_group_by_field_id = [id] AND gbe.entity = ${stringAsDB(xsTableSubtable.xsStatField.entity)})`);
            }
            // exists condition is used for group by types with mandatory fields - for "date types" day/month/year is exists not used
            // (as field is usually used date field from XsStatField)
            filter = {
                where: `(([fieldType.code] NOT IN (:...dateTypeList) AND (${whereExistsList.join(" AND ")})) OR ([fieldType.code] IN (:...dateTypeList)))`,
                params: {dateTypeList: UtilsCommon.xGroupByTypeDateTypeList()}
            };
        }
        return filter;
    }

    render() {
        return (
            <div>
                <XFormHeader form={this} label="Statistic table"/>
                <div className="x-form-row">
                    <div className="x-form-col">
                        <XInputDecimal form={this} field="id" label="ID" readOnly={true}/>
                        <XInputDate form={this} field="modifDate" label="Modified at" readOnly={true}/>
                        <XInputText form={this} field="modifXUser.name" label="Modified by" inputStyle={{width:'12.5rem'}}/>
                        <XInputText form={this} field="label" label="Label" inputStyle={{width:'20rem'}}/>
                        <XFormDataTable2 form={this} assocField="xsTableSubtableList" label="Statistic fields" sortField="subtableOrder"
                                         onClickAddRow={this.onClickAddRowSubtable}>
                            <XFormColumn field="subtableOrder" header="Order" width="4rem"/>
                            <XFormAutoCompleteColumn assocField="xsStatField" displayField="label" header="Statistic field" width="30rem"
                                                     searchBrowse={<XsStatFieldBrowse/>} assocForm={<XsStatFieldForm/>}/>
                            <XFormColumn field="xsStatField.entity" header="Entity" width="15rem"/>
                            <XFormColumn field="createSumRow" header="Create sum row" width="10rem"/>
                            <XFormColumn field="createSumColumn" header="Create sum column" width="10rem"/>
                            <XFormColumn field="filter" header="Filter" width="15rem"/>
                        </XFormDataTable2>
                        <XFormDataTable2 form={this} assocField="xsTableGroupByFieldForColumnsList" label="Group by fields for columns" sortField="fieldOrder">
                            <XFormColumn field="fieldOrder" header="Order" width="4rem"/>
                            <XFormAutoCompleteColumn assocField="xsGroupByField" displayField="label" header="Group by field" width="25rem" fields={["fieldType.name"]}
                                                     searchBrowse={<XsGroupByFieldBrowse/>} assocForm={<XsGroupByFieldForm/>} filter={this.createFilterForXsGroupByField}/>
                            <XFormColumn field="xsGroupByField.fieldType.name" header="Field type" width="15rem"/>
                            <XFormColumn field="filter" header="Filter" width="15rem"/>
                        </XFormDataTable2>
                        <XFormDataTable2 form={this} assocField="xsTableGroupByFieldForRowsList" label="Group by fields for rows" sortField="fieldOrder">
                            <XFormColumn field="fieldOrder" header="Order" width="4rem"/>
                            <XFormAutoCompleteColumn assocField="xsGroupByField" displayField="label" header="Group by field" width="25rem" fields={["fieldType.name"]}
                                                     searchBrowse={<XsGroupByFieldBrowse/>} assocForm={<XsGroupByFieldForm/>} filter={this.createFilterForXsGroupByField}/>
                            <XFormColumn field="xsGroupByField.fieldType.name" header="Field type" width="15rem"/>
                            <XFormColumn field="filter" header="Filter" width="15rem"/>
                        </XFormDataTable2>
                    </div>
                </div>
                <XFormFooter form={this}/>
            </div>
        );
    }
}
