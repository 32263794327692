import React, {useState} from "react";
import {
    XBrowseProps,
    XLazyColumn,
    XLazyDataTable,
    XSearchBrowseProps
} from "@michalrakus/x-react-web-lib/XLazyDataTable";
import {Utils} from "../../Utils";
import {KlientSluzbaForm} from "./KlientSluzbaForm";
import {HladatKlientaMimoSluzbuDialog, HladatKlientaMimoSluzbuDialogState} from "./HladatKlientaMimoSluzbuDialog";
import {Klient} from "../../model/klient/klient.entity";
import {OperationType, XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {xLocaleOption} from "@michalrakus/x-react-web-lib/XLocale";
import {KlientSluzba} from "../../model/klient/klient-sluzba.entity";
import {ColumnBodyOptions} from "primereact/column";
import {AsUIType, convertValueBase, XDateScale} from "@michalrakus/x-react-web-lib/XUtilsConversions";
import {XUtilsCommon} from "@michalrakus/x-react-web-lib/XUtilsCommon";

export const KlientSluzbaBrowse = (props: XBrowseProps & XSearchBrowseProps) => {

    const [hladatKlientaDialogState, setHladatKlientaDialogState] = useState<HladatKlientaMimoSluzbuDialogState>({opened: false});

    const today: Date = XUtilsCommon.today();

    const onAddRow = () => {

        if (!Utils.getCurrentSluzba()) {
            alert("Vyberte najprv službu.");
            return;
        }

        setHladatKlientaDialogState({
            opened: true,
            onHideDialog: onHideDialog
        });
    }

    const onHideDialog = async (ok: boolean, klient?: Klient | null, objectChange?: OperationType) => {
        // TODO - objectChange nie je spraveny, zatial nepouzivat
        setHladatKlientaDialogState({opened: false});
        if (ok) {
            if (klient !== null) {
                // TODO - zatial vezmeme priamo cely zaznam Klient a pouzijeme ho vo formulari KlientSluzbaForm pre update
                // -> korektnejsie by bolo teraz rereadnut zaznam z DB (pouzit klient!.id)
                // potrebujeme najoinovat vsetko co sa pouziva vo formulari KlientSluzbaForm v casti klient (cast KlientSluzba bude insertovana) (v autocomplete zobrazujeme aj obec aj okres)
                const klientFromDB: Klient = await XUtils.fetchById("Klient", ["pohlavie.name", "obec.okres.nazov", "statCiselnik.nazov", "klientSluzbaList.sluzba.nazov"], klient!.id);
                // otvorime formular KlientForm v rezime na insert (id zaznamu KlientSluzba je undefined) a predplnime niektore atributy
                props.openForm!(<KlientSluzbaForm initValues={{klient: klientFromDB, sluzba: Utils.getCurrentSluzba(), version: 0}}/>);
            }
            else {
                // otvorime formular KlientForm v rezime na insert, pricom aj klient bude nanovo vytvoreny
                // inicializacia prebehne cez KlientSluzbaForm.createNewObject()
                props.openForm!(<KlientSluzbaForm/>);
            }
        }
    }

    const onEdit = (selectedRow: any) => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        props.openForm!(<KlientSluzbaForm id={selectedRow.id}/>);
    }

    const onRemoveRow = async (selectedRow: any): Promise<boolean> =>  {

        if (window.confirm(xLocaleOption('removeRowConfirm'))) {
            await XUtils.post('remove-klient-sluzba', {id: selectedRow.id});
            return true;
        }
        return false;
    }

    const rowClassName = (rowData: KlientSluzba) => {
        return {
            'klient-sluzba-zakaz': Utils.klientSluzbaMaZakaz(rowData, today)
        };
    };

    const klientDatumNarodeniaBody = (klientSluzba: KlientSluzba, options: ColumnBodyOptions): React.ReactNode => {
        return convertValueBase('date', klientSluzba.klient.datumNarodeniaIbaRok ? XDateScale.Year : XDateScale.Date, klientSluzba.klient.datumNarodenia, true, AsUIType.Form);
    }

    return (
        <div>
            <XLazyDataTable entity="KlientSluzba" label="Klienti" sortField="id desc" rows={30} customFilter={Utils.filterCurrentSluzba()}
                            onAddRow={onAddRow} onEdit={onEdit} removeRow={onRemoveRow}
                            fields={["klientSluzbaZakazList.id"]} rowClassName={rowClassName}
                            displayed={props.displayed} searchBrowseParams={props.searchBrowseParams}>
                <XLazyColumn field="id" header="ID" width="5rem"/>
                <XLazyColumn field="klient.menoPriezviskoPrezyvka" header="Meno priezvisko prezývka" width="20rem"/>
                <XLazyColumn field="klient.datumNarodenia" header="Dátum narodenia" body={klientDatumNarodeniaBody}/>
                <XLazyColumn field="klient.ulica" header="Ulica" width="13rem"/>
                <XLazyColumn field="klient.cisloDomu" header="Číslo domu" width="7rem"/>
                <XLazyColumn field="klient.psc" header="PSČ" width="5rem"/>
                <XLazyColumn field="klient.mesto" header="Mesto/obec (text)" width="11rem"/>
                <XLazyColumn field="klient.obec.nazov" header="Mesto/obec" width="10rem"/>
                <XLazyColumn field="klient.obec.okres.nazov" header="Okres" width="10rem"/>
                <XLazyColumn field="klient.statCiselnik.nazov" header="Štát" width="8rem"/>
                <XLazyColumn field="klucovyPracovnikXUser.name" header="Kľúčový pracovník" width="12rem"/>
            </XLazyDataTable>
            <HladatKlientaMimoSluzbuDialog dialogState={hladatKlientaDialogState}/>
        </div>
    );
}
