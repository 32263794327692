import React, {useState} from "react";
import {Dialog} from "primereact/dialog";
import {
    XChildrenLayout,
    xChildrenLayoutOptionsToAdd,
    XFieldMeta,
    XFieldOperation,
    XFieldProp,
    XFieldPropEditProps,
    XFieldPropValues,
    XFieldPropValuesForEdit,
    XFieldPropValuesFrom,
    XFieldPropValuesMapForEdit,
    XFieldSetBase,
    XFieldType,
    xFieldTypeOptionsToAdd
} from "./XFieldSetBase";
import {InputText} from "primereact/inputtext";
import {XCalendar} from "@michalrakus/x-react-web-lib/XCalendar";
import {XButton} from "@michalrakus/x-react-web-lib/XButton";
import {Dropdown} from "primereact/dropdown";
import {XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {Panel} from "primereact/panel";
import * as _ from "lodash";
import {XSimpleFieldPropEdit} from "./XSimpleFieldPropEdit";
import {Checkbox} from "primereact/checkbox";
import {XInputTextareaBase} from "@michalrakus/x-react-web-lib/XInputTextareaBase";
import {InputNumber, InputNumberChangeEvent} from "primereact/inputnumber";

export const XEditFieldDialog = (props: {
        dialogOpened: boolean;
        xFieldOperationEnum: XFieldOperation;
        xFieldMeta: XFieldMeta;
        getFieldByName: (field: string) => XFieldMeta | undefined;
        getMaxFieldGroupId: () => number;
        fieldProps?: XFieldProp[];
        onHideDialog: (xFieldMeta: XFieldMeta | null) => void;
    }) => {

    const cloneDeep = (fieldPropValuesMap: XFieldPropValuesMapForEdit | undefined): XFieldPropValuesMapForEdit | undefined => {
        return fieldPropValuesMap ? _.cloneDeep(fieldPropValuesMap) : undefined;
    }

    // bez tohto to pada este skor ako dojde k otvoreniu dialogu
    const xFieldMetaNotNull = props.xFieldMeta ?? {field: "", label: ""};

    // OTAZKA - treba sem inicializovat props.xFieldMeta, ked hodnoty setujeme v onShow metode?
    const [type, setType] = useState<XFieldType | undefined>();
    const [decimalScale, setDecimalScale] = useState<number | undefined>();
    const [childrenLayout, setChildrenLayout] = useState<XChildrenLayout | undefined>();
    const [width, setWidth] = useState<string | undefined>();
    const [labelWidth, setLabelWidth] = useState<string | undefined>();
    const [inputWidth, setInputWidth] = useState<string | undefined>();
    const [suppressFieldGroupPanel, setSuppressFieldGroupPanel] = useState<true | undefined>();
    const [field, setField] = useState<string>("");
    const [label, setLabel] = useState<string>("");
    const [tooltip, setTooltip] = useState<string | undefined>();
    const [dateFrom, setDateFrom] = useState<Date | undefined>();
    const [dateTo, setDateTo] = useState<Date | undefined>();
    const [fieldPropValuesMap, setFieldPropValuesMap] = useState<XFieldPropValuesMapForEdit | undefined>();

    // bez tejto metody by nefungovala inicializacia stavu podla "props.xEditColumnDialogValues" pri opetovnom otvarani dialogu
    // asi to neni moc pekny sposob ale nechce sa mi posuvat stav do vyssej komponenty, zatial nechame takto
    const onShow = () => {

        setType(xFieldMetaNotNull.type);
        setDecimalScale(adjustDecimalScale(xFieldMetaNotNull.type, xFieldMetaNotNull.decimalProps?.scale));
        setChildrenLayout(adjustChildrenLayout(xFieldMetaNotNull.type, xFieldMetaNotNull.childrenLayout));
        setWidth(xFieldMetaNotNull.width);
        setLabelWidth(xFieldMetaNotNull.labelWidth);
        setInputWidth(xFieldMetaNotNull.inputWidth);
        setSuppressFieldGroupPanel(xFieldMetaNotNull.suppressFieldGroupPanel);
        setField(xFieldMetaNotNull.field);
        setLabel(xFieldMetaNotNull.label);
        setTooltip(xFieldMetaNotNull.tooltip);
        setDateFrom(xFieldMetaNotNull.dateFrom);
        setDateTo(xFieldMetaNotNull.dateTo);
        // vytvorime si kopiu pre pripad ze user zrusi editaciu (nezmenia sa povodne hodnoty)
        setFieldPropValuesMap(adjustFieldPropValuesMap(cloneDeep(xFieldMetaNotNull.fieldPropValuesMapForEdit)));
    }

    const adjustChildrenLayout = (xFieldType: XFieldType | undefined, xChildrenLayout: XChildrenLayout | undefined): XChildrenLayout | undefined => {
        // nastavime default layout
        if (xFieldType === XFieldType.rootField || XFieldType.fieldGroup) {
            if (xChildrenLayout === undefined) {
                xChildrenLayout = XChildrenLayout.column; // default
            }
        }
        else {
            xChildrenLayout = undefined;
        }
        return xChildrenLayout;
    }

    const adjustDecimalScale = (xFieldType: XFieldType | undefined, decimalScale: number | undefined): number | undefined => {
        // nastavime default
        if (xFieldType === XFieldType.inputDecimal) {
            if (decimalScale === undefined) {
                decimalScale = 2; // default
            }
        }
        else {
            decimalScale = undefined;
        }
        return decimalScale;
    }

    const adjustFieldPropValuesMap = (xFieldPropValuesMap: XFieldPropValuesMapForEdit | undefined): XFieldPropValuesMapForEdit | undefined => {
        // ak treba, vytvorime prazdny objekt XFieldPropValues
        // ak treba, doplnime uz existujuci objekt XFieldPropValues o pripadne nove props (ktore mohli byt pridane do fieldProps)
        // (metoda sa uplatnuje pri vytvoreni noveho fieldu, pripadne po uprave XFieldSet.fieldProps a zavolani editacie existujuceho fieldu)
        if (props.fieldProps) {
            if (xFieldPropValuesMap === undefined) {
                xFieldPropValuesMap = {};
            }
            for (const xFieldProp of props.fieldProps) {
                if (xFieldPropValuesMap[xFieldProp.id] === undefined) {
                    // ak existuje parent, nastavime valuesFrom = parent
                    const valuesFrom: XFieldPropValuesFrom = props.xFieldMeta.xFieldMetaParentCached !== undefined ? XFieldPropValuesFrom.parent : XFieldPropValuesFrom.this;
                    xFieldPropValuesMap[xFieldProp.id] = {
                        valuesFrom: valuesFrom
                    }
                }
            }
        }
        else {
            xFieldPropValuesMap = undefined; // ak nemame fieldProps, nepouzivame fieldPropValuesMap
        }
        return xFieldPropValuesMap;
    }

    const isOperationAddField = (): boolean => {
        return props.xFieldOperationEnum === XFieldOperation.AddFieldAsChild || props.xFieldOperationEnum === XFieldOperation.AddFieldAsSibling;
    }

    const onSave = () => {

        if (!type) {
            alert("Please select the field type.");
            return;
        }

        if (type === XFieldType.inputDecimal && decimalScale === undefined) {
            alert("Please type the decimal scale.");
            return;
        }

        if (!field) {
            alert("Please type the field name.");
            return;
        }

        // dame validaciu, nech mozme nazvy v buducnosti pouzit aj na nazvy stlpcov v DB
        const pattern: RegExp = /^[a-z][a-z0-9_]+$/;
        if (!pattern.test(field)) {
            alert("Field name can contain only characters: a-z, 0-9, _");
            return;
        }

        if (isOperationAddField()) {
            const xFieldMetaExisting: XFieldMeta | undefined = props.getFieldByName(field);
            if (xFieldMetaExisting) {
                alert(`Field name "${field}" is already used for the field with label "${xFieldMetaExisting.label}".`);
                return;
            }
        }

        if (props.fieldProps && fieldPropValuesMap) {
            for (const xFieldProp of props.fieldProps) {
                const xFieldPropValuesForEdit: XFieldPropValuesForEdit = fieldPropValuesMap![xFieldProp.id];
                if (xFieldPropValuesForEdit.error) {
                    alert(`Error on field prop ${xFieldProp.label}: ${xFieldPropValuesForEdit.error}`);
                    return;
                }
            }
        }

        let xFieldMetaList: XFieldMeta[] | undefined = undefined;
        if (XFieldSetBase.isFieldGroup(type)) {
            if (isOperationAddField()) {
                xFieldMetaList = []; // new field
            }
            else {
                // operation EditField
                xFieldMetaList = props.xFieldMeta?.xFieldMetaList; // existing field
            }
        }
        props.onHideDialog({
            type: type,
            childrenLayout: isChildrenLayoutVisible() ? childrenLayout : undefined, // pre poriadok v json datach
            width: width,
            labelWidth: isLabelWidthVisible() ? labelWidth : undefined,
            inputWidth: isInputWidthVisible() ? inputWidth : undefined,
            suppressFieldGroupPanel: isSuppressFieldGroupPanelVisible() ? suppressFieldGroupPanel : undefined, // pre poriadok v json datach
            field: field,
            label: label,
            tooltip: tooltip,
            decimalProps: isInputDecimal() ? {scale: decimalScale!} : undefined,
            dateFrom: dateFrom,
            dateTo: dateTo,
            xFieldMetaList: xFieldMetaList,
            fieldPropValuesMapForEdit: fieldPropValuesMap,
            xFieldMetaParentCached: props.xFieldMeta.xFieldMetaParentCached
        });
    }

    const onChangeType = (e: any) => {
        setType(e.value);

        // zrusime hodnotu, pripadne nastavime default hodnotu
        setDecimalScale(adjustDecimalScale(e.value, decimalScale));

        // zrusime hodnotu, pripadne nastavime default hodnotu
        setChildrenLayout(adjustChildrenLayout(e.value, childrenLayout));

        if (e.value === XFieldType.fieldGroup) {
            // prednastavime uzivatelovi unique nazov fieldu, nech nemusi vymyslat nazov
            if (field === "") {
                const maxId: number = props.getMaxFieldGroupId();
                setField(`${XFieldSetBase.fieldGroupPrefix}${maxId + 1}`);
            }
        }
    }

    const onChangeField = (e: any) => {
        // zbavime field name diakritiky a medzier, nech mozme field name v buducnosti pouzit ako nazov stlpca v tabulke
        let fieldName: string = e.target.value;
        if (fieldName) {
            fieldName = XUtils.normalizeString(e.target.value).trim().replaceAll(' ', '_');
        }
        setField(fieldName);
    }

    const onChangeValueFrom = (xFieldPropValuesForEdit: XFieldPropValuesForEdit, value: XFieldPropValuesFrom) => {
        xFieldPropValuesForEdit.valuesFrom = value;
        if (xFieldPropValuesForEdit.valuesFrom === XFieldPropValuesFrom.parent) {
            // discard values, we don´t need them, we use values from parent
            xFieldPropValuesForEdit.values = undefined;
        }
        // treba klonovat, inac react nezobrazi zmenenu hodnotu
        const fieldPropValuesMapCloned: XFieldPropValuesMapForEdit = {...fieldPropValuesMap};
        setFieldPropValuesMap(fieldPropValuesMapCloned);
    }

    const onChangeFieldPropValues = (xFieldPropValuesForEdit: XFieldPropValuesForEdit, value: XFieldPropValues) => {
        xFieldPropValuesForEdit.values = value;
        // treba klonovat, inac react nezobrazi zmenenu hodnotu
        const fieldPropValuesCloned: XFieldPropValuesMapForEdit = {...fieldPropValuesMap};
        setFieldPropValuesMap(fieldPropValuesCloned);
    }

    const createFieldProp = (xFieldProp: XFieldProp, fieldPropElemList: JSX.Element[]) => {
        const xFieldPropValuesForEdit: XFieldPropValuesForEdit = fieldPropValuesMap![xFieldProp.id];
        fieldPropElemList.push(
            <Panel key={xFieldProp.id} header={xFieldProp.label}>
                <div className="field grid">
                    <label className="col-fixed" style={{width:'8rem'}}>Values from</label>
                    <Dropdown options={XUtils.options(props.xFieldMeta.xFieldMetaParentCached !== undefined ? [XFieldPropValuesFrom.parent, XFieldPropValuesFrom.this] : [XFieldPropValuesFrom.this])}
                              value={xFieldPropValuesForEdit.valuesFrom} onChange={(e: any) => onChangeValueFrom(xFieldPropValuesForEdit, e.value)}/>
                </div>
                {createFieldPropEdit(xFieldProp.fieldPropEdit, xFieldPropValuesForEdit)}
            </Panel>
        );
    }

    const createFieldPropEdit = (fieldPropEdit: JSX.Element | undefined, xFieldPropValuesForEdit: XFieldPropValuesForEdit): JSX.Element | undefined => {
        let elem: JSX.Element | undefined = undefined;
        if (xFieldPropValuesForEdit.valuesFrom === XFieldPropValuesFrom.this) {
            if (!fieldPropEdit) {
                fieldPropEdit = <XSimpleFieldPropEdit/>; // default fieldPropEdit component
            }
            /* klonovanim elementu pridame atributy value, onChange */
            elem = React.cloneElement(fieldPropEdit, {
                value: xFieldPropValuesForEdit.values,
                onChange: (value: XFieldPropValues) => onChangeFieldPropValues(xFieldPropValuesForEdit, value),
                onErrorChange: (error: (string | undefined)) => xFieldPropValuesForEdit.error = error
            } satisfies XFieldPropEditProps/*, itemListOrFieldPropEdit.children*/);
        }
        return elem;
    }

    const isInputDecimal = (): boolean => {
        return type === XFieldType.inputDecimal;
    }

    const isChildrenLayoutVisible = (): boolean => {
        return type === XFieldType.rootField || type === XFieldType.fieldGroup;
    }

    const isLabelWidthVisible = (): boolean => {
        // poznamka - dali sme aj pre checkbox aj ked tam staci nastavit standardny width, ale nech je to podobne s inputText-om
        return type === XFieldType.inputText || type === XFieldType.inputDecimal || type === XFieldType.checkbox;
    }

    const isInputWidthVisible = (): boolean => {
        return type === XFieldType.inputText || type === XFieldType.inputDecimal;
    }

    const isSuppressFieldGroupPanelVisible = (): boolean => {
        return type === XFieldType.fieldGroup && (childrenLayout === XChildrenLayout.column || childrenLayout === XChildrenLayout.row);
    }

    let fieldPropElemList: JSX.Element[] = [];
    if (props.dialogOpened) {
        if (props.fieldProps && fieldPropValuesMap) {
            for (const xFieldProp of props.fieldProps) {
                createFieldProp(xFieldProp, fieldPropElemList);
            }
        }
    }

    const operationEditField: boolean = !isOperationAddField();
    let xFieldTypeOptions: XFieldType[] = xFieldTypeOptionsToAdd;
    if (xFieldMetaNotNull.type === XFieldType.rootField) {
        xFieldTypeOptions = [XFieldType.rootField, ...xFieldTypeOptions]; // nech vidno v dropdowne aj root
    }

    const dialogLabelWidth: string = '12rem';

    // poznamka: renderovanie vnutornych komponentov Dialogu sa zavola az po otvoreni dialogu
    return (
        <Dialog visible={props.dialogOpened} onShow={onShow} onHide={() => props.onHideDialog(null)}>
            <div className="field grid">
                <label htmlFor="type" className="col-fixed" style={{width: dialogLabelWidth}}>Field type</label>
                <Dropdown id="type" options={XUtils.options(xFieldTypeOptions)} value={type} onChange={onChangeType}
                          readOnly={operationEditField} disabled={operationEditField}/>
            </div>
            {isInputDecimal() ?
                <div className="field grid">
                    <label htmlFor="decimalScale" className="col-fixed" style={{width: dialogLabelWidth}}>Decimal scale</label>
                    <InputNumber id="decimalScale" value={decimalScale} onChange={(e: InputNumberChangeEvent) => setDecimalScale(e.value !== null ? e.value : undefined)} mode="decimal" locale="de-DE"
                                 useGrouping={false} minFractionDigits={0} maxFractionDigits={0} min={0} max={12} style={{width:'10rem'}}/>
                </div>
            : null}
            {isChildrenLayoutVisible() ?
                <div className="field grid">
                    <label htmlFor="childrenLayout" className="col-fixed" style={{width: dialogLabelWidth}}>Children layout</label>
                    <Dropdown id="childrenLayout" options={XUtils.options(xChildrenLayoutOptionsToAdd)} value={childrenLayout ?? XChildrenLayout.row} onChange={(e: any) => setChildrenLayout(e.value)}/>
                </div>
            : null}
            <div className="field grid">
                <label htmlFor="width" className="col-fixed" style={{width: dialogLabelWidth}}>Width (e.g. 7.5rem)</label>
                <InputText id="width" value={width !== undefined ? width : ""} onChange={(e: any) => setWidth(e.target.value !== "" ? e.target.value : undefined)} style={{width:'30rem'}}/>
            </div>
            {isLabelWidthVisible() ?
                <div className="field grid">
                    <label htmlFor="labelWidth" className="col-fixed" style={{width: dialogLabelWidth}}>Label width (e.g. 7.5rem)</label>
                    <InputText id="labelWidth" value={labelWidth !== undefined ? labelWidth : ""} onChange={(e: any) => setLabelWidth(e.target.value !== "" ? e.target.value : undefined)} style={{width:'30rem'}}/>
                </div>
            : null}
            {isInputWidthVisible() ?
                <div className="field grid">
                    <label htmlFor="inputWidth" className="col-fixed" style={{width: dialogLabelWidth}}>Input width (e.g. 7.5rem)</label>
                    <InputText id="inputWidth" value={inputWidth !== undefined ? inputWidth : ""} onChange={(e: any) => setInputWidth(e.target.value !== "" ? e.target.value : undefined)} style={{width:'30rem'}}/>
                </div>
            : null}
            {isSuppressFieldGroupPanelVisible() ?
                <div className="field grid">
                    <label htmlFor="suppressFieldGroupPanel" className="col-fixed" style={{width:'15rem'}}>Suppress field group panel</label>
                    <Checkbox id="suppressFieldGroupPanel" checked={suppressFieldGroupPanel ?? false} onChange={(e: any) => setSuppressFieldGroupPanel(e.checked ? true : undefined)}/>
                </div>
            : null}
            <div className="field grid">
                <label htmlFor="field" className="col-fixed" style={{width: dialogLabelWidth}}>Field name</label>
                <InputText id="field" value={field} onChange={onChangeField} style={{width:'30rem'}} readOnly={operationEditField}/>
            </div>
            <div className="field grid">
                <label htmlFor="label" className="col-fixed" style={{width: dialogLabelWidth}}>Label</label>
                <InputText id="label" value={label} onChange={(e: any) => setLabel(e.target.value)} style={{width:'30rem'}}/>
            </div>
            <div className="field grid">
                <label htmlFor="tooltip" className="col-fixed" style={{width: dialogLabelWidth}}>Tooltip</label>
                <XInputTextareaBase value={tooltip ?? null}
                                    onChange={(value: string | null) => setTooltip(value ?? undefined)}
                                    style={{width:'30rem'}} autoResize={true}/>
            </div>
            <div className="field grid">
                <label htmlFor="dateFrom" className="col-fixed" style={{width: dialogLabelWidth}}>Date from</label>
                <XCalendar id="dateFrom" value={dateFrom ?? null} onChange={(value: Date | null) => setDateFrom(value ?? undefined)}/>
            </div>
            <div className="field grid">
                <label htmlFor="dateTo" className="col-fixed" style={{width: dialogLabelWidth}}>Date to</label>
                <XCalendar id="dateTo" value={dateTo ?? null} onChange={(value: Date | null) => setDateTo(value ?? undefined)}/>
            </div>
            {fieldPropElemList}
            <XButton label={operationEditField ? "Modify field" : "Add field"} onClick={onSave}/>
        </Dialog>
    );
}
