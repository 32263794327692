import React from "react";
import {Form} from "../../XLibItems";
import {XInputText} from "@michalrakus/x-react-web-lib/XInputText";
import {XFormFooter} from "@michalrakus/x-react-web-lib/XFormFooter";
import {XInputDate} from "@michalrakus/x-react-web-lib/XInputDate";
import {XFormBaseModif} from "@michalrakus/x-react-web-lib/XFormBaseModif";
import {XCheckbox} from "@michalrakus/x-react-web-lib/XCheckbox";
import {XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {EnumEnum, Rola, SluzbaEnum} from "../../common/enums";
import {XDropdownEnum} from "../user/XDropdownEnum";
import {Utils} from "../../Utils";
import {XObject} from "@michalrakus/x-react-web-lib/XObject";
import {XAutoComplete} from "@michalrakus/x-react-web-lib/XAutoComplete";
import {Klient} from "../../model/klient/klient.entity";
import {
    dateAsDB,
    dateFromModel,
    stringAsUI,
    XDateScale
} from "@michalrakus/x-react-web-lib/XUtilsConversions";
import {XFormCol} from "@michalrakus/x-react-web-lib/XFormCol";
import {XFormRow} from "@michalrakus/x-react-web-lib/XFormRow";
import {KlientSluzba} from "../../model/klient/klient-sluzba.entity";
import {XMultiSelect} from "@michalrakus/x-react-web-lib/XMultiSelect";
import {XInputTextarea} from "@michalrakus/x-react-web-lib/XInputTextarea";
import {
    XFormAutoCompleteColumn,
    XFormColumn,
    XFormDataTable2,
    XFormTextareaColumn
} from "@michalrakus/x-react-web-lib/XFormDataTable2";
import {MultiSelect} from "primereact/multiselect";
import {XUserDePaul} from "../../model/user/x-user-de-paul.entity";
import {FindUsersRequest} from "../../common/user-api";
import type {XFormProps} from "@michalrakus/x-react-web-lib/XFormBase";
import {XFormHeader} from "@michalrakus/x-react-web-lib/XFormHeader";
import {XUtilsCommon} from "@michalrakus/x-react-web-lib/XUtilsCommon";
import {XFieldChangeEvent} from "@michalrakus/x-react-web-lib/XFieldChangeEvent";
import {XInputDecimal} from "@michalrakus/x-react-web-lib/XInputDecimal";
import {TabPanel, TabView} from "primereact/tabview";
import {XFormScrollable} from "../XFormScrollable";
import {XFormWidth} from "../XFormWidth";
import {xLocaleOption} from "@michalrakus/x-react-web-lib/XLocale";
import {KlientSluzbaKatamneza} from "./KlientSluzbaKatamneza";
import {Sluzba} from "../../model/user/sluzba.entity";
import {KlientSluzbaAdresa} from "./KlientSluzbaAdresa";
import {Tooltip} from "primereact/tooltip";
import {XFormPanelList} from "./XFormPanelList";
import {KlientSluzbaSocRehab} from "../../model/klient/klient-sluzba-soc-rehab.entity";
import {KlientSluzbaCielSpoluprace} from "../../model/klient/klient-sluzba-ciel-spoluprace.entity";
import {XInputTextareaRow} from "./XInputTextareaRow";
import {XInputDateRow} from "./XInputDateRow";
import {KlientSluzbaHodnoteniaDatumy} from "./KlientSluzbaHodnoteniaDatumy";
import {XFormDivider} from "./XFormDivider";
import {KlientSluzbaZakliknuteFieldy} from "./KlientSluzbaZakliknuteFieldy";
import {statSlovensko} from "../../common/constants";
import {XCustomFilter} from "@michalrakus/x-react-web-lib/FindParam";

// formular pre dvojicku KlientSluzba/Klient
// KlientSluzba je root zaznam, Klient je prijoinovany zaznam
// formular ma custom save service, ktory sejvuje obidva zaznamy v jednej transakcii (insert/update podla toho ci je id = undefined v prislusnom zazname)

interface OptionGroup {
    label: string;
    items: Option[];
}

interface Option {
    label: string;
    code: string;
}

// ciselnik diagnozy mame (zatial) tuto natvrdo, lebo obsahuje grouping
// (ak bude treba, tak treba vytvorit samostatnu dvojtabulku)
// pozor kody (atribut code) musia byt unique (zapisuju sa do DB)
const diagnozyOptions: OptionGroup[] = [
    {
        label: 's telesným postihnutím',
        items: [
            { label: 'Detská mozgová obrna', code: 'dmo' },
            { label: 'Parkinsonova choroba', code: 'parkinson' },
            { label: 'Skleróza multiplex, muskulárna dystrofia', code: 'sm-md' },
            { label: 'Neurodegeneratívne ochorenia', code: 'neurodegenerativne-ochorenia' },
            { label: 'Iné telesné a neurologické poruchy', code: 'ine-telesne-poruchy' }
        ]
    },
    {
        label: 's duševným postihnutím',
        items: [
            { label: 'Alzheimer a demencie rôzneho typu etyológie', code: 'alzheimer' },
            { label: 'Organický psychosyndróm', code: 'psychosyndrom' },
            { label: 'Pervazívna vývinová porucha', code: 'pervazivna-porucha' },
            { label: 'Schizofrénia a schizotypové poruchy', code: 'schizofrenia' },
            { label: 'Afektívne poruchy', code: 'afektivne-poruchy' },
            { label: 'Iné nešpecifikované duševné poruchy', code: 'ine-dusevne-poruchy' }
        ]
    },
    {
        label: 's mentálnym postihnutím',
        items: [
            { label: 'mentálne postihnutie', code: 'mentalne-postihnutie' }
        ]
    },
    {
        label: 's chronickým ochorením alebo postihnutím',
        items: [
            { label: 'AIDS', code: 'aids' },
            { label: 'Diabetes mellitus', code: 'diabetes' },
            { label: 'Iné chronické ochorenie (napr. kardiovaskulárne ochorenia, astma alebo chronické obštrukčné pľúcne ochorenia)', code: 'ine-chronicke-ochorenie' },
            { label: 'Kombinované', code: 'chronicke-ochorenie-kombinovane' }
        ]
    },
    {
        label: 'so zmyslovým postihnutím',
        items: [
            { label: 'Zrakové', code: 'zrakove-postihnutie' },
            { label: 'Sluchové', code: 'sluchove-postihnutie' },
            { label: 'Kombinované', code: 'zmyslove-postihnutie-kombinovane' }
        ]
    }
];

@Form("KlientSluzba")
export class KlientSluzbaForm extends XFormBaseModif {

    // casom mozno pojde prec, bude nahradene lazy
    userSocialnyPracovnikList: XUserDePaul[] = [];

    constructor(props: XFormProps) {
        super(props);

        // odkedy renderujeme zvlast formular pre Alzbeta osetrovna, tak joinujeme prakticky vsetko,
        // co neni zrovna idealny stav pre Alzbetu - da sa to o-if-vat podla zvolenej sluzby

        // chceme vediet aky formular vykreslit
        this.addField("sluzba.kod");

        // tab-ka 1 - zdielane udaje / formular Alzbeta osetrovna
        this.addField("klient.pohlavie.name");
        this.addField("klient.obec.okres.nazov"); // chceme vidiet aj okres v autocomplete
        //this.addField("klient.mestskaCast.name"); rusime
        this.addField("klient.modifXUser.name");
        this.addField("klientSluzbaZakazList.id");

        // najoinujeme zaznamy KlientSluzba na zazname klient, aby sme vedeli zobrazit zoznam sluzieb do ktorych klient patri
        this.addField("klient.klientSluzbaList.sluzba.nazov");

        // joiny potrebne v druhom, tretom, ... tab-e
        // poznamka: v TabView nepouzivame renderActiveOnly={false}, lebo potom nam vykresli XInputTextarea velmi uzke (pravdepodobne bug v primereact)
        // zaroven vsak neskorsie renderovanie tab-iek sposobuje ze nemame vsetky join-y ktore nam treba, preto tie join-y musime pridat manualne

        // tab-ka mapovanie
        this.addField("modifXUser.name");
        this.addField("klucovyPracovnikXUser.name");
        if (Utils.isSluzbaStreetwork() || Utils.isSluzbaOsetrovnaNDC()) {
            //this.addField("miestoMoznehoKontaktuKlientSluzbaList.miestoMoznehoKontaktu.miesto");
            this.addField("miestoZdrziavaniaMestskaCast.name");
        }

        // tab-ka socialna anamneza
        this.addField("socPostavenieList.name");
        this.addField("rodinnyStav.name");
        this.addField("uzivatelSluzbyList.name");
        this.addField("dokladList.name");
        this.addField("klientSluzbaPrijemList.druhPrijmu.name");
        this.addField("vzdelanie.name");

        // tab-ka zdravotna anamneza
        this.addField("klientSluzbaOdkazanostList.odkazanost.name");
        this.addField("klientSluzbaOdkazanostList.stupenOdkazanosti.name");

        // tab-ka ukoncenie
        this.addField("klientSluzbaUkonceniePoskytSocPoradList.poslKlucovyPracovnikXUser.name");

        // tab-ka individualny plan
        this.addField("klientSluzbaCielSpolupraceList.id");

        // tab-ka socialna rehabilitacia
        this.addField("klientSluzbaSocRehabList.id");

        this.onChangeKlientMenoPriezviskoDatumNarodenia = this.onChangeKlientMenoPriezviskoDatumNarodenia.bind(this);
        this.onClickAddRowUkoncenie = this.onClickAddRowUkoncenie.bind(this);
        this.panelBodyKlientSluzbaSocRehab = this.panelBodyKlientSluzbaSocRehab.bind(this);
        this.panelBodyKlientSluzbaCielSpoluprace = this.panelBodyKlientSluzbaCielSpoluprace.bind(this);
    }

    async componentDidMount() {
        await super.componentDidMount();

        const sluzbaKod: SluzbaEnum = Utils.getCurrentSluzba()!.kod as SluzbaEnum;
        // nacitame si zoznam userov ktori mozu robit spec/psych poradenstvo (tych budeme ponukat v prislusnych autocomplete-och)
        this.userSocialnyPracovnikList = await XUtils.fetchMany('find-users', {sluzbaKod: sluzbaKod, rolaCode: Rola.socialnyPracovnik} satisfies FindUsersRequest);

        this.setStateXForm(); // aby sa prejavili zmeny ktore zavisia od nacitanych zoznamov
    }

    createNewObject(): XObject {
        // inicializujeme not null boolean-y a tie list-y, ktore su zobrazene vo formulari - tie vecsinou sedia s tymi co maju zapnuty cascade (su agregovane)
        return {
            klient: {
                datumNarodeniaIbaRok: Utils.getCurrentSluzba()?.kod === SluzbaEnum.alzbetaOsetrovna,
                datumNarodeniaOverenyUdaj: false,
                rodneCisloOverenyUdaj: false,
                statCiselnik: statSlovensko,
                mesto: null,
                obec: null,
                cudzinec: false,
                klientSluzbaList: [],
                uzivatel: false,
                noclaharen: Utils.isSluzbaNoclaharen(),
                version: 0
            },
            sluzba: Utils.getCurrentSluzba(),
            klientSluzbaZakazList: [],
            klientSluzbaPrijemList: [],
            klientSluzbaOdkazanostList: [],
            klientSluzbaCielSpolupraceList: [],
            klientSluzbaSocRehabList: [],
            miestoMoznehoKontaktuKlientSluzbaList: [],
            klientSluzbaUkonceniePoskytSocPoradList: [],
            version: 0
        };
    }

    // pomocna metodka
    private getKlientSluzba(): KlientSluzba | null {
        return this.state.object;
    }

    async onChangeKlientMenoPriezviskoDatumNarodenia(e: XFieldChangeEvent<KlientSluzba>) {
        if (this.isAddRow()) {
            if (e.object.klient.meno && e.object.klient.priezvisko && e.object.klient.datumNarodenia) {
                const datumNarodeniaAsDB: string = dateAsDB(dateFromModel(e.object.klient.datumNarodenia));
                let filterDatum: XCustomFilter;
                if (e.object.klient.datumNarodeniaIbaRok) {
                    // mame len rok, musi sediet rok
                    filterDatum = {where: `date_trunc('year', [klient.datumNarodenia])::DATE = date_trunc('year', ${datumNarodeniaAsDB})::DATE`, params: {}};
                }
                else {
                    // mame cely datum narodenia - ak je v zazname uvedeny rok, tak musi sediet rok, ak je v zazname cely datum, tak musi sediet cely datum)
                    filterDatum = {where: `(([klient.datumNarodeniaIbaRok] AND date_trunc('year', [klient.datumNarodenia])::DATE = date_trunc('year', ${datumNarodeniaAsDB}))` +
                            ` OR ((NOT [klient.datumNarodeniaIbaRok]) AND [klient.datumNarodenia] = ${datumNarodeniaAsDB}))`, params: {}};
                }
                const filter: XCustomFilter | undefined = XUtilsCommon.filterAnd(
                    {where: `${Utils.getSchema()}.unaccent([klient.meno]) ILIKE ${Utils.getSchema()}.unaccent(:meno)` +
                            ` AND ${Utils.getSchema()}.unaccent([klient.priezvisko]) ILIKE ${Utils.getSchema()}.unaccent(:priezvisko)`, params: {meno: e.object.klient.meno, priezvisko: e.object.klient.priezvisko}},
                    filterDatum
                );
                // zosortujeme podla "id desc" - najnovsie zaznamy ako prve
                const klientSluzbaList: KlientSluzba[] = await XUtils.fetchRows('KlientSluzba', filter, "id desc",
                    ["sluzba.kod", "klient.pohlavie.name", "klient.obec.okres.nazov", "klient.modifXUser.name", "klientSluzbaZakazList.id", "klient.klientSluzbaList.sluzba.nazov"]);
                if (klientSluzbaList.length > 0) {
                    // najprv skusime najst zaznam z aktualnej sluzby
                    const klientSluzbaAktualnaList: KlientSluzba[] = klientSluzbaList.filter((value: KlientSluzba) => value.sluzba.id === Utils.getCurrentSluzba()?.id);
                    if (klientSluzbaAktualnaList.length > 0) {
                        //if (window.confirm(xLocaleOption('removeRowConfirm'))) {
                        const klientSluzba: KlientSluzba = klientSluzbaAktualnaList[0];
                        if (window.confirm(`Našiel sa záznam pre klienta "${Utils.klientCreateIDInfo(klientSluzba.klient)}" pre aktuálnu službu ${Utils.getCurrentSluzba()?.nazov}.${XUtilsCommon.newLine}`
                            + `${klientSluzbaAktualnaList.length > 1 ? `Počet nájdených záznamov: ${klientSluzbaAktualnaList.length}${XUtilsCommon.newLine}` : ``}`
                            + XUtilsCommon.newLine
                            + `OK - použiť existujúci nájdený záznam (zadané údaje budú prepísané)${XUtilsCommon.newLine}`
                            + `Cancel - pokračovať vo vytváraní nového záznamu`)) {
                            // prejdeme do (plneho) update rezimu
                            this.ponechajDatumNarodeniaAkPrisielRok(klientSluzba.klient, e.object.klient.datumNarodenia, e.object.klient.datumNarodeniaIbaRok);
                            this.setState({object: klientSluzba});
                        }
                    }
                    else {
                        // mame len zaznamy z cudzich sluzieb - pouzijeme len cast Klient z prveho zaznamu
                        const klient: Klient = klientSluzbaList[0].klient;
                        // zratame vsetkych najdenych (kozmeticka zalezitost)
                        const klientIdSet: Set<number> = new Set<number>();
                        for (const klientSluzba of klientSluzbaList) {
                            klientIdSet.add(klientSluzba.klient.id);
                        }
                        if (window.confirm(`Našiel sa záznam pre klienta "${Utils.klientCreateIDInfo(klient)}" (služby: ${klient.klientSluzbaList.map((value: KlientSluzba) => value.sluzba.nazov).join(", ")}).${XUtilsCommon.newLine}`
                            + `${klientIdSet.size > 1 ? `Počet nájdených záznamov: ${klientIdSet.size}${XUtilsCommon.newLine}` : ``}`
                            + XUtilsCommon.newLine
                            + `OK - použiť existujúci nájdený záznam (zadané zdieľané údaje budú prepísané)${XUtilsCommon.newLine}`
                            + `Cancel - pokračovať vo vytváraní nového záznamu`)) {
                            // zapiseme zaznam Klient (KlientSluzba s id = undefined zostava)
                            const klientSluzba: KlientSluzba = this.getKlientSluzba()!;
                            this.ponechajDatumNarodeniaAkPrisielRok(klient, e.object.klient.datumNarodenia, e.object.klient.datumNarodeniaIbaRok);
                            klientSluzba.klient = klient;
                            this.setState({object: klientSluzba});
                        }
                    }
                }
                else {
                    // ************* docasne riesenie pre zaznamy noclaharne bez KlientSluzba *****************
                    // copy-paste
                    let filterDatum: XCustomFilter;
                    if (e.object.klient.datumNarodeniaIbaRok) {
                        // mame len rok, musi sediet rok
                        filterDatum = {where: `date_trunc('year', [datumNarodenia])::DATE = date_trunc('year', ${datumNarodeniaAsDB})::DATE`, params: {}};
                    }
                    else {
                        // mame cely datum narodenia - ak je v zazname uvedeny rok, tak musi sediet rok, ak je v zazname cely datum, tak musi sediet cely datum)
                        filterDatum = {where: `(([datumNarodeniaIbaRok] AND date_trunc('year', [datumNarodenia])::DATE = date_trunc('year', ${datumNarodeniaAsDB}))` +
                                ` OR ((NOT [datumNarodeniaIbaRok]) AND [datumNarodenia] = ${datumNarodeniaAsDB}))`, params: {}};
                    }
                    const filter: XCustomFilter | undefined = XUtilsCommon.filterAnd(
                        {where: `${Utils.getSchema()}.unaccent([meno]) ILIKE ${Utils.getSchema()}.unaccent(:meno)` +
                                ` AND ${Utils.getSchema()}.unaccent([priezvisko]) ILIKE ${Utils.getSchema()}.unaccent(:priezvisko)` +
                                ` AND [noclaharen] = TRUE`, params: {meno: e.object.klient.meno, priezvisko: e.object.klient.priezvisko}},
                        filterDatum
                    );
                    // zosortujeme podla "id desc" - najnovsie zaznamy ako prve
                    const klientList: Klient[] = await XUtils.fetchRows('Klient', filter, "id desc",
                        ["pohlavie.name", "obec.okres.nazov", "modifXUser.name", "klientZakazUbytovatList.id", "klientSluzbaList.sluzba.nazov"]);
                    if (klientList.length > 0) {
                        const klient: Klient = klientList[0];
                        if (window.confirm(`Našiel sa záznam pre klienta "${Utils.klientCreateIDInfo(klient)}" (služby: Vincent nocaháreň, ${klient.klientSluzbaList.map((value: KlientSluzba) => value.sluzba.nazov).join(", ")}).${XUtilsCommon.newLine}`
                            + `${klientList.length > 1 ? `Počet nájdených záznamov: ${klientList.length}${XUtilsCommon.newLine}` : ``}`
                            + XUtilsCommon.newLine
                            + `OK - použiť existujúci nájdený záznam (zadané zdieľané údaje budú prepísané)${XUtilsCommon.newLine}`
                            + `Cancel - pokračovať vo vytváraní nového záznamu`)) {
                            // zapiseme zaznam Klient (KlientSluzba s id = undefined zostava)
                            const klientSluzba: KlientSluzba = this.getKlientSluzba()!;
                            this.ponechajDatumNarodeniaAkPrisielRok(klient, e.object.klient.datumNarodenia, e.object.klient.datumNarodeniaIbaRok);
                            klientSluzba.klient = klient;
                            this.setState({object: klientSluzba});
                        }
                    }
                    // ************* docasne riesenie pre zaznamy noclaharne bez KlientSluzba - KONIEC *****************
                }
            }
        }
    }

    // pomocna metodka - zapise datumNarodenia do "najdenyKlient" ak je v klientovi len rok (rok narodenia nam neprepise datum narodenia)
    ponechajDatumNarodeniaAkPrisielRok(najdenyKlient: Klient, datumNarodenia: Date, datumNarodeniaIbaRok: boolean) {
        if (najdenyKlient.datumNarodeniaIbaRok && !datumNarodeniaIbaRok) {
            najdenyKlient.datumNarodenia = datumNarodenia;
            najdenyKlient.datumNarodeniaIbaRok = false;
        }
    }

    onChangeKlientDatumNarodeniaIbaRok(e: XFieldChangeEvent<KlientSluzba>) {
        if (e.object.klient.datumNarodeniaIbaRok) {
            const datumNarodenia: Date | null = dateFromModel(e.object.klient.datumNarodenia);
            if (datumNarodenia && (datumNarodenia.getMonth() !== 0 || datumNarodenia.getDay() !== 1)) {
                e.object.klient.datumNarodenia = new Date(`${datumNarodenia.getFullYear()}-01-01`);
            }
        }
    }

    onClickAddRowUkoncenie() {
        this.onTableAddRow("klientSluzbaUkonceniePoskytSocPoradList", {
            datum: XUtilsCommon.today(),
            dovod: null,
            datumPoslKontaktu: XUtilsCommon.today(),
            poslKlucovyPracovnikXUser: this.getKlientSluzba()!.klucovyPracovnikXUser
        });
    };

    preSave(object: XObject) {
        super.preSave(object);
        object.klient.modifDate = object.modifDate;
        object.klient.modifXUser = object.modifXUser;
    }

    // overridneme standardny saveRow
    async saveRow(): Promise<KlientSluzba> {
        // kontrola - toto by nam sposobilo problemy pri zapise
        if (!this.state.object.klient) {
            throw "Unexpected error: klient musi byt vyplneny";
        }
        // zrusili sme cascade save na asociacii klient.klientSluzbaList
        // if (this.state.object.klient.klientSluzbaList !== undefined) {
        //     throw "Unexpected error: klient.klientSluzbaList musi byt undefined (na klientovi sa nesmie citat/nastavovat oneToMany asociacia klientSluzbaList)";
        // }
        const reload: boolean = this.props.onSaveOrCancel !== undefined;
        const klientSluzba: KlientSluzba = await XUtils.fetch('save-klient-sluzba', {entity: this.getEntity(), object: this.state.object, reload: reload});
        if (reload) {
            // ak robime reload (napr. editujeme objekt cez XAutoComplete), tak nam vracia zlu hodnotu derivovaneho atributu menoPriezviskoPrezyvka
            // je to len taka kozmeticka vec, aby sme videli v XAutoComplete spravnu hodnotu
            // zodpoveda funkcii v databaze
            const klient: Klient = klientSluzba.klient;
            klient.menoPriezviskoPrezyvka = `${stringAsUI(klient.meno)} ${stringAsUI(klient.priezvisko)} ${stringAsUI(klient.prezyvka)}`.trim();
        }
        return klientSluzba;
    }

    createLabel(): string {
        let label: string = "Klient";
        const klient: Klient | undefined = this.getKlientSluzba()?.klient;
        if (klient) {
            label = Utils.klientCreateLabel(label, klient);
            if (klient.id === undefined) {
                label += " - " + xLocaleOption('newRow');
            }
        }
        return label;
    }

    createChipsSluzby(): JSX.Element[] {
        let sluzbaList: string[] = [];
        const klientSluzba: KlientSluzba | null = this.getKlientSluzba();
        if (klientSluzba) {
            for (const klientSluzbaZKlienta of klientSluzba.klient.klientSluzbaList) {
                // noclaharen sa riesi podla priznaku, nikdy by nemal existovat zaznam KlientSluzba pre noclaharen
                if (klientSluzbaZKlienta.sluzba.kod !== SluzbaEnum.noclaharen) {
                    sluzbaList.push(klientSluzbaZKlienta.sluzba.nazov);
                }
            }
            // noclaharen specialne
            if (klientSluzba.klient.noclaharen) {
                sluzbaList.push("Vincent nocľaháreň");
            }
        }
        return Utils.createChips(sluzbaList);
    }

    panelBodyKlientSluzbaCielSpoluprace(entity: string, row: KlientSluzbaCielSpoluprace, removeButton: JSX.Element): JSX.Element {
        return (
            <XFormRow>
                <XFormCol width="full">
                    <div className="x-form-inline-row justify-content-between">
                        {/* poznamka: 3rem je sirka pre remove button */}
                        <div className="x-form-inline-row" style={{width: 'calc(100% - 3rem)'}}>
                            <XInputDateRow form={this} entity={entity} rowData={row} field="datumOd"
                                           label="Dátum začiatok" labelStyle={{width:'8rem'}}/>
                            {/* poznamka: 36rem je sirka ostatnych fieldov/labelov v riadku - zistene experimentalne */}
                            <XInputTextareaRow form={this} entity={entity} rowData={row} field="ciel" rows={1} fieldStyle={{width: 'calc(100% - 33rem)'}}
                                               label="Cieľ" labelStyle={{width:'3rem'}} desc="Cieľ je dohoda s klientom k naplneniu jeho potreby. (čo má byť záverom intervencie, čo chceme dosiahnuť...)."/>
                            <XInputDateRow form={this} entity={entity} rowData={row} field="datumDo"
                                           label="Dátum koniec" labelStyle={{width:'8rem'}} desc="Časové vymedzenie naplnenia cieľa - do kedy ho chceme naplniť. Aj keď sa cieľ nenaplnil, treba uviesť dátum ukončenia."/>
                        </div>
                        <div className="field grid">
                            {removeButton}
                        </div>
                    </div>
                    <XInputTextareaRow form={this} entity={entity} rowData={row} field="ciastkoveCiele"
                                       label="Čiastkové ciele" labelStyle={{width:'5.5rem'}} desc="Čiastkové ciele - kroky ako naplniť cieľ, kto to urobí, čo sa podarilo pri naplnení cieľa vybaviť (napr. dokumenty, lekárov, atď.), je klient schopný vykonať čiastkový cieľ sám, s pomocou?
Záver či je cieľ splnený / nesplnený / zmena cieľa a prečo."						/>
                </XFormCol>
            </XFormRow>
        );
    }

    panelBodyKlientSluzbaSocRehab(entity: string, row: KlientSluzbaSocRehab, removeButton: JSX.Element): JSX.Element {
        return (
            <XFormRow>
                <XFormCol width="full">
                    <div className="x-form-inline-row justify-content-between">
                        <div className="x-form-inline-row">
                            <XInputDateRow form={this} entity={entity} rowData={row} field="datumSpracovania"
                                           label="Dátum spracovania"/>
                            <XInputDateRow form={this} entity={entity} rowData={row} field="datumVyhodnotenia"
                                           label="Dátum vyhodnotenia" labelStyle={{width:'11rem'}}/>
                        </div>
                        <div className="field grid">
                            {removeButton}
                        </div>
                    </div>
                    <XInputTextareaRow form={this} entity={entity} rowData={row} field="ciele" rows={1}
                                       label="Ciele" labelStyle={{width:'5.5rem'}} desc="Čo chce / potrebuje zlepšiť"/>
                    <XInputTextareaRow form={this} entity={entity} rowData={row} field="plan" rows={1}
                                       label="Plán" labelStyle={{width:'5.5rem'}} desc="Ako chce dosiahnuť cieľ, čo bude robiť sám / skupinovo, ako často, dohodnutý časový horizont prehodnotenia"/>
                    <XInputTextareaRow form={this} entity={entity} rowData={row} field="vysledok"
                                       label="Výsledok" labelStyle={{width:'5.5rem'}}/>
                </XFormCol>
            </XFormRow>
        );
    }

    // korektne riesenie, ale vadilo mi preblikavanie cierneho formulara,
    // tak radsej hned vykreslujem formular, ktory sa neskor zaplni hodnotami
    // (mozno je to aj rychlejsie ako vykreslovat formular az po nacitani dat, netusim)
    /*
    renderOld() {
        const klientSluzba: KlientSluzba | null = this.getKlientSluzba();
        if (!klientSluzba) {
            // nemame este nacitany/vytvoreny objekt (nezbehol componentDidMount)
            return <div>Formulár sa načítava...</div>;
        }
        else if (klientSluzba.sluzba.kod === SluzbaEnum.alzbetaOsetrovna) {
            // maly formular bez tab-iek pouzivany v osetrovni, podobny skor formularu klienta noclaharne
            return this.renderAlzbetaOsetrovna();
        }
        else {
            // hlavny velky formular s tab-kami
            return this.renderSocPorad();
        }
    }
    */

    render() {
        const sluzba: Sluzba | undefined = Utils.getCurrentSluzba();
        if (!sluzba) {
            // nemalo by sa stat
            return <div>Vyberte službu.</div>;
        }
        else if (sluzba.kod === SluzbaEnum.alzbetaOsetrovna) {
            // maly formular bez tab-iek pouzivany v osetrovni, podobny skor formularu klienta noclaharne
            return this.renderAlzbetaOsetrovna();
        }
        else {
            // hlavny velky formular s tab-kami
            return this.renderSocPorad();
        }
    }

    renderAlzbetaOsetrovna() {
        return (
            <div>
                <XFormHeader form={this} label={this.createLabel()} appendNewRow={false}/>
                <XFormScrollable form={this} widthFitContent={true}>
                    <XFormRow>{/* tato najvrchnejsia uroven XFormRow/XFormCol je tu na to aby ohranicila XInputTextarea "poznamky", inac by bol roztiahnuty na cely formular */}
                        <XFormCol>
                            <XFormRow>
                                <XFormCol labelStyle={{width:'10rem'}}>
                                    <XInputText form={this} field="klient.meno" label="Meno" inputStyle={{width:'15rem'}} readOnly={false} onChange={this.onChangeKlientMenoPriezviskoDatumNarodenia}/>
                                    <XInputText form={this} field="klient.priezvisko" label="Priezvisko" inputStyle={{width:'15rem'}} readOnly={false} onChange={this.onChangeKlientMenoPriezviskoDatumNarodenia}/>
                                    <XInputText form={this} field="klient.prezyvka" label="Prezývka" inputStyle={{width:'15rem'}} readOnly={false}/>
                                    <div className="x-form-inline-row">
                                        <XInputDate form={this} field="klient.datumNarodenia" label="Dátum narod." labelStyle={{width:'10rem'}} readOnly={false} onChange={this.onChangeKlientMenoPriezviskoDatumNarodenia}
                                                    scale={this.getKlientSluzba()?.klient?.datumNarodeniaIbaRok ? XDateScale.Year : XDateScale.Date}/>
                                        <XCheckbox form={this} field="klient.datumNarodeniaIbaRok" label="iba rok" labelStyle={{width:'5rem'}} readOnly={false} onChange={this.onChangeKlientDatumNarodeniaIbaRok}/>
                                    </div>
                                    <XDropdownEnum form={this} assocField="klient.pohlavie" label="Pohlavie" enumEnumCode={EnumEnum.pohlavie} readOnly={false}/>
                                </XFormCol>
                                <XFormCol>
                                    <KlientSluzbaAdresa form={this} osetrovna={true} labelStyle={{width:'11rem'}}/>
                                    <XInputDecimal form={this} field="id" label="Klient-služba ID" labelStyle={{width:'11rem'}} readOnly={true}/>
                                    <XInputDate form={this} field="modifDate" label="Dátum modif." labelStyle={{width:'11rem'}} readOnly={true}/>
                                    <XInputText form={this} field="modifXUser.name" label="Modifikoval" labelStyle={{width:'11rem'}} inputStyle={{width:'12.35rem'}}/>
                                </XFormCol>
                            </XFormRow>
                            <XFormRow>
                                <XFormCol width="full">
                                    <div className="field grid">
                                        <label htmlFor="sluzby" className="col-fixed" style={{width:'10rem'}}>Služby</label>
                                        <div>{this.createChipsSluzby()}</div>
                                    </div>
                                </XFormCol>
                            </XFormRow>
                            <XFormRow>
                                <XFormCol width="full">
                                    <XInputTextarea form={this} field="poznamka" label="Poznámka" labelOnTop={true} cols="full" autoResize={true}/>
                                </XFormCol>
                            </XFormRow>
                            <XFormDataTable2 form={this} assocField="klientSluzbaZakazList" label="Prerušenia služby" addRowLabel="Pridať prerušenie">
                                <XFormColumn field="datumOd" header="Dátum od"/>
                                <XFormColumn field="datumDo" header="Dátum do"/>
                                <XFormTextareaColumn field="poznamka" header="Poznámka" width="45rem"/>
                            </XFormDataTable2>
                        </XFormCol>
                    </XFormRow>
                </XFormScrollable>
                <XFormFooter form={this}/>
            </div>
        );
    }

    // poznamka: pt={{title: {style: {width: '100%'}}}} v elemente Panel nastavuje width na title - span zobrazujuci header text - nepotrebujeme to mat nastavene
    // asi to sluzi len na to ze ked sa tam da autocomplete, tak bude roztiahnuty na celu sirku
    renderSocPorad() {
        const klientSluzba: KlientSluzba | null = this.getKlientSluzba();
        const headerFooterHeight: string = '16.5rem';
        const stlpec2LabelWidth: string = '10.5rem';
        return (
            <div>
                <XFormHeader form={this} label={this.createLabel()} appendNewRow={false}/>
                <XFormWidth form={this} width="95.5rem">
                    <TabView>
                        <TabPanel key="zdielane-udaje" header="Službami zdieľané údaje">
                            <XFormScrollable form={this} headerFooterHeight={headerFooterHeight} fixedHeight={true}>
                                <XFormRow>
                                    <XFormCol labelStyle={{width:'7.5rem'}}>
                                        <XInputText form={this} field="klient.meno" label="Meno" inputStyle={{width:'15rem'}} readOnly={false} onChange={this.onChangeKlientMenoPriezviskoDatumNarodenia}/>
                                        <XInputText form={this} field="klient.priezvisko" label="Priezvisko" inputStyle={{width:'15rem'}} readOnly={false} onChange={this.onChangeKlientMenoPriezviskoDatumNarodenia}/>
                                        <XInputText form={this} field="klient.prezyvka" label="Prezývka" inputStyle={{width:'15rem'}} readOnly={false}/>
                                        <XInputDecimal form={this} field="klient.id" label="Klient ID" readOnly={true}/>
                                        <XInputDate form={this} field="klient.modifDate" label="Dátum modif." readOnly={true}/>
                                        <XInputText form={this} field="klient.modifXUser.name" label="Modifikoval" inputStyle={{width:'12.35rem'}} readOnly={true}/>
                                    </XFormCol>
                                    <XFormCol>
                                        <div className="x-form-inline-row">
                                            <XInputText form={this} field="klient.rodneCislo" label="Rodné číslo" labelStyle={{width:stlpec2LabelWidth}} inputStyle={{width:'7.5rem'}} readOnly={false} placeholder="000000/0000"/>
                                            <XCheckbox form={this} field="klient.rodneCisloOverenyUdaj" label="overené" labelStyle={{width:'5rem'}} readOnly={false}/>
                                        </div>
                                        <div className="x-form-inline-row">
                                            <XInputDate form={this} field="klient.datumNarodenia" label="Dátum narodenia" labelStyle={{width:stlpec2LabelWidth}} readOnly={false} onChange={this.onChangeKlientMenoPriezviskoDatumNarodenia}
                                                        scale={this.getKlientSluzba()?.klient?.datumNarodeniaIbaRok ? XDateScale.Year : XDateScale.Date}/>
                                            <XCheckbox form={this} field="klient.datumNarodeniaIbaRok" label="iba rok" labelStyle={{width:'4.5rem'}} readOnly={false} onChange={this.onChangeKlientDatumNarodeniaIbaRok}/>
                                            <XCheckbox form={this} field="klient.datumNarodeniaOverenyUdaj" label="overený" labelStyle={{width:'5rem'}} readOnly={false}/>
                                        </div>
                                        <XDropdownEnum form={this} assocField="klient.pohlavie" label="Pohlavie" labelStyle={{width:'10.5rem'}} enumEnumCode={EnumEnum.pohlavie} readOnly={false}/>
                                        <div className="x-form-inline-row">
                                            <XInputText form={this} field="klient.obcianskyPreukaz" label="Občiansky preukaz" labelStyle={{width:stlpec2LabelWidth}} inputStyle={{width:'7.5rem'}} readOnly={false}/>
                                            <XInputDate form={this} field="klient.obcianskyPreukazDatumPlatnosti" label="dátum platnosti" labelStyle={{width:'8rem'}} readOnly={false}/>
                                        </div>
                                        <div className="x-form-inline-row">
                                            <XDropdownEnum form={this} assocField="klient.zdravotnaPoistovna" label="Zdravotná poisťovňa" labelStyle={{width:stlpec2LabelWidth}} enumEnumCode={EnumEnum.zdravotnaPoistovna} readOnly={false}/>
                                            {/* informácie o zmenách poisťovní a rok zmeny - nezdielany atribut! */}
                                            <XInputText form={this} field="zdravotnaPoistovnaZmeny" inputStyle={{width:'21rem'}}
                                                        desc="informácie o zmenách poisťovní a rok zmeny (nezdieľaný atribút!)"/>
                                        </div>
                                        {/* kedze mame klient.stat, tak tento checkbox zrusime - ked nam to potvrdia, tak vyhodime aj z DB
                                        <XCheckbox form={this} field="klient.cudzinec" label="Cudzinec" labelStyle={{width:stlpec2LabelWidth}} readOnly={false}/>
                                         */}
                                        <div className="field grid">
                                            <label htmlFor="sluzby" className="col-fixed" style={{width:stlpec2LabelWidth}}>Služby</label>
                                            <div style={{width:'27rem'}}>{this.createChipsSluzby()}</div>{/* 27 rem, aby sa wrapovalo, ked je vela chip-ov. 27 rem = sirka dropdown zdrav poist + zdravotnaPoistovnaZmeny */}
                                        </div>
                                    </XFormCol>
                                    <XFormCol>
                                        <KlientSluzbaAdresa form={this} osetrovna={false} labelStyle={{width:'7rem'}}/>
                                    </XFormCol>
                                </XFormRow>
                                <XFormDivider label="Nezdieľané údaje"/>
                                <XFormRow>
                                    <XFormCol width="full">
                                        <div className="x-form-inline-row">
                                            <XInputDecimal form={this} field="id" label="Klient-služba ID" readOnly={true}/>
                                            <XInputDate form={this} field="modifDate" label="Dátum modif." labelStyle={{width:'7.5rem'}} readOnly={true}/>
                                            <XInputText form={this} field="modifXUser.name" label="Modifikoval" labelStyle={{width:'7.5rem'}} inputStyle={{width:'12.35rem'}} readOnly={true}/>
                                        </div>
                                        {/* povodna realizacia "Miesta zdržiavania sa" cez link tabulku
                                        <div className="x-form-row justify-content-between align-items-center">
                                            <XAutoComplete form={this} assocField="klucovyPracovnikXUser" displayField="name" label="Kľúčový pracovník" suggestions={this.userSocialnyPracovnikList} width="15rem"/>
                                            {Utils.isSluzbaStreetwork() || Utils.isSluzbaOsetrovna() ?
                                                <XFormDataTable2 form={this} assocField="miestoMoznehoKontaktuKlientSluzbaList" label="Miesta zdržiavania sa">
                                                    <XFormColumn field="id" header="ID" readOnly={true} width="4rem"/>
                                                    <XFormAutoCompleteColumn assocField="miestoMoznehoKontaktu" displayField="miesto" header="Miesto" width="40rem"
                                                                             filter={Utils.filterCurrentSluzba()}
                                                                             searchBrowse={<MiestoMoznehoKontaktuBrowse/>} assocForm={<MiestoMoznehoKontaktuForm/>}/>
                                                </XFormDataTable2>
                                                : null
                                            }
                                        </div>
                                        */}
                                        {Utils.isSluzbaStreetwork() || Utils.isSluzbaOsetrovnaNDC() ?
                                            <div className="x-form-inline-row">
                                                <XInputTextarea form={this} field="miestoZdrziavania" label="Miesto zdržiavania" cols="full" rows={1} autoResize={true}
                                                                fieldStyle={{width: `calc(100% - 7rem - 12rem)`}}
                                                                desc="opis aktuálneho miesta alebo posledného miesta, uviesť mesiac a rok"/>
                                                <XAutoComplete form={this} assocField="miestoZdrziavaniaMestskaCast" displayField="name" label="Mestská časť" filter={Utils.enumFilter(EnumEnum.mestskaCast)}
                                                               labelStyle={{width:'7rem'}} width="12rem"/>
                                            </div>
                                            : <div/>
                                        }
                                        <XInputTextarea form={this} field="kontakt" label="Kontakt" cols="full" rows={1} autoResize={true} desc="telefón, mail"/>
                                        <XAutoComplete form={this} assocField="klucovyPracovnikXUser" displayField="name" label="Kľúčový pracovník" suggestions={this.userSocialnyPracovnikList} width="15rem"/>
                                    </XFormCol>
                                </XFormRow>
                            </XFormScrollable>
                        </TabPanel>
                        <TabPanel key="mapovanie" header="Mapovanie">
                            <XFormScrollable form={this} headerFooterHeight={headerFooterHeight} fixedHeight={true}>
                                <XFormRow>
                                    <XFormCol width="full">
                                        <div className="flex align-items-center">
                                            <div id="mapaSpravaniaKlientaElemId" style={{width:'4rem'}}>Mapa správania klienta</div>
                                            <Tooltip target="#mapaSpravaniaKlientaElemId" content="Sociálny pracovník venuje pozornosť:
·  sprievodným javom: ako je zrozumiteľnosť podávania informácií, jasnosť kladených otázok, schopnosť formulovať otázky, prejavy úzkosti – potenie, chvenie, neschopnosť dodržať očný kontakt nesúvislé akoby nedopovedané vety, zrýchlené tempo reči,  príliš hlasitý, alebo nedostatočne počuteľný prejav
·  motorickým prejavom klienta: nepokoj, instabilita, gestikulácia, kývanie sa, pohyb rôznych častí tela a pod. môžu byť dôležitým zdrojom informácii o klientovi
·  emočný stav klienta: sa dá poznať na základe prejavov temperamentu (striedanie nálad, netrpezlivosť, výbušnosť, plačlivosť), rečového rytmu, poruchy dychu a pod."/>
                                            <div className="x-form-col" style={{width:'100%'}}>
                                                <XInputTextarea form={this} field="mapaSpravaniaFyziologicka" label="fyziologická" cols="full" autoResize={true} labelStyle={{width:'6rem'}}
                                                                desc="vonkajšie prejavy, opis postavy, chôdze, pozorované zdravotné ťažkosti, výrazné zdravotné obmedzenia, miera sebaobsluby"/>
                                                <XInputTextarea form={this} field="mapaSpravaniaMotoricka" label="motorická" cols="full" autoResize={true} labelStyle={{width:'6rem'}}
                                                                desc="Vonkajšie prejavy správania, Reč tela, pohyby pri komunikácii, gestikulácia, príznaky neurotického správania, či rozprávala nahlas, intonačné prejavy"/>
                                                <XInputTextarea form={this} field="mapaSpravaniaKognitivna" label="kognitívna afektívna" cols="full" autoResize={true} labelStyle={{width:'6rem'}}
                                                                desc="opis nálad, čo ich ovplyvňuje, závislosti/problematické užívanie návykových látok v súvislosti ovplvnenia nálad, ako pôsobí prejavovo (sebavedomie), či si uvedomuje svoju situáciu alebo riziká, ako ich vníma"/>
                                            </div>
                                        </div>
                                        <XInputTextarea form={this} field="spustacKUtokuUniku" label="Spúšťač k útoku/úniku" cols="full" autoResize={true}
                                                        desc="Na čo si dávať pozor v komunikácii voči klientovi, alebo od klienta voči pracovníkovi. Jasné a stručné.
napr. téma, ktorú s klientom nemáme otvárať, reagoval na niečo agresívne, alebo sa uzavrel a nechcel komunikovať?"/>
                                        {!Utils.isSluzbaUtulok() ? <XInputTextarea form={this} field="rizikaVociSluzbe" label="Riziká voči službe a pracovníkom" cols="full" autoResize={true}
                                                                                   desc="v mieste bývania/ zdržiavania sa: Žije v oblasti diviakov? Nebezpečný pes? V okolí sú klienti (susedia) s agresívnym správaním?
zo správania klienta: Je agresívny pod vplyvom alkoholu/drog?"/> : <div/>
                                        }
                                        <XInputTextarea form={this} field="ineInformacie" label="Iné informácie" cols="full" autoResize={true}
                                                        desc="Využíva iné služby? Spolupracuje s inou službou, ak áno kontaktná osoba? rodné meno za slobodna,..."/>
                                    </XFormCol>
                                </XFormRow>
                            </XFormScrollable>
                        </TabPanel>
                        <TabPanel key="socialna-anamneza" header="Sociálna anamnéza">
                            <XFormScrollable form={this} headerFooterHeight={headerFooterHeight} fixedHeight={true}>
                                <XFormRow>
                                    <XFormCol width="full">
                                        <div className="x-form-inline-row">
                                            <XMultiSelect form={this} assocToMany="socPostavenieList" displayField="name"
                                                          label="Sociálne postavenie" filter={Utils.enumFilter(EnumEnum.socialnePostavenie)} sortField="enumOrder"/>
                                            <XDropdownEnum form={this} assocField="rodinnyStav" label="Rodinný stav" labelStyle={{width:'7rem'}} enumEnumCode={EnumEnum.rodinnyStav}/>
                                        </div>
                                        {Utils.getCurrentSluzba()?.kod === SluzbaEnum.komunitneCentrumZahorskaVes ?
                                            <XMultiSelect form={this} assocToMany="uzivatelSluzbyList" displayField="name"
                                                          label="Užívateľ služby" filter={Utils.enumFilter(EnumEnum.uzivatelSluzby)} sortField="enumOrder"/>
                                            : <div/>
                                        }
                                        <XMultiSelect form={this} assocToMany="dokladList" displayField="name"
                                                      label="Doklady" filter={Utils.enumFilter(EnumEnum.doklady)} sortField="enumOrder"/>
                                        <XFormDataTable2 form={this} assocField="klientSluzbaPrijemList" label="Pravidelný príjem">
                                            <XFormAutoCompleteColumn assocField="druhPrijmu" displayField="name"
                                                                 header="Druh príjmu"
                                                                 filter={Utils.enumFilter(EnumEnum.druhPrijmu)}
                                                                 sortField="enumOrder" width="19rem"/>
                                            <XFormColumn field="mesiacOd" header="Mesiac od" width="7.5rem"/>
                                            <XFormColumn field="mesiacDo" header="Mesiac do" width="7.5rem"/>
                                            <XFormColumn field="vyskaPrijmu" header="Výška príjmu" width="8rem"/>
                                            <XFormColumn field="datumASposobVyplatyPrijmu"
                                                         header="Dátum a spôsob výplaty príjmu" width="20rem"/>
                                        </XFormDataTable2>
                                        <XInputTextarea form={this} field="majetok" label="Majetok" cols="full" autoResize={true} labelStyle={{width:'7rem'}}/>
                                        <XInputTextarea form={this} field="dlhyExekucie" label="Dlhy/exekúcie" cols="full" autoResize={true} labelStyle={{width:'7rem'}}/>
                                        <div className="x-form-inline-row">
                                            <XAutoComplete form={this} assocField="vzdelanie" displayField="name" label="Vzdelanie" labelStyle={{width:'7rem'}} width="15rem" filter={Utils.enumFilter(EnumEnum.vzdelanie)}/>
                                            <XInputTextarea form={this} field="vzdelaniePopis" label="Popis" labelStyle={{width:'4rem'}} cols="full" rows={1} autoResize={true}
                                                            fieldStyle={{width: `calc(100% - 7rem - 15rem)`}}
                                                            desc="názov školy, roky, učebný odbor"/>
                                        </div>
                                        <XInputTextarea form={this} field="praca" label="Práca" cols="full" autoResize={true} labelStyle={{width:'7rem'}}
                                                        desc="Priebeh zamestnaní a roky, koľko má odpracovaných rokov, aké pracovné skúsenosti získal, má kurzy, iné doplňujúce vzdelanie?"/>
                                        <XInputTextarea form={this} field="rodina" label="Rodina" cols="full" autoResize={true} labelStyle={{width:'7rem'}}
                                                        desc="Je s rodinou v kontakte? Či nad tým niekedy premýšľa, aké mali vzťahy? Má deti, súrodencov, rodičov?"/>
                                        <XInputTextarea form={this} field="nudzovyKontakt" label="Núdzový kontakt" cols="full" rows={1} autoResize={true}
                                                        desc="Koho môžeme kontaktovať v prípade zhoršenia zdravotného stavu? Telefonický kontakt s menom a vzťahom voči klientovi."/>
                                        <XInputTextarea form={this} field="anamneza" label="Anamnéza" cols="full" autoResize={true}
                                                        desc="...informácie o živote klienta, detstvo, zážitky, úspechy, neúspechy
...hypotéza o probléme, odkiaľ prišiel-na podnet koho"/>
                                        <XInputTextarea form={this} field="osobneZaujmy" label="Osobné záujmy, záľuby, trávenie voľného času" cols="full" autoResize={true}/>
                                        <XInputTextarea form={this} field="socialnyProblem" label="Sociálny problém" cols="full" autoResize={true}
                                                        desc="...hypotéza o probléme, príčina vzniku, charakteru a rozsahu problému
...pomenovanie problému alebo nepriaznivej sociálnej situácie klientom
...analýza problému a momentálny stav, zhodnotenie sociálnym pracovníkom z pozorovania, rozhovorov, počúvania, analýza dokumentov"/>
                                        <XInputTextarea form={this} field="kedyStratilByvanie" label="Kedy prvý krát stratil bývanie?" cols="full" autoResize={true}/>
                                        <XInputTextarea form={this} field="akoPrisielOByvanie" label="Ako/prečo prišiel o stabilné bývanie?" cols="full" autoResize={true}/>
                                        <XInputTextarea form={this} field="akoRiesilSituaciu" label="Ako riešil svoju situáciu?" cols="full" autoResize={true}/>
                                    </XFormCol>
                                </XFormRow>
                            </XFormScrollable>
                        </TabPanel>
                        <TabPanel key="zdravotna-anamneza" header="Zdravotná anamnéza">
                            <XFormScrollable form={this} headerFooterHeight={headerFooterHeight} fixedHeight={true}>
                                <XFormRow>
                                    <XFormCol width="full">
                                        <XInputTextarea form={this} field="zdravotnaAnamneza" label="Zdravotná anamnéza" cols="full" autoResize={true}
                                                        desc="Informácie, ktoré udáva klient- ochorenia, zdravotné ťažkosti aktuálne, úrazy, prekonané ochorenia v minulosti."/>
                                        <XInputTextarea form={this} field="alergie" label="Alergie" cols="full" rows={1} autoResize={true}/>
                                        <div className="field grid">
                                            <label htmlFor="diagnozy" className="col-fixed" style={{width: `${XUtils.FIELD_LABEL_WIDTH}`}}>Diagnózy</label>
                                            {/* diagnozy su zapisane do atributu typu json vo formate: [<kod1>, <kod2>, ..., <kodN>] */}
                                            <MultiSelect value={this.state.object?.diagnozy ?? []} onChange={(e) => {this.state.object.diagnozy = e.value; this.setStateXForm();}}
                                                         options={diagnozyOptions} optionGroupChildren="items" optionGroupLabel="label" optionLabel="label" optionValue="code"
                                                         display="chip"/>
                                        </div>
                                        <div className="x-form-inline-row">
                                            <XInputTextarea form={this} field="lieky" label="Lieky" cols="full" rows={1} autoResize={true}
                                                            fieldStyle={{width: `calc(100% - 9.5rem - 8.4rem)`}}/>
                                            <XInputDate form={this} field="liekyDatumAktualizacie" label="dátum aktualizácie" labelStyle={{width:'9.5rem'}}/>
                                        </div>
                                        <XInputTextarea form={this} field="vseobecnyLekar" label="Všeobecný lekár" cols="full" rows={1} autoResize={true}/>
                                        <XInputTextarea form={this} field="odbornyLekar" label="Odborný lekár" cols="full" autoResize={true}/>
                                        <XFormDataTable2 form={this} assocField="klientSluzbaOdkazanostList" label="Odkázanosť na sociálnu službu">
                                            <XFormAutoCompleteColumn assocField="odkazanost" displayField="name"
                                                                     header="Odkázanosť"
                                                                     filter={Utils.enumFilter(EnumEnum.odkazanostNaSocialnuSluzbu)}
                                                                     sortField="enumOrder" width="25rem"/>
                                            <XFormAutoCompleteColumn assocField="stupenOdkazanosti" displayField="name"
                                                                     header="Stupeň odkázanosti"
                                                                     filter={Utils.enumFilter(EnumEnum.stupenOdkazanosti)}
                                                                     sortField="enumOrder" width="10rem"/>
                                        </XFormDataTable2>
                                    </XFormCol>
                                </XFormRow>
                            </XFormScrollable>
                        </TabPanel>
                        <TabPanel key="ukoncenie-katamneza" header="Ukončenie/katamnéza">
                            <XFormScrollable form={this} headerFooterHeight={headerFooterHeight} fixedHeight={true}>
                                <XFormRow>
                                    <XFormCol width="full">
                                        <XFormDataTable2 form={this} assocField="klientSluzbaUkonceniePoskytSocPoradList" label="Ukončenie poskytovania sociálneho poradenstva"
                                                         onClickAddRow={this.onClickAddRowUkoncenie}>
                                            <XFormColumn field="datum" header="Dátum" readOnly={true}/>
                                            <XFormTextareaColumn field="dovod" header="Dôvod" width="40rem"/>
                                            <XFormColumn field="datumPoslKontaktu" header="Dátum posl. kontaku"/>
                                            <XFormAutoCompleteColumn assocField="poslKlucovyPracovnikXUser" displayField="name" header="Posledný kľúčový pracovník"
                                                                     suggestions={this.userSocialnyPracovnikList} width="15rem"/>
                                        </XFormDataTable2>
                                        {klientSluzba ? <KlientSluzbaKatamneza klientSluzbaId={klientSluzba.id}/> : <div/>}
                                    </XFormCol>
                                </XFormRow>
                            </XFormScrollable>
                        </TabPanel>
                        <TabPanel key="individualny-plan" header="Individuálny plán">
                            <XFormScrollable form={this} headerFooterHeight={headerFooterHeight} fixedHeight={true}>
                                <XFormRow>
                                    <XFormCol width="full">
                                        <div className="x-form-inline-row">
                                            <XInputDate form={this} field="individPlanDatumSpracovania" label="Dátum spracovania"/>
                                            <XInputDate form={this} field="individPlanDatumAktualizacie" label="Dátum aktualizácie" labelStyle={{width:'10rem'}}/>
                                        </div>
                                        <XInputTextarea form={this} field="temyNaRozhovor" label="Témy na rozhovor" cols="full" autoResize={true}
                                                        desc="Ponuka sociálneho poradenstva, témy, postrehy, ktoré chceme otvárať na rozhovore s klientom. Témy vyplývajúce so sociálnej diagnostiky a porád.
Potencionálne možnosti klienta pre zlepšenie, stabilizáciu sociálnej situácie a problému. (napr. posilnenie sociálnych zručností, adaptácia, integrácia, kompenzácia nepriaznivého zdravotného stavu).
Otázky, ktoré sa klienta treba spýtať pri stretnutí."/>
                                        <XInputTextarea form={this} field="potrebyOcakavaniaKlienta" label="Potreby očakávania klienta" cols="full" autoResize={true}
                                                        desc="Identifikované individuálne potreby, priania, očakávania klienta kam sa chce dostať.
Potreby, očávania a sny klienta, zlepšenie situácie, v ktorej sa nachádza ako vie cieľ dosianuť.
Môže ísť aj o pomenovanie nepriaznivej sociálnej situácie, ktorú chce zmeniť."/>
                                        <XFormDivider label="Ciele spolupráce"/>
                                        <XFormPanelList form={this} assocField="klientSluzbaCielSpolupraceList" panelBody={this.panelBodyKlientSluzbaCielSpoluprace} label="" addRowLabel="Pridať cieľ"/>
                                        <XFormDivider label="Metódy spolupráce"/>
                                        <div className="flex align-items-center">
                                            <div style={{width:'7rem'}}>Metódy spolupráce, ktoré sme pri intervencii aplikovali</div>
                                            <div className="x-form-col" style={{width:'100%'}}>
                                                <div className="field grid">
                                                    <div id="individ-plan-metody-zo-zapisov" className="flex align-items-center" style={{width:'6rem'}}>metódy zo zápisov</div>
                                                    <Tooltip target="#individ-plan-metody-zo-zapisov" content="Metódy spolupráce zakliknuté na zápisoch"/>
                                                    <div style={{width:'calc(100% - 6rem)'}}>{klientSluzba ? <KlientSluzbaZakliknuteFieldy klientSluzbaId={klientSluzba.id} fieldGroup="metodyprace"/> : <div/>}</div>
                                                </div>
                                                <XInputTextarea form={this} field="metodySpolupraceVlastnyPopis" label="vlastný popis" labelStyle={{width:'6rem'}} cols="full" autoResize={true}
                                                                desc="Vlastný popis zvolených metód"/>
                                            </div>
                                        </div>
                                        <div className="field grid">
                                            <div id="individ-plan-hodnotenia" className="flex align-items-center" style={{width:'6rem'}}>Hodnotenia</div>
                                            <Tooltip target="#individ-plan-hodnotenia" content='Dátumy zápisov na ktorých je zakliknuté "hodnotenie"'/>
                                            <div style={{width:'calc(100% - 6rem)'}}>{klientSluzba ? <KlientSluzbaHodnoteniaDatumy klientSluzbaId={klientSluzba.id}/> : <div/>}</div>
                                        </div>
                                    </XFormCol>
                                </XFormRow>
                            </XFormScrollable>
                        </TabPanel>
                        <TabPanel key="rizikovy-plan" header="Rizikový plán">
                            <XFormScrollable form={this} headerFooterHeight={headerFooterHeight} fixedHeight={true}>
                                <XFormRow>
                                    <XFormCol width="full">
                                        <div className="x-form-inline-row">
                                            <XInputDate form={this} field="rizikovyPlanDatumSpracovania" label="Dátum spracovania"/>
                                            <XInputDate form={this} field="rizikovyPlanDatumAktualizacie" label="Dátum aktualizácie" labelStyle={{width:'10rem'}}/>
                                        </div>
                                        <XInputTextarea form={this} field="rizika" label="Riziká" cols="full" autoResize={true}
                                                        desc="Aké riziká alebo krízová situácia môže nastať? Rozhovory o následkoch, prevencii.
Napr. riziko pádu, riziko preležanín, napadnutie partnerom, vyhorená chatka, prespávanie v kontajneri - ohrozenie života, strata financii, okradnutie alebo iné..."/>
                                        <div className="flex align-items-center">
                                            <div style={{width:'6rem'}}>Ako vníma klient riziká a ich následky</div>
                                            <div className="x-form-col" style={{width:'100%'}}>
                                                <XInputTextarea form={this} field="akoVnimaRizikaRozumovaZlozka" label="Rozumová zložka" labelStyle={{width:'6rem'}} cols="full" autoResize={true}
                                                                desc="Mohol posúdiť následky svojho konania? Je rozumová zložka ovplyvnená ochorením?"/>
                                                <XInputTextarea form={this} field="akoVnimaRizikaVolovaZlozka" label="Vôľová zložka" labelStyle={{width:'6rem'}} cols="full" autoResize={true}
                                                                desc="Mohol prijímateľ svoje konanie ovládnuť? Dokázal prijímateľ ovládnuť svoje konanie?"/>
                                            </div>
                                        </div>
                                        <XInputTextarea form={this} field="faktoryVznikuAPrevencia" label="Faktory vzniku a prevencia" cols="full" autoResize={true}
                                                        desc="Čo ovplyvňuje vznik rizikovej situácie? Aké sú spúšťače a faktory, ktoré môže vnímať klient ako nápomocné v predchádzaní krízy. (napr. keď je partner pod vplyvom alkoholu)
Došlo už k podobnej situácii? (napr. ak vznikajú hádky po pití alkoholu určiť si mieru spoločného pitia, neriešiť veci pod vplyvom alkoholu..)"/>
                                        <XInputTextarea form={this} field="postupKrizovejIntervencie" label="Postup krízovej intervencie" cols="full" autoResize={true}
                                                        desc="Čo budeme robiť, ak nastane? Ako minimalizujeme riziko? Nastavenie deeskalačných techník na zvládanie krízy, ktoré sú konzultované s klientom.
Napr. čo urobí klientka po napadnutí partnerom, má založný telefón s ktorým si vie privolať pomoc, navštívi lekára - dokumentovanie jej zranení, podá trestné oznámenie, zavolá nám, že potrebuje pomoc, má niesto kam môže odísť na noc"/>
                                    </XFormCol>
                                </XFormRow>
                            </XFormScrollable>
                        </TabPanel>
                        <TabPanel key="socialna-rehabilitacia" header="Sociálna rehabilitácia">
                            <XFormScrollable form={this} headerFooterHeight={headerFooterHeight} fixedHeight={true}>
                                <XFormRow>
                                    <XFormCol width="full">
                                        <XFormPanelList form={this} assocField="klientSluzbaSocRehabList" panelBody={this.panelBodyKlientSluzbaSocRehab} label="" addRowLabel="Pridať záznam"/>
                                    </XFormCol>
                                </XFormRow>
                            </XFormScrollable>
                        </TabPanel>
                        <TabPanel key="prerusenia" header="Prerušenia služby">
                            <XFormScrollable form={this} headerFooterHeight={headerFooterHeight} fixedHeight={true}>
                                <XFormRow>
                                    <XFormCol width="full">
                                        <XFormDataTable2 form={this} assocField="klientSluzbaZakazList" label="" addRowLabel="Pridať prerušenie">
                                            <XFormColumn field="datumOd" header="Dátum od"/>
                                            <XFormColumn field="datumDo" header="Dátum do"/>
                                            <XFormTextareaColumn field="poznamka" header="Poznámka" width="73rem"/>
                                        </XFormDataTable2>
                                    </XFormCol>
                                </XFormRow>
                            </XFormScrollable>
                        </TabPanel>
                    </TabView>
                </XFormWidth>
                <XFormFooter form={this}/>
            </div>
        );
    }
}
