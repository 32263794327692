import React from "react";
import {Form} from "../../XLibItems";
import {XInputDecimal} from "@michalrakus/x-react-web-lib/XInputDecimal";
import {XInputText} from "@michalrakus/x-react-web-lib/XInputText";
import {XFormFooter} from "@michalrakus/x-react-web-lib/XFormFooter";
import {XInputDate} from "@michalrakus/x-react-web-lib/XInputDate";
import {XFormBaseModif} from "@michalrakus/x-react-web-lib/XFormBaseModif";
import {XObject} from "@michalrakus/x-react-web-lib/XObject";
import {SluzbaRolaPravaCheckboxPanel} from "./SluzbaRolaPravaCheckboxPanel";
import {
    SluzbaSluzbaRolaCheckboxMap,
    SluzbaRolaPrava
} from "../../model/user/sluzba-rola-prava.entity";
import type {XFormProps} from "@michalrakus/x-react-web-lib/XFormBase";
import {XFormHeader} from "@michalrakus/x-react-web-lib/XFormHeader";
import {XDropdownEnum} from "./XDropdownEnum";
import {EnumEnum, TypPrav} from "../../common/enums";
import {XFieldChangeEvent} from "@michalrakus/x-react-web-lib/XFieldChangeEvent";
import {XViewStatus} from "@michalrakus/x-react-web-lib/XUtils";

@Form("SluzbaRolaPrava")
export class SluzbaRolaPravaForm extends XFormBaseModif {

    constructor(props: XFormProps) {
        super(props);

        this.onChangeTypPrav = this.onChangeTypPrav.bind(this);
        this.onChangePrava = this.onChangePrava.bind(this);
    }

    createNewObject(): XObject {
        return {typPrav: {id: 143, code: 'lenCheckbox', name: 'len checkbox'}, prava: {}, version: 0};
    }

    // pomocna metodka
    private getSluzbaRolaPrava(): SluzbaRolaPrava | null {
        return this.state.object;
    }

    onChangeTypPrav(e: XFieldChangeEvent<SluzbaRolaPrava>) {
        if (e.object.typPrav.code === TypPrav.citanieZapis) {
            // zmena z lenCheckbox na citanieZapis
            const prava: SluzbaSluzbaRolaCheckboxMap = e.object.prava;
            for (const [sluzbaKod, sluzbaRolaCheckboxMap] of Object.entries(prava)) {
                if (sluzbaRolaCheckboxMap !== undefined) {
                    for (const [sluzbaRolaId, booleanValue] of Object.entries(sluzbaRolaCheckboxMap)) {
                        if (booleanValue) {
                            sluzbaRolaCheckboxMap[sluzbaRolaId as any as number] = XViewStatus.ReadWrite;
                        }
                    }
                }
            }
        }
        else if (e.object.typPrav.code === TypPrav.lenCheckbox) {
            // zmena z citanieZapis na lenCheckbox
            const prava: SluzbaSluzbaRolaCheckboxMap = e.object.prava;
            for (const [sluzbaKod, sluzbaRolaCheckboxMap] of Object.entries(prava)) {
                if (sluzbaRolaCheckboxMap !== undefined) {
                    for (const [sluzbaRolaId, citanieZapisValue] of Object.entries(sluzbaRolaCheckboxMap)) {
                        if (citanieZapisValue === XViewStatus.ReadWrite) {
                            sluzbaRolaCheckboxMap[sluzbaRolaId as any as number] = true;
                        }
                        else {
                            // ak bol readOnly alebo hidden, tak zrusime
                            delete sluzbaRolaCheckboxMap[sluzbaRolaId as any as number];
                        }
                    }
                }
            }
        }
        else {
            throw "Neockavana chyba - neznama hodnota v e.object.typPrav";
        }
    }

    onChangePrava(value: SluzbaSluzbaRolaCheckboxMap) {
        // zapiseme do modelu
        this.getSluzbaRolaPrava()!.prava = value;
        // zavolame react metodu this.setState (aby sa zmena prejavila vo formulari)
        this.setStateXForm();
    }

    render() {
        return (
            <div>
                <XFormHeader form={this} label="Práva"/>
                <div className="x-form-row">
                    <div className="x-form-col">
                        <XInputDecimal form={this} field="id" label="ID" readOnly={true}/>
                        <XInputDate form={this} field="modifDate" label="Dátum modif." readOnly={true}/>
                        <XInputText form={this} field="modifXUser.name" label="Modifikoval" size={20}/>
                        <XInputText form={this} field="kod" label="Kód" size={20}/>
                        <XInputText form={this} field="nazov" label="Názov" size={40}/>
                        <XDropdownEnum form={this} assocField="typPrav" label="Typ práv" enumEnumCode={EnumEnum.typPrav} onChange={this.onChangeTypPrav}/>
                        {this.getSluzbaRolaPrava() ? <SluzbaRolaPravaCheckboxPanel typPrav={this.getSluzbaRolaPrava()!.typPrav.code as TypPrav} value={this.getSluzbaRolaPrava()!.prava} onChange={this.onChangePrava}/> : undefined}
                    </div>
                </div>
                <XFormFooter form={this}/>
            </div>
        );
    }
}
