import React, {useRef, useState} from "react";
import {OperationType} from "@michalrakus/x-react-web-lib/XUtils";
import {Utils} from "../../Utils";
import {XAutoCompleteBase} from "@michalrakus/x-react-web-lib/XAutoCompleteBase";
import {Klient} from "../../model/klient/klient.entity";
import {Dialog} from "primereact/dialog";
import {KlientNoclaharenForm} from "./KlientNoclaharenForm";
import {KlientNoclaharenBrowse} from "./KlientNoclaharenBrowse";
import {
    HladatKlientaMimoSluzbuDialog,
    HladatKlientaMimoSluzbuDialogState
} from "../klient/HladatKlientaMimoSluzbuDialog";

// analogicky komponent ako KlientSluzbaAutoComplete, ale tento sluzi pre Noclaharen
// rozdiel je hlavne v tom ze tu sa nepracuje s typom KlientSluzba ale s typom Klient (kde priznak noclaharen = true)
// a na editaciu/vyhladavanie nepouzivame <KlientSluzbaForm/> a <KlientSluzbaBrowse/> ale <KlientNoclaharenForm/> a <KlientNoclaharenBrowse/>

// UPOZORNENIE:
// property "value" typu Klient musi v sebe obsahovat vsetky zaznamy ktorych atributy sa tu pouzivaju,
// t.j. aj zaznamy XEnum pre pohlavie, obec a statCiselnik,
// t.j. vo formulari kde sa KlientNoclaharenAutoComplete pouziva treba najoinovat toto:
//     this.addField("klient.pohlavie.name");
//     this.addField("klient.mestskaCast.name"); - uz nie
//     this.addField("klient.obec.nazov");
//     this.addField("klient.statCiselnik.nazov");

interface UpravitKlientaMimoSluzbuDialogState {
    opened: boolean;
    id?: number;
    initValues?: object;
}

export const KlientNoclaharenAutoComplete = (props: {
    value: Klient | null;
    onChange: (klient: Klient | null, objectChange: OperationType) => void;
    error: string | undefined;
    onErrorChange: (error: string | undefined) => void;
    readOnly?: boolean;
    maxWidth?: string;
    itemTemplate?: (suggestion: any, index: number, createStringValue: boolean, defaultValue: (suggestion: any) => string) => React.ReactNode; // pouzivane ak potrebujeme nejaky custom format item-om (funkcia defaultValue rata default format)
    inputClassName?: string;
}) => {

    const [hladatKlientaDialogState, setHladatKlientaDialogState] = useState<HladatKlientaMimoSluzbuDialogState>({opened: false});
    const [upravitKlientaDialogState, setUpravitKlientaDialogState] = useState<UpravitKlientaMimoSluzbuDialogState>({opened: false});

    const xAutoCompleteBaseRef = useRef<XAutoCompleteBase>(null);

    const onAddRow = (inputValue: string) => {
        setHladatKlientaDialogState({
            opened: true,
            onHideDialog: onHideDialog
        });
    }

    const onHideDialog = (ok: boolean, klient?: Klient | null, objectChange?: OperationType) => {
        // TODO - objectChange nie je spraveny, zatial nepouzivat
        setHladatKlientaDialogState({opened: false});
        if (ok) {
            if (klient !== null) {
                // otvorime formular KlientNoclaharenForm v rezime na update
                // priznak noclaharen nastavime v KlientNoclaharenForm.preInitForm, po tom ako sa zaznam Klient reread-ne z DB
                setUpravitKlientaDialogState({opened: true, id: klient!.id, initValues: undefined});
            }
            else {
                // otvorime formular KlientNoclaharenForm v rezime na insert, pricom aj klient bude nanovo vytvoreny
                // inicializacia prebehne cez KlientNoclaharenForm.createNewObject()
                setUpravitKlientaDialogState({opened: true, id: undefined, initValues: undefined});
            }
        }
    }

    const onSaveOrCancel = (object: any | null, objectChange: OperationType) => {
        setUpravitKlientaDialogState({opened: false});
        // object je typu Klient
        props.onChange(object, objectChange);
        props.onErrorChange(undefined);
        // vycistime aj autocomplete (ak bol save), user tam moze mat nieco zapisane
        xAutoCompleteBaseRef.current!.formDialogOnSaveOrCancel(object, objectChange);
    }

    return (
        <div className="w-full" style={{maxWidth: props.maxWidth}}>
            <XAutoCompleteBase ref={xAutoCompleteBaseRef} value={props.value} onChange={props.onChange} error={props.error} onErrorChange={props.onErrorChange} idField="id"
                               field={["V:cisloVreca", "meno", "priezvisko", "prezyvka", "RČ:rodneCislo", "pohlavie.name", "datumNarodenia", "ulica", "cisloDomu", "psc", "mesto", "obec.nazov", "statCiselnik.nazov", "ID:id", "poznamky"]}
                               itemTemplate={props.itemTemplate} inputClassName={props.inputClassName}
                               suggestionsQuery={{entity: "Klient", filter: Utils.klientFilterNoclaharen(), fields: ["klientZakazUbytovatList.id"]}}
                               searchBrowse={<KlientNoclaharenBrowse/>} valueForm={<KlientNoclaharenForm/>} onAddRow={onAddRow}
                               insertButtonTooltip="Nový klient" updateButtonTooltip="Upraviť klienta"
                               width="100%" scrollHeight="50rem" readOnly={props.readOnly}
                               suggestionsLoad="lazy" lazyLoadMaxRows={15} minLength={2} setFocusOnCreate={true}/>
            <HladatKlientaMimoSluzbuDialog dialogState={hladatKlientaDialogState}/>
            <Dialog className="x-dialog-without-header" visible={upravitKlientaDialogState.opened} onHide={() => setUpravitKlientaDialogState({opened: false})}>
                <KlientNoclaharenForm id={upravitKlientaDialogState.id} initValues={upravitKlientaDialogState.initValues} onSaveOrCancel={onSaveOrCancel}/>
            </Dialog>
        </div>
    );
}
